import { Image } from 'components/images';
import { useManagerState } from 'managers-app/store';
import { FunctionComponent, useMemo } from 'react';
import { t } from 'libs';
import { ROUTES } from 'managers-app/libs';
import { Link } from 'react-router-dom';

import MenuItem from './MenuItem';

const Sidebar: FunctionComponent = () => {
  const company = useManagerState((state) => state.company.item);
  const pendingUsers = useManagerState((state) => state.clients.count);
  const pendingAuthorizations = useManagerState((state) => state.authorizations.count);
  const user = useManagerState((state) => state.session.user);

  const editableLink = (): string => {
    if (user.edit('texts')) return ROUTES.settings.texts;
    if (user.edit('links')) return ROUTES.settings.links;
    if (user.edit('nodal')) return ROUTES.settings.nodal;
    return ROUTES.settings.legal;
  };

  const link = useMemo(() => editableLink(), [user]);

  return (
    <div className="sticky flex flex-col justify-between w-full px-3 top-11 h-[calc(100vh-44px)]">
      <div>
        <Link to={ROUTES.index} className="max-w-[44px] mx-auto mb-12 block">
          <Image src={company.document('icon')} />
        </Link>
        {user.requestable && (
          <MenuItem
            icon="requests"
            label={t('managers.pages.requests')}
            to={ROUTES.index}
            requests
            end
          />
        )}
        {user.section('scheduled-cuts') && company.isPremium && (
          <MenuItem
            icon="scheduleds"
            label={t('managers.pages.scheduled_cuts')}
            to={ROUTES.scheduled.index}
          />
        )}
        {user.section('non-payment-cuts') && company.isPremium && (
          <MenuItem
            icon="unpayments"
            label={t('managers.pages.non_payment_cuts')}
            to={ROUTES.unpayment}
          />
        )}
        {(user.section('iclients') || user.requestable) && (
          <MenuItem
            icon="clients"
            label={t('managers.pages.clients')}
            to={user.section('iclients') && pendingUsers > 0
              ? ROUTES.clients.pending
              : ROUTES.clients.index}
            number={user.section('iclients') ? pendingUsers : 0}
          />
        )}
        {user.section('authorizations') && (
          <MenuItem
            icon="authorizations"
            label={t('managers.pages.authorizations')}
            to={pendingAuthorizations > 0
              ? ROUTES.authorizations.pending
              : ROUTES.authorizations.index}
            number={pendingAuthorizations}
          />
        )}
        {user.section('managers') && (
          <MenuItem
            icon="managers"
            label={t('managers.pages.managers')}
            to={ROUTES.managers.index}
          />
        )}
        {user.editable && (
          <MenuItem
            icon="settings"
            label={t('managers.pages.editor')}
            match={ROUTES.settings.index}
            to={link}
          />
        )}
      </div>
      <MenuItem
        icon="manager"
        label={t('managers.pages.profile')}
        to={ROUTES.profile}
      />
    </div>
  );
};

export default Sidebar;
