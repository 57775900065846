import { useClientActions, useClientState } from 'clients-app/store';
import { useEffect } from 'react';

const useContractsState = (): [boolean, string] => {
  const loading = useClientState((state) => state.contracts.loading);
  const message = useClientState((state) => state.contracts.message);
  return [loading, message];
};

const useContractsRequest = (): void => {
  const loggedIn = useClientState((state) => state.session.loggedIn);
  const user = useClientState((state) => state.session.user);
  const index = useClientActions((state) => state.contracts.index);
  const setLoading = useClientActions((state) => state.contracts.setLoading);
  const setMessage = useClientActions((state) => state.contracts.setMessage);

  const handleResponse = (r: string | boolean): void => (
    typeof r === 'string' ? setMessage(r) : setMessage('')
  );

  useEffect(() => {
    if (!loggedIn || !user.validated) {
      setLoading(false);
      return;
    }

    setLoading(true);
    index()
      .then((r: boolean | string) => handleResponse(r))
      .finally(() => setLoading(false));
  }, [loggedIn]);
};

export { useContractsRequest, useContractsState };
