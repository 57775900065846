import _ from 'lodash/fp';
import { clients } from 'clients-app/libs';
import { managers } from 'managers-app/libs';
import authentication from 'features/authentications/authentications.json';

import common from './files/common.json';
import errors from './files/errors.json';
import pages from './files/pages.json';
import requests from './files/requests.json';
import user from './models/user.json';
import models from './models/common.json';
import documents from './models/documents.json';
import contact from './models/contact.json';
import contract from './models/contract.json';
import unpayment from './models/unpayment.json';
import scheduled from './models/scheduled.json';
import request from './models/request.json';

const strings = {
  ...common,
  errors,
  pages,
  clients,
  managers,
  authentication,
  requests,
  models: {
    ...models,
    user,
    documents,
    contact,
    contract,
    unpayment,
    scheduled,
    request,
  },
};

const t = (key: string, params?: object): string => {
  const text = _.get(key)(strings);

  return _.template(text)(params);
};

export default t;
