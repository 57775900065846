import { FunctionComponent } from 'react';
import _ from 'lodash/fp';

import Icon from './Icon';

interface Props {
  className: string;
  onClose?: () => void;
}

const Close: FunctionComponent<Props> = ({ className, onClose }: Props) => (
  !_.isUndefined(onClose) ? (
    <button type="button" onClick={onClose} className={className}>
      <Icon name="close" size="small" />
    </button>
  ) : null
);

export default Close;
