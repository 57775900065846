import { FunctionComponent } from 'react';
import { Outlet } from 'react-router-dom';
import classnames from 'classnames';
import { useIsMatch } from 'hooks';
import { PATHS } from 'clients-app/libs';

import { Header } from './header';
import Footer from './footer';

const Public: FunctionComponent = () => {
  const isHome = useIsMatch(PATHS.index);
  const MAIN = classnames('flex flex-col flex-1', { 'pt-14 xl:pt-16': !isHome });

  return (
    <>
      <Header />
      <main className={MAIN}>
        <Outlet />
      </main>
      <Footer type={isHome ? 'home' : 'default'} />
    </>
  );
};

export default Public;
