import {
  Action, Thunk, action, thunk,
} from 'easy-peasy';
import { API, handleResponse } from 'libs';
import { Distributor } from 'models';

import managers from '../../managers';

interface DistributorModel {
  items: Distributor[];

  init: Action<DistributorModel, Distributor[]>;

  index: Thunk<DistributorModel>;
}

const distributorModel: DistributorModel = {
  items: [],

  init: action((state, payload) => {
    state.items = payload;
  }),

  index: thunk(async (actions, _payload) => {
    const response = await API.get(managers)('/distributors');
    return handleResponse(response, actions.init);
  }),
};

export type { DistributorModel };

export default distributorModel;
