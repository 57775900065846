import { AppDocument, CompanyDocuments } from '../document/types';
import {
  Color,
  Contact,
  ContactNumber,
  Link,
  Setting,
  Text,
} from '../setting/types';

const VERSIONS = [
  'PREMIUM',
  'LITE',
] as const;

type Version = typeof VERSIONS[number];

type Company = {
  id: number;
  createdAt?: string;
  name: string;
  gtm: string;
  version: Version;
  container: string;
  texts: Setting[];
  links: Setting[];
  contactNumbers: ContactNumber[];
  colors: Setting[];
  documents: AppDocument[];
};

type ClientCompany = Company;

type ManagerCompany = Omit<Company, 'texts' | 'links' | 'contactNumbers' | 'colors'>;

interface IClientCompany extends ClientCompany {
  text: (key: Text) => string;
  link: (key: Link) => string;
  contactNumber: (key: Contact) => ContactNumber;
  color: (key: Color) => string;
  document: (docType: CompanyDocuments) => string;
  system: string;
  isPremium: boolean;
}

interface IManagerCompany extends ManagerCompany {
  document: (docType: CompanyDocuments) => string;
  system: string;
  isPremium: boolean;
}

export { VERSIONS };

export type {
  IClientCompany,
  IManagerCompany,
  ClientCompany,
  ManagerCompany,
};
