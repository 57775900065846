import { FunctionComponent } from 'react';
import classnames from 'classnames';
import { Bodycopy, ButtonText } from 'components/typographies';

interface Props {
  label: string;
  id: string;
  active: boolean;
}

const MenuItem: FunctionComponent<Props> = (
  {
    label,
    id,
    active,
  }: Props,
) => {
  const LABEL = classnames('whitespace-nowrap', {
    'text-content': active,
    'text-content-secondary': !active,
  });

  return (
    <a href={`#${id}`} className="relative flex flex-col justify-center w-auto" title={label}>
      {active
        ? <ButtonText className={LABEL}>{label}</ButtonText>
        : <Bodycopy className={LABEL}>{label}</Bodycopy>}
      {active && <span className="h-[3px] bg-primary lg:w-8 w-full absolute bottom-0 lg:-bottom-2 left-0" />}
    </a>
  );
};

export default MenuItem;
