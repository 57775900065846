import _ from 'lodash/fp';

import {
  AppDocument,
  DocType,
  COMPANY_DOCUMENTS,
  CLIENT_DOCUMENTS,
  ClientDocuments,
  CompanyDocuments,
  RequestDocuments,
  HistoricalDocuments,
  HISTORICAL_REQUIRED_DOCUMENTS,
} from './types';

const getDocument = (documents: AppDocument[]): ((docType: CompanyDocuments) => string) => (
  (docType: CompanyDocuments): string => _.flow(
    _.find(['docType', docType]),
    _.get('url'),
  )(documents)
);

const buildDocument = (docType: DocType, container: string): AppDocument => ({
  filename: '',
  url: '',
  base64: '',
  docType,
  container,
});

export type {
  AppDocument,
  ClientDocuments,
  CompanyDocuments,
  RequestDocuments,
  HistoricalDocuments,
  DocType,
};

export {
  getDocument,
  buildDocument,
  COMPANY_DOCUMENTS,
  CLIENT_DOCUMENTS,
  HISTORICAL_REQUIRED_DOCUMENTS,
};
