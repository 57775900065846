import { FunctionComponent, ReactNode } from 'react';
import { classNames } from 'libs';

import {
  SQUARED,
  SQUARED_SIZES,
  SQUARED_STYLES,
  SquareSize,
  SquareStyle,
} from './styles';

type Props = {
  children: ReactNode
  size?: SquareSize;
  styles?: SquareStyle;
  className?: string;
}

const Squared: FunctionComponent<Props> = (
  {
    children,
    size = 'medium',
    styles = 'regular',
    className = '',
  },
) => (
  <div className={classNames(SQUARED, SQUARED_SIZES[size], SQUARED_STYLES[styles], className)}>
    {children}
  </div>
);

export default Squared;
