import { useActiveIndex } from 'clients-app/hooks';
import { useClientState } from 'clients-app/store';
import { FunctionComponent } from 'react';
import classnames from 'classnames';
import { Container } from 'components/containers';
import { ROUTES } from 'clients-app/libs';
import { t } from 'libs';

import MobileNavItem from './components/MobileNavItem';
import MobileAccordion from './components/MobileAccordion';
import ItemNav from './components/ItemNav';
import UnloggedMenu from './components/UnloggedMenu';
import LoggedMenu from './components/LoggedMenu';

interface Props {
  open: boolean;
}

const MobileMenu: FunctionComponent<Props> = ({ open }: Props) => {
  const loggedIn = useClientState((state) => state.session.loggedIn);
  const company = useClientState((state) => state.company.item);
  const [equal, activate] = useActiveIndex();
  const selfconsumption = (
    company.isPremium ? ROUTES.selfconsumption.index : ROUTES.selfconsumption.procedures
  );

  const MENU = classnames('xl:hidden fixed top-14 left-0 h-[calc(100%-56px)] bg-header-negative w-full z-50', {
    'flex flex-col overflow-y-scroll': open,
    hidden: !open,
  });

  const INNER_MENU = classnames('flex flex-col w-full', {
    'mb-16': loggedIn,
    'flex-1 mb-2': !loggedIn,
  });

  return (
    <div className={MENU}>
      <Container className={INNER_MENU}>
        <div className="border-t border-divider-200">
          <MobileNavItem to={ROUTES.index} label={t('clients.pages.home')} />
          {company.isPremium
          && <MobileNavItem to={ROUTES.breakdowns.index} label={t('clients.pages.breakdowns_cuts')} />}
          <MobileAccordion title={t('clients.pages.access_connection')} open={equal(1)} onClick={() => activate(1)}>
            <ItemNav to={ROUTES.supply.index} label={t('clients.pages.electrical_supply')} />
            <ItemNav to={selfconsumption} label={t('clients.pages.selfconsumption')} />
            <ItemNav to={ROUTES.generation} label={t('clients.pages.energy_generation')} />
            <ItemNav to={ROUTES.nodal} label={t('clients.pages.nodal_capacities')} />
          </MobileAccordion>
          <MobileAccordion title={t('clients.pages.help')} open={equal(2)} onClick={() => activate(2)}>
            <ItemNav to={ROUTES.contact.index} label={t('clients.pages.contact')} />
            <ItemNav to={ROUTES.normative} label={t('clients.pages.normative')} />
          </MobileAccordion>
        </div>
      </Container>
      {loggedIn ? <LoggedMenu /> : <UnloggedMenu />}
    </div>
  );
};

export default MobileMenu;
