import _ from 'lodash/fp';

import {
  getText,
  getLink,
  getContactNumber,
  getColor,
  setColors,
} from '../setting';
import { getDocument } from '../document';

import {
  ClientCompany,
  IClientCompany,
  IManagerCompany,
  ManagerCompany,
  VERSIONS,
} from './types';

const clientCompany: ClientCompany = {
  id: 0,
  name: '',
  gtm: '',
  version: 'LITE',
  container: '',
  texts: [],
  links: [],
  contactNumbers: [],
  colors: [],
  documents: [],
};

const managerCompany: ManagerCompany = {
  id: 0,
  name: '',
  gtm: '',
  version: 'LITE',
  container: '',
  documents: [],
};

const isPremium = _.isEqual(VERSIONS[0]);

const createClientCompany = (company = clientCompany): IClientCompany => ({
  ...company,
  text: getText(company.texts),
  link: getLink(company.links),
  contactNumber: getContactNumber(company.contactNumbers),
  color: getColor(company.colors),
  document: getDocument(company.documents),
  system: setColors(company.colors),
  isPremium: isPremium(company.version),
});

const createManagerCompany = (company = managerCompany): IManagerCompany => ({
  ...company,
  document: getDocument(company.documents),
  system: setColors([]),
  isPremium: isPremium(company.version),
});

export type {
  ClientCompany,
  IClientCompany,
  IManagerCompany,
  ManagerCompany,
};

export { createClientCompany, createManagerCompany };
