import { classNames } from 'libs';
import { FunctionComponent, ReactNode } from 'react';

interface Props {
  children: ReactNode;
  className?: string;
}

const HorizontalSection: FunctionComponent<Props> = ({ children, className = '' }: Props) => (
  <div className="w-full max-w-screen-full lg:px-5 full:px-16 full:mx-auto">
    <div className={classNames('w-full overflow-x-auto flex items-center', className)}>
      {children}
    </div>
  </div>
);

export default HorizontalSection;
