import {
  Action,
  Thunk,
  action,
  thunk,
} from 'easy-peasy';
import { API, handleResponse } from 'libs';
import { Link, Setting } from 'models';
import _ from 'lodash/fp';

import managers from '../../managers';

import { ILinks } from './types';

interface LinkModel {
  links: ILinks;

  init: Action<LinkModel, Setting[]>;

  index: Thunk<LinkModel>;
  create: Thunk<LinkModel, Setting>;
  update: Thunk<LinkModel, Setting>;
}

const getLink = (texts: Setting[]): ((key: Link) => Setting) => (
  (key: Link): Setting => _.flow(
    _.find(['key', key]),
  )(texts)
);

const createLinks = (texts: Setting[] = []): ILinks => ({
  items: texts,
  find: getLink(texts),
});

const linkModel: LinkModel = {
  links: createLinks(),

  init: action((state, payload) => {
    state.links = createLinks(payload);
  }),

  index: thunk(async (actions, _payload) => {
    const response = await API.get(managers)('/links');
    return handleResponse(response, actions.init);
  }),

  create: thunk(async (actions, payload) => {
    const response = await API.post(managers)('/links', payload);
    return handleResponse(response, actions.init);
  }),

  update: thunk(async (actions, payload) => {
    const response = await API.put(managers)(`/links/${payload.id}`, payload);
    return handleResponse(response, actions.init);
  }),
};

export type { LinkModel };

export default linkModel;
