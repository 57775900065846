import { FunctionComponent, ReactNode } from 'react';
import { classNames } from 'libs';

interface Props {
  children: ReactNode;
  className?: string;
  tag?: keyof Pick<JSX.IntrinsicElements, 'p' | 'div' | 'span'>;

}

const Bodycopy: FunctionComponent<Props> = ({ children, className = '', tag: Tag = 'p' }: Props) => (
  <Tag className={classNames('text-s-base xl:text-l-base', className)}>
    {children}
  </Tag>
);

export default Bodycopy;
