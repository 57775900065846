import { FunctionComponent, ReactNode } from 'react';
import { classNames } from 'libs';

interface Props {
  children: ReactNode;
  className?: string;
}

const H1: FunctionComponent<Props> = ({ children, className = '' }: Props) => (
  <h1 className={classNames('text-s-xl xl:text-l-xl', className)}>
    {children}
  </h1>
);

export default H1;
