import { FunctionComponent } from 'react';
import { classNames } from 'libs';
import classnames from 'classnames';

import { CheckboxProps } from '../types';

import Input from './Input';
import Label from './Label';

const Checkbox: FunctionComponent<CheckboxProps> = (
  {
    id,
    name,
    readOnly = false,
    disabled = false,
    className = '',
    value,
    placeholder,
    checked,
    onChange,
    onBlur,
  }: CheckboxProps,
) => {
  const CHECK = classnames('min-w-[20px] w-5 h-5 block border flex items-center mr-2', {
    'border-content cursor-pointer': !disabled,
    'border-content-disabled cursor-not-allowed': disabled,
    'bg-content ring-[2px] ring-inset ring-backdrop-primary': checked,
    'bg-backdrop-primary': !checked,
    'bg-content-disabled': disabled && checked,
  });

  const LABEL = classnames('text-l-base', {
    'text-content cursor-pointer': !disabled,
    'text-content-disabled cursor-not-allowed': disabled,
  });

  return (
    <div className={classNames('flex items-center', className)}>
      <Input
        type="checkbox"
        name={name}
        id={id}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        readOnly={readOnly}
        disabled={disabled}
        className="hidden"
        checked={checked}
      />
      <Label
        value=""
        htmlFor={id}
        className={CHECK}
      />
      <Label
        value={placeholder}
        htmlFor={id}
        className={LABEL}
      />
    </div>
  );
};

export default Checkbox;
