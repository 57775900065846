const CONTAINER_SIZES = {
  'x-small': 'w-full px-5 mx-auto max-w-lg',
  small: 'w-full px-5 lg:pl-20 full:pr-16',
  regular: 'w-full max-w-screen-full px-5 full:px-16 mx-auto',
  large: 'w-full max-w-screen-full pl-[60px] pr-5 full:pr-16',
  none: 'w-full',
};

type ContainerSizes = keyof typeof CONTAINER_SIZES;

const SECTION_MARGIN = {
  none: '',
  simple: 'mb-8',
  'x-small': 'mb-10 lg:mb-16',
  'x-smaller': 'mb-8 lg:mb-14',
  'x-smallest': 'mb-8 lg:mb-[72px]',
  regular: 'mb-16 lg:mb-20',
  small: 'mb-12 lg:mb-24',
  medium: 'mb-14 lg:mb-28',
  large: 'mb-[72px] lg:mb-36',
};

type SectionMargins = keyof typeof SECTION_MARGIN;

export { CONTAINER_SIZES, SECTION_MARGIN };

export type { ContainerSizes, SectionMargins };
