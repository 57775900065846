const COMPANY_DOCUMENTS = [
  'icon',
  'favicon',
  'logo',
  'brand',
  'banner',
  'login',
  'landing',
  'select',
  'supply',
  'selfconsumption',
  'privacy',
  'cookies',
  'legal',
  'quality',
  'authorized-contract',
  'capacidad-nodal',
] as const;

type CompanyDocuments = typeof COMPANY_DOCUMENTS[number];

const CLIENT_DOCUMENTS = [
  'front-id',
  'back-id',
  'selfie',
] as const;

type ClientDocuments = typeof CLIENT_DOCUMENTS[number];

const REQUEST_DOCUMENTS = [
  'authorization',
  'technical-report',
  'project',
  'charges',
  'technical-blueprint',
  'installation-bulletin',
  'license',
  'ownership',
  'warranty',
  'environmental-impact-receipt',
  'constitution-of-guarantee',
  'T243',
  'general-situation-plan',
  'private-location-plan',
  'significant-distances',
  'general-implementation-plan',
  'general-implementation-plan-dwg',
  'receipt',
] as const;

type RequestDocuments = typeof REQUEST_DOCUMENTS[number];

const HISTORICAL_DOCUMENTS = [
  'analizing-documentation',
  'documentation-requirement',
  'analizing-viability',
  'emmiting-permissions',
  'proposal',
  'upstream',
  'deliverable',
  'cups',
  'cau',
] as const;

type HistoricalDocuments = typeof HISTORICAL_DOCUMENTS[number];

const HISTORICAL_REQUIRED_DOCUMENTS = [
  'location-report',
  'payment-proof',
  'tecnical-project',
  'do-certificate',
  'preproposal',
  'cie',
  'photographies',
  'functional-tests',
  'protection-settings',
  'passage-permits',
  'organism-authorization',
  'permissions',
  'agreement',
  'signed-agreement',
  'sealed-signed-agreement',
  'bill',
  'administration-authorization',
  'urban-license',
] as const;

type HistoricalRequiredDocuments = typeof HISTORICAL_REQUIRED_DOCUMENTS[number];

const AUTHORIZED_DOCUMENTS = [
  'authorization-authorized',
  'authorization-front-id',
  'authorization-back-id',
] as const;

type AuthorizedDocuments = typeof AUTHORIZED_DOCUMENTS[number];

type DocType = CompanyDocuments | ClientDocuments | HistoricalRequiredDocuments |
RequestDocuments | AuthorizedDocuments | HistoricalDocuments | 'other' | 'other-xlsx';

type AppDocument = {
  id?: number;
  createdAt?: string;
  filename: string;
  url: string;
  base64: string;
  docType: DocType;
  container: string;
};

export { COMPANY_DOCUMENTS, CLIENT_DOCUMENTS, HISTORICAL_REQUIRED_DOCUMENTS };

export type {
  AppDocument,
  ClientDocuments,
  CompanyDocuments,
  RequestDocuments,
  HistoricalDocuments,
  DocType,
};
