import { FunctionComponent } from 'react';
import { classNames } from 'libs';

import { BaseLinkProps } from './types';
import InnerContent from './components/InnerContent';
import LineDecorator from './components/LineDecorator';
import LinkWrapper from './components/LinkWrapper';
import BUTTON_CLASSES from './styles';

interface Props extends BaseLinkProps {
  to: string;
}

const InternalLink: FunctionComponent<Props> = (
  {
    to,
    className = '',
    label = '',
    icon,
    iconRight = false,
    small = false,
    size = 'regular',
    styles = 'regular',
  }: Props,
) => (
  <LinkWrapper
    to={to}
    className={classNames(BUTTON_CLASSES.link(styles, iconRight), className)}
  >
    <InnerContent
      className={BUTTON_CLASSES.icon(iconRight, label)}
      label={label}
      icon={icon}
      small={small}
    />
    <LineDecorator
      className={classNames(
        BUTTON_CLASSES.line(size, styles, false),
        BUTTON_CLASSES.position(small, iconRight, icon),
      )}
    />
  </LinkWrapper>
);

export default InternalLink;
