import {
  Action,
  Thunk,
  action,
  thunk,
} from 'easy-peasy';
import { API, handleResponse } from 'libs';
import {
  Condition,
  createManagerCompany,
  createSelectables,
  IManagerCompany,
  ManagerCompany,
  Selectable,
  Selectables,
} from 'models';

import managers from '../../managers';

interface CompanyModel {
  item: IManagerCompany;
  selectables: Selectables;
  conditions: Condition[];

  init: Action<CompanyModel, ManagerCompany>;
  initSelectables: Action<CompanyModel, Selectable[]>;
  initCondition: Action<CompanyModel, Condition[]>;

  index: Thunk<CompanyModel>;
  indexSelectables: Thunk<CompanyModel>;
  indexConditions: Thunk<CompanyModel>;
}

const companyModel: CompanyModel = {
  item: createManagerCompany(),
  selectables: createSelectables(),
  conditions: [],

  init: action((state, payload) => {
    state.item = createManagerCompany(payload);
  }),

  initSelectables: action((state, payload) => {
    state.selectables = createSelectables(payload);
  }),

  initCondition: action((state, payload) => {
    state.conditions = payload;
  }),

  index: thunk(async (actions, _payload) => {
    const response = await API.get(managers)('/company');
    return handleResponse(response, actions.init);
  }),

  indexSelectables: thunk(async (actions, _payload) => {
    const response = await API.get(managers)('/selectables');
    return handleResponse(response, actions.initSelectables);
  }),

  indexConditions: thunk(async (actions, _payload) => {
    const response = await API.get(managers)('/conditions');
    return handleResponse(response, actions.initCondition);
  }),
};

export type { CompanyModel };

export default companyModel;
