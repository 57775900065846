import { ChangeEvent, FunctionComponent, useState } from 'react';
import _ from 'lodash/fp';
import classnames from 'classnames';
import { DOCUMENTS, classNames, t } from 'libs';
import { Icon } from 'components/icons';
import { AppDocument, buildDocument } from 'models';
import { useToggle } from 'hooks';
import { Modal } from 'components/modals';
import { Bodycopy } from 'components/typographies';

import { TYPOGRAPHY } from './styles';
import FormGroup from './components/FormGroup';
import Label from './components/Label';
import Input from './components/Input';
import InputError from './components/InputError';
import { FieldProps, FileProps, acceptanceTypes } from './types';

interface Props extends FileProps, FieldProps {
  maxSize?: number;
}

const FileField: FunctionComponent<Props> = (
  {
    id,
    name,
    readOnly = false,
    disabled = false,
    required = false,
    className = '',
    value,
    onChange,
    onBlur,
    accept = 'pdf',
    touched = false,
    error = '',
    maxSize = 5,
  }: Props,
) => {
  const [open, toggleOpen] = useToggle(false);
  const [openType, toggleOpenType] = useToggle(false);
  const hasValue = !_.isEmpty(value?.url);
  const hasError = touched && !_.isEmpty(error);
  const [key, setKey] = useState(0);

  const handleChange = async (e: ChangeEvent<HTMLInputElement>): Promise<void> => {
    const { cancelable, target } = e;
    const { files } = target;
    if (cancelable || _.isNil(files)) return;
    if (DOCUMENTS.tooBig(files[0].size, maxSize)) {
      toggleOpen();
      return;
    }

    if (!acceptanceTypes[accept].includes(files[0].type)) {
      toggleOpenType();
      return;
    }

    const docB64: AppDocument = await DOCUMENTS.readFile(files[0], { ...value });
    onChange(name, { ...docB64 });
  };

  const handleOnDelete = (): void => {
    const defaultDoc = buildDocument(value.docType, value.container);
    setKey((prevKey: number): number => prevKey + 1);
    onChange(name, { ...defaultDoc });
  };

  const FIELD = classnames('mb-8 border flex', {
    'border-content-disabled': disabled,
    'border-danger-400': hasError,
    'border-divider-200': !hasValue && !hasError,
    'border-content': hasValue && !hasError,
  });

  const LABEL = classnames('text-s-sm bg-backdrop-primary absolute px-1.5 -top-2 left-3 z-[5] max-w-[calc(100%-24px)]', {
    hidden: !hasValue,
    'text-content-disabled': disabled,
    'text-content': _.isEmpty(error),
    'text-danger-400': hasError && !disabled,
  });

  const INPUT = classnames(classNames('w-full flex items-center pl-4 py-3 text-content', className, TYPOGRAPHY), {
    'cursor-not-allowed': disabled || readOnly,
    'text-content-inactive': !hasValue && !disabled,
    'text-content-disabled': disabled,
    'text-content truncate': hasValue && !disabled,
  });

  const ERROR = classnames({
    hidden: !touched || disabled,
    block: hasError,
  });

  const HELPER = classnames(classNames(TYPOGRAPHY, 'pr-4'), {
    'text-content-interaction': !disabled,
    'text-content-disabled': disabled,
  });

  const DELETABLE = classnames(classNames(TYPOGRAPHY, 'pr-4 py-3'), {
    'text-content-interaction': !disabled,
    'text-content-disabled': disabled,
  });

  const MAX = classnames(classNames(TYPOGRAPHY, 'mr-1'), {
    'text-content': hasValue && !disabled,
    'text-content-inactive': !hasValue && !disabled,
    'text-content-disabled': disabled,
  });

  return (
    <FormGroup className={FIELD}>
      <Label
        value={t(`models.documents.placeholder.${value.docType}`)}
        className={LABEL}
        htmlFor={id}
        required={required}
      />
      <label htmlFor={id} className={INPUT}>
        <span className="flex-1">
          {_.isEmpty(value?.url) ? (
            <>
              {t(`models.documents.placeholder.${value.docType}`)}
              {required && <sup>*</sup>}
            </>
          ) : value?.filename}
        </span>
        <span className={MAX}>{t('max_size', { size: maxSize })}</span>
        {!hasValue && (
          <span className={HELPER}>
            <Icon name="search" />
          </span>
        )}
      </label>
      {hasValue && (
        <span className={DELETABLE} onClick={handleOnDelete}>
          <Icon name="close" />
        </span>
      )}
      <Input
        key={key}
        id={id}
        name={name}
        type="file"
        className="hidden"
        onChange={handleChange}
        readOnly={readOnly}
        disabled={disabled}
        onClick={onBlur}
        accept={_.join(',')(acceptanceTypes[accept])}
      />
      <InputError error={error} className={ERROR} />
      <Modal open={open} title={t('error')} onClose={toggleOpen}>
        <Bodycopy>{t('errors.max_size', { size: maxSize })}</Bodycopy>
      </Modal>
      <Modal open={openType} title={t('error')} onClose={toggleOpenType}>
        <Bodycopy>{t('errors.doc_type', { type: _.join(', ')(acceptanceTypes[accept]) })}</Bodycopy>
      </Modal>
    </FormGroup>
  );
};

export default FileField;
