import { ROUTES } from 'clients-app/libs';
import { ButtonLink } from 'components/buttons';
import { t } from 'libs';
import { FunctionComponent } from 'react';

const UnloggedMenu: FunctionComponent = () => (
  <div className="flex">
    <ButtonLink
      to={ROUTES.session}
      label={t('clients.sign_in')}
      className="!py-4 w-full !px-0"
    />
    <ButtonLink
      to={ROUTES.register}
      label={t('clients.pages.sign_up')}
      styles="outlined"
      className="!py-4 w-full !px-0"
    />
  </div>
);

export default UnloggedMenu;
