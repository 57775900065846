import { ReactNode } from 'react';
import { RouteObject } from 'react-router-dom';

const layout = (element: ReactNode, ...children: RouteObject[]): RouteObject => ({
  element,
  children,
});

const route = (path: string, ...children: RouteObject[]): RouteObject => ({
  path,
  children,
});

const page = (path: string, element: ReactNode): RouteObject => ({
  path,
  element,
});

const indexed = (element: ReactNode): RouteObject => ({
  index: true,
  element,
});

const layoutroute = (
  path: string,
  element: ReactNode,
  ...children: RouteObject[]
): RouteObject => ({
  path,
  element,
  children,
});

export {
  layout,
  route,
  page,
  indexed,
  layoutroute,
};
