const ICON_SIZES = {
  'xx-small': 'w-2.5 min-w-[10px]',
  'x-small': 'w-4 min-w-[16px] ',
  'x-small-growth': 'w-4 lg:w-5 min-w-[16px] lg:min-w-[20px]',
  small: 'w-5 min-w-[20px]',
  'small-growth': 'w-5 lg:w-6 min-w-[20px] lg:min-w-[24px]',
  medium: 'w-6 min-w-[24px]',
  'medium-growth': 'w-6 lg:w-7 min-w-[24px] lg:min-w-[28px]',
  large: 'w-7 min-w-[28px]',
  'large-growth': 'w-7 lg:w-9 min-w-[28px] min-w-[36px]',
};

const ANIMATE = 'transform group-hover:translate-x-2.5 transition-transform duration-100 ease-linear';

type IconSize = keyof typeof ICON_SIZES;

export { ANIMATE, ICON_SIZES };

export type { IconSize };
