import { useParams } from 'react-router-dom';

import { PathParams } from '../models';

const DEFAULT_PARAM = '0';

const usePathParams = (): PathParams => {
  const { id, email } = useParams<PathParams>();

  return {
    id: id || DEFAULT_PARAM,
    email: email || '',
  };
};

export default usePathParams;
