import {
  Action, Thunk, action, thunk,
} from 'easy-peasy';
import { API, handleResponse } from 'libs';
import { Annotation, ID } from 'models';

import managers from '../../managers';

import { AnnotationParams } from './types';

interface AnnotationModel {
  items: Annotation[];

  init: Action<AnnotationModel, Annotation[]>;

  index: Thunk<AnnotationModel, ID>;

  create: Thunk<AnnotationModel, AnnotationParams>;
}

const annotationModel: AnnotationModel = {
  items: [],

  init: action((state, payload) => {
    state.items = payload;
  }),

  index: thunk(async (actions, payload) => {
    const response = await API.get(managers)(`/requests/${payload}/annotations`);
    return handleResponse(response, actions.init);
  }),

  create: thunk(async (actions, payload) => {
    const response = await API.post(managers)(`/requests/${payload.id}/annotations`, payload.annotation);
    return handleResponse(response, actions.init);
  }),
};

export type { AnnotationModel };

export default annotationModel;
