import { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import { classNames } from 'libs';

import { BaseLinkProps } from './types';
import InnerCard from './components/InnerCard';

interface Props extends Omit<BaseLinkProps, 'iconRight' | 'small' | 'size' | 'styles' | 'disabled'> {
  to: string;
  external?: boolean;
  disabled?: boolean;
}

const CardLink: FunctionComponent<Props> = (
  {
    to,
    label = 'Link Card',
    icon = 'bolt',
    className,
    external = false,
    disabled = false,
  }: Props,
) => {
  if (disabled) {
    return (
      <div className={classNames('group flex items-center py-2 xl:py-3 border-b border-divider-200 first:border-t w-full opacity-40', className)}>
        <InnerCard
          icon={icon}
          label={label}
        />
      </div>
    );
  }

  return (
    external
      ? (
        <a href={to} target="_blank" rel="noopener noreferrer" className={classNames('group flex items-center py-2 xl:py-3 border-b border-divider-200 first:border-t w-full', className)}>
          <InnerCard
            icon={icon}
            label={label}
          />
        </a>
      )
      : (
        <Link to={to} className={classNames('group flex items-center py-2 xl:py-3 border-b border-divider-200 first:border-t w-full', className)}>
          <InnerCard
            icon={icon}
            label={label}
          />
        </Link>
      )
  );
};

export default CardLink;
