import _ from 'lodash/fp';
import { TagStyles } from 'components/tags';

import {
  Client,
  IClient,
  CLIENT_TYPES,
  CLIENT_STATES,
  ClientState,
} from './types';

const defaultClient: Client = {
  id: 0,
  createdAt: '',
  type: CLIENT_TYPES[0],
  name: '',
  email: '',
  identityNumber: '',
  address: '',
  postalCode: '',
  municipality: '',
  province: '',
  phoneNumber: '',
  state: CLIENT_STATES[1],
};

const isParticular = (clientType: string) => (
  _.isEqual(CLIENT_TYPES[0], clientType) || _.isEqual(CLIENT_TYPES[2], clientType)
);

const isValidated = _.isEqual(CLIENT_STATES[3]);
const isRejected = _.isEqual(CLIENT_STATES[4]);
const isPending = _.isEqual(CLIENT_STATES[2]);
const isEmailPending = _.isEqual(CLIENT_STATES[0]);

const setLocation = (client: Client): string => (
  `${client.address}, ${client.postalCode} - ${client.municipality}, ${client.province}`
);

const tagStyle = (state: ClientState): TagStyles => {
  if (_.isEqual(CLIENT_STATES[0], state) || _.isEqual(CLIENT_STATES[2], state)) {
    return 'warning';
  }

  if (_.isEqual(CLIENT_STATES[1], state)) return 'regular';
  if (_.isEqual(CLIENT_STATES[3], state)) return 'success';
  return 'danger';
};

const createClient = (client = defaultClient): IClient => ({
  ...client,
  particular: isParticular(client.type),
  location: setLocation(client),
  validated: isValidated(client.state),
  rejected: isRejected(client.state),
  pending: isPending(client.state),
  emailPending: isEmailPending(client.state),
  stateColor: tagStyle(client.state),
});

export type { Client, IClient };

export {
  CLIENT_TYPES,
  CLIENT_STATES,
  createClient,
  defaultClient,
};
