import { FunctionComponent } from 'react';
import { Outlet } from 'react-router-dom';

import Navigation from './header';

const Plain: FunctionComponent = () => (
  <>
    <Navigation />
    <main className="flex flex-col flex-1 pt-14 xl:pt-16">
      <Outlet />
    </main>
  </>
);

export default Plain;
