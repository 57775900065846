import { Thunk, thunk } from 'easy-peasy';
import { Alias, ID } from 'models';
import { API, handleResponse } from 'libs';

import clients from '../../clients';

interface AliasModel {
  create: Thunk<AliasModel, Alias>;

  update: Thunk<AliasModel, Alias>;

  destroy: Thunk<AliasModel, ID>;
}

const aliasModel: AliasModel = {
  create: thunk(async (_actions, payload) => {
    const response = await API.post(clients)('/aliases', payload);
    return handleResponse(response);
  }),

  update: thunk(async (_actions, payload) => {
    const response = await API.put(clients)(`/aliases/${payload.id}`, payload);
    return handleResponse(response);
  }),

  destroy: thunk(async (_actions, payload) => {
    const response = await API.delete(clients)(`/aliases/${payload}`);
    return handleResponse(response);
  }),
};

export type { AliasModel };

export default aliasModel;
