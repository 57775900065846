import { FunctionComponent } from 'react';

const Loader: FunctionComponent = () => (
  <div className="relative flex flex-col items-center justify-center w-12 h-12 mb-6">
    <span className="w-8 h-8 rounded-full bg-notice-400">
      <span className="absolute top-0 left-0 inline-flex w-full h-full rounded-full opacity-75 animate-ping bg-notice-200" />
    </span>
  </div>
);

export default Loader;
