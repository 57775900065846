import { ROUTES } from 'clients-app/libs';
import { useClientState } from 'clients-app/store';
import { InternalLink } from 'components/buttons';
import { H2 } from 'components/typographies';
import { t } from 'libs';
import { FunctionComponent } from 'react';

import Unverified from './Unverfied';
import ContractList from './ContractList';

const Contracts: FunctionComponent = () => {
  const user = useClientState((state) => state.session.user);

  return (
    <>
      <div className="mb-6 lg:flex lg:items-center lg:justify-between lg:mb-10">
        <H2 className="mb-2 lg:mb-0">{t('clients.contracts.contracts')}</H2>
        {user.validated && (
          <InternalLink
            to={ROUTES.contracts.new}
            label={t('clients.contracts.dont_appear')}
          />
        )}
      </div>
      {!user.validated && <Unverified />}
      {user.validated && <ContractList />}
    </>
  );
};

export default Contracts;
