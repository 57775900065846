import { useManagerActions } from 'managers-app/store';
import { useEffect } from 'react';

const useClearReturn = () => {
  const setBack = useManagerActions((actions) => actions.app.setBack);
  const setLabel = useManagerActions((actions) => actions.app.setLabel);

  useEffect(() => {
    setBack('');
    setLabel('');
  }, []);
};

export default useClearReturn;
