import {
  FunctionComponent,
  ReactNode,
  useMemo,
  useState,
} from 'react';
import { Row } from 'react-table';
import _ from 'lodash/fp';
import { Icon } from 'components/icons';
import { EmptyRow } from 'components/empties';

interface Props {
  rows: Row<object>[];
  per?: number;
  children: (page: Row<object>[]) => ReactNode;
  message: string;
}

const Pagination: FunctionComponent<Props> = (
  {
    rows,
    children,
    per = 20,
    message,
  }: Props,
) => {
  const [pageNumber, setPageNumber] = useState(0);
  const maxPages = Math.ceil(rows.length / per);

  const page = useMemo(() => (
    rows.slice(pageNumber * per, Math.min((pageNumber + 1) * per, rows.length))
  ), [rows, per, pageNumber]);

  const onNext = (): void => {
    if (_.gte(pageNumber)(maxPages - 1)) return;
    setPageNumber((prev) => prev + 1);
  };

  const onPrev = (): void => {
    if (_.lte(pageNumber)(0)) return;
    setPageNumber((prev) => prev - 1);
  };

  const goTo = (pageSelected: number): void => setPageNumber(pageSelected);

  return (
    <EmptyRow empty={rows.length === 0} message={message}>
      {children(page)}
      {(rows.length > per) && (
        <div className="flex items-center justify-center mt-8">
          <button type="button" onClick={onPrev} disabled={pageNumber === 0} className="mr-8 disabled:opacity-25">
            <Icon name="angle-left" />
          </button>
          <div>
            {_.map((value: number) => (
              value >= pageNumber - 2 && value <= pageNumber + 2
                ? (
                  <button key={value} type="button" onClick={() => goTo(value)} disabled={value === pageNumber} className="mr-4 opacity-25 disabled:opacity-100 text-s-sm xl:text-l-sm last:mr-0">
                    {value + 1}
                  </button>
                ) : null
            ))(Array.from({ length: maxPages }, (_index, i) => i))}
          </div>
          <button type="button" onClick={onNext} disabled={pageNumber === maxPages - 1} className="ml-8 disabled:opacity-25">
            <Icon name="angle-right" />
          </button>
        </div>
      )}
    </EmptyRow>
  );
};

export default Pagination;
