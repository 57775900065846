import { FunctionComponent } from 'react';
import _ from 'lodash/fp';

import FooterHome from './FooterHome';
import FooterDefault from './FooterDefault';
import FooterForm from './FooterForm';
import FooterPrivate from './FooterPrivate';

interface Props {
  type?: 'home' | 'default' | 'private' | 'form';
}

const Footer: FunctionComponent<Props> = ({ type = 'default' }: Props) => {
  if (_.isEqual(type, 'home')) {
    return <FooterHome />;
  }

  if (_.isEqual(type, 'private')) {
    return <FooterPrivate />;
  }

  if (_.isEqual(type, 'form')) {
    return <FooterForm />;
  }

  return <FooterDefault />;
};

export default Footer;
