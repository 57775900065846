import { Thunk, thunk } from 'easy-peasy';
import { API, handleResponse } from 'libs';

import clients from '../../clients';

import { FraudParams, IncidentParams, MessageParams } from './types';

interface ContactModel {
  message: Thunk<ContactModel, MessageParams>;

  fraud: Thunk<ContactModel, FraudParams>;

  incident: Thunk<ContactModel, IncidentParams>;
}

const contactModel: ContactModel = {
  message: thunk(async (_actions, payload) => {
    const response = await API.post(clients)('/message', payload);
    return handleResponse(response);
  }),

  fraud: thunk(async (_actions, payload) => {
    const response = await API.post(clients)('/fraud', payload);
    return handleResponse(response);
  }),

  incident: thunk(async (_actions, payload) => {
    const response = await API.post(clients)('/incident', payload);
    return handleResponse(response);
  }),
};

export type {
  ContactModel,
  FraudParams,
  IncidentParams,
  MessageParams,
};

export default contactModel;
