import {
  Action,
  Computed,
  Thunk,
  action,
  computed,
  thunk,
} from 'easy-peasy';
import {
  Authorization,
  Contract,
  IContract,
  ID,
  ScheduledCut,
  createContract,
} from 'models';
import _ from 'lodash/fp';
import { API, handleResponse } from 'libs';

import clients from '../../clients';

import { Breakdown } from './types';

interface ContractModel {
  items: IContract[];
  loading: boolean;
  message: string;

  init: Action<ContractModel, Contract[]>;
  setLoading: Action<ContractModel, boolean>;
  setMessage: Action<ContractModel, string>;

  index: Thunk<ContractModel>;

  create: Thunk<ContractModel, Authorization>;

  destroy: Thunk<ContractModel, ID>;

  breakdowns: Computed<ContractModel, Breakdown[]>;
  validateds: Computed<ContractModel, IContract[]>;
}

const contractModel: ContractModel = {
  items: [],
  loading: true,
  message: '',

  init: action((state, payload) => {
    const contracts = _.map(createContract)(payload);
    state.items = contracts;
  }),

  setLoading: action((state, payload) => {
    state.loading = payload;
  }),

  setMessage: action((state, payload) => {
    state.message = payload;
  }),

  index: thunk(async (actions, _payload) => {
    const response = await API.get(clients)('/contracts');
    return handleResponse(response, actions.init);
  }),

  create: thunk(async (actions, payload) => {
    const response = await API.post(clients)('/contracts', payload);
    return handleResponse(response, actions.index);
  }),

  destroy: thunk(async (actions, payload) => {
    const response = await API.delete(clients)(`/contracts/${payload}`);
    return handleResponse(response, actions.index);
  }),

  breakdowns: computed((state) => {
    const sc = _.flow(
      _.map((c: IContract): Breakdown[] => (
        _.map((s: ScheduledCut): Breakdown => ({
          id: s.id ?? 0,
          type: 'scheduled',
          affectsTo: [c.naming],
          municipality: s.municipalities,
          description: s.description,
          from: s.start,
          to: s.end,
          model: s,
        }))(c.scheduledCuts)
      )),
      _.flatten,
      _.compact,
      _.groupBy('id'),
      _.toPairs,
      _.map((item: [string, Breakdown[]]): Breakdown => {
        if (item[1].length === 1) return item[1][0];
        const affects = _.flow(
          _.map('affectsTo'),
          _.flatten,
          _.uniq,
        )(item[1]);

        return {
          id: item[1][0].id ?? 0,
          type: 'scheduled',
          affectsTo: affects,
          description: item[1][0].description,
          municipality: item[1][0].municipality,
          from: item[1][0].from,
          to: item[1][0].to,
          model: item[1][0].model,
        };
      }),
    )(state.items);

    const unpayments = _.flow(
      _.map((c: IContract): Breakdown | null => {
        if (!c.nonPaymentCut) return null;

        return {
          id: c.nonPaymentCut?.id ?? 0,
          type: 'cut',
          affectsTo: [c.naming],
          marketer: c.nonPaymentCut?.marketer,
          municipality: [c.nonPaymentCut?.municipality],
          from: c.nonPaymentCut?.initDate,
          model: c.nonPaymentCut,
        };
      }),
      _.compact,
    )(state.items);

    // @ts-ignore
    const bd: Breakdown[] = _.flow(
      _.concat(sc),
      _.orderBy('from', 'asc'),
    )(unpayments);

    return bd;
  }),

  validateds: computed((state) => _.flow(
    _.filter('validated'),
  )(state.items)),
};

export type { ContractModel, Breakdown };

export default contractModel;
