import { FunctionComponent, ReactNode } from 'react';

import Caption from './Caption';
import Bodycopy from './Bodycopy';

interface Props {
  label: string;
  value: string | ReactNode;
  className?: string;
  valueClassName?: string;
}

const InfoField: FunctionComponent<Props> = (
  {
    label,
    value,
    className = 'mb-4 lg:mb-0 lg:flex-1',
    valueClassName = '',
  }: Props,
) => (
  <div className={className}>
    <Caption className="mb-1 text-content-disabled">{label}</Caption>
    {typeof value === 'string' ? <Bodycopy className={valueClassName}>{value}</Bodycopy> : value}
  </div>
);

export default InfoField;
