import { PATHS } from 'clients-app/libs';
import { useClientState } from 'clients-app/store';
import { ExternalLink } from 'components/buttons';
import { useIsMatch } from 'hooks';
import { t } from 'libs';
import { FunctionComponent } from 'react';

const GDPR: FunctionComponent = () => {
  const isHome = useIsMatch(PATHS.index);
  const company = useClientState((state) => state.company.item);

  return (
    <div className="flex justify-between lg:justify-start">
      <div className="flex-1 md:flex md:flex-initial">
        <ExternalLink
          to={company.document('cookies')}
          label={t('clients.cookies')}
          styles={isHome ? 'negative' : 'positive'}
          className="mb-2 md:mb-0 md:mr-9"
          small
        />
        <ExternalLink
          to={company.document('quality')}
          label={t('clients.quality')}
          styles={isHome ? 'negative' : 'positive'}
          className="md:mr-9"
          small
        />
      </div>
      <div className="flex flex-col-reverse items-end flex-1 md:flex-row md:flex-initial">
        <ExternalLink
          to={company.document('privacy')}
          label={t('clients.privacy')}
          styles={isHome ? 'negative' : 'positive'}
          className="md:mr-9"
          small
        />
        <ExternalLink
          to={company.document('legal')}
          label={t('clients.legal')}
          className="mb-2 md:mb-0 md:mr-9"
          styles={isHome ? 'negative' : 'positive'}
          small
        />
      </div>
    </div>
  );
};

export default GDPR;
