import { Icon, IconName } from 'components/icons';
import { FunctionComponent, ReactNode } from 'react';
import { useToggle } from 'hooks';

import Modal from './Modal';

interface Props {
  icon: IconName;
  title: string;
  children: ReactNode;
}

const Tooltip: FunctionComponent<Props> = ({ icon, title, children }: Props) => {
  const [open, toggleOpen] = useToggle(false);

  return (
    <>
      <button type="button" onClick={toggleOpen} className="">
        <Icon name={icon} className="text-content-interaction" />
      </button>
      <Modal title={title} open={open} onClose={toggleOpen}>
        {children}
      </Modal>
    </>
  );
};

export default Tooltip;
