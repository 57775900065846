import _ from 'lodash/fp';

import { Selectable, Selectables, SelectablesKeys } from './types';

const find = (selectables: Selectable[]): ((key: SelectablesKeys) => Selectable[]) => (
  // @ts-ignore
  (key: SelectablesKeys): Selectable[] => _.flow(
    _.filter((o: Selectable) => o.key === key),
    _.orderBy(['id'], ['asc']),
  )(selectables)
);

const createSelectables = (selectables: Selectable[] = []): Selectables => ({
  items: selectables,
  find: find(selectables),
});

export { createSelectables };

export type { Selectables, Selectable };
