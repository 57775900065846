import { momentum, t } from 'libs';
import { IClient, ID } from 'models';
import { FunctionComponent, ReactNode, useMemo } from 'react';
import {
  useTable,
  useSortBy,
  Column,
  TableProps,
  TableBodyProps,
  HeaderGroup,
  Row,
  Cell,
} from 'react-table';
import _ from 'lodash/fp';
import { Caption } from 'components/typographies';
import { Link } from 'react-router-dom';
import { Icon } from 'components/icons';
import { Tag } from 'components/tags';
import { Pagination } from 'managers-app/components/pagination';

interface Props {
  clients: IClient[];
  to: (id: ID) => string;
  message: string;
}

type Option = {
  id: number;
  name: string;
  identityNumber: string;
  phoneNumber: string;
  creation: string;
  state: ReactNode;
}

const ClientsTable: FunctionComponent<Props> = ({ clients, to, message }: Props) => {
  const data = useMemo(() => _.map((client: IClient): Option => ({
    id: client.id,
    name: client.name,
    identityNumber: client.identityNumber,
    phoneNumber: client.phoneNumber,
    creation: momentum(client.createdAt).format('YYYY/MM/DD'),
    state: (
      <Tag
        tag="span"
        label={t(`models.user.${client.state}`)}
        styles={client.stateColor}
      />
    ),
  }))(clients), [clients]);

  const columns: Column<Option>[] = useMemo(() => ([
    {
      Header: t('models.user.name'),
      accessor: 'name',
      width: 296,
      right: 56,
    },
    {
      Header: t('models.identityNumber'),
      accessor: 'identityNumber',
      width: 100,
      right: 56,
    },
    {
      Header: t('models.user.phoneNumber'),
      accessor: 'phoneNumber',
      width: 100,
      right: 56,
    },
    {
      Header: t('models.user.createdAt'),
      accessor: 'creation',
      width: 128,
      right: 56,
    },
    {
      Header: t('models.user.state'),
      accessor: 'state',
      width: 224,
      right: 56,
    },
  ]), []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
  } = useTable<Option>({ columns, data }, useSortBy);

  const table: TableProps = getTableProps();
  const body: TableBodyProps = getTableBodyProps();
  /* eslint-disable react/prop-types */
  /* eslint-disable @typescript-eslint/no-explicit-any */
  return (
    <div>
      <div role={table.role} className={table.className} style={table.style}>
        {_.map((headerGroup: HeaderGroup<Option>) => (
          <div
            className="flex items-center w-full px-4 py-4 border-b border-content"
            key={headerGroup.getHeaderGroupProps().key}
            role={headerGroup.getHeaderGroupProps().role}
          >
            {_.map((column: HeaderGroup<Option>) => (
              <div
                key={column.getHeaderProps().key}
                role={column.getHeaderProps().role}
                // @ts-ignore
                onClick={column.getHeaderProps(column.getSortByToggleProps()).onClick}
                className="flex items-center"
                // @ts-ignore
                style={{ width: `${column.width}px`, marginRight: `${column.right}px` }}
              >
                <Caption className="mr-2">{column.render('Header')}</Caption>
                <Icon
                  size="xx-small"
                  // @ts-ignore
                  // eslint-disable-next-line no-nested-ternary
                  name={column.isSorted ? column.isSortedDesc ? 'arrow-up' : 'arrow-down' : 'filter'}
                />
              </div>
            ))(headerGroup.headers)}
            <div className="flex-1" />
          </div>
        ))(headerGroups)}
        <div role={body.role} className="px-4" style={body.style}>
          {/* @ts-ignore */}
          <Pagination rows={rows} message={message}>
            {/* @ts-ignore */}
            {(page: Row<Option>[]) => (
              <>
                {_.map((row: Row<Option>) => {
                  prepareRow(row);
                  return (
                    <Link
                      to={to(row.original.id)}
                      key={row.getRowProps().key}
                      role={row.getRowProps().role}
                      className="flex items-center py-5 border-b border-divider-200 group"
                    >
                      {_.map((cell: Cell<Option, any>) => (
                        <div
                          key={cell.getCellProps().key}
                          role={cell.getCellProps().role}
                          // @ts-ignore
                          style={{ width: `${cell.column.width}px`, marginRight: `${cell.column.right}px` }}
                        >
                          <Caption className="truncate">
                            {cell.column.id === 'creation' ? (
                              momentum(cell.value).format('DD/MM/YY')
                            ) : cell.render('Cell')}
                          </Caption>
                        </div>
                      ))(row.cells)}
                      <div className="flex justify-end flex-1">
                        <Icon name="angle-right" animate />
                      </div>
                    </Link>
                  );
                })(page)}
              </>
            )}
          </Pagination>
        </div>
      </div>
    </div>
  );
  /* eslint-enable react/prop-types */
  /* eslint-enable @typescript-eslint/no-explicit-any */
};

export default ClientsTable;
