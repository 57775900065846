import { ROUTES } from 'clients-app/libs';
import { useClientState } from 'clients-app/store';
import { useForbidenRedirect } from 'hooks';
import { FunctionComponent } from 'react';
import { Outlet } from 'react-router-dom';

const Private: FunctionComponent = () => {
  const loggedIn = useClientState((state) => state.session.loggedIn);
  useForbidenRedirect(!loggedIn, ROUTES.session, true);

  return <Outlet />;
};

export default Private;
