import { Thunk, thunk } from 'easy-peasy';
import { ChangeStateParams } from 'models';
import { API, handleResponse } from 'libs';

import managers from '../../managers';

interface RequestCupsModel {
  marketer: Thunk<RequestCupsModel, ChangeStateParams>;
  counter: Thunk<RequestCupsModel, ChangeStateParams>;
  finish: Thunk<RequestCupsModel, ChangeStateParams>;
}

const requestCupsModel: RequestCupsModel = {
  marketer: thunk(async (_actions, payload, { getStoreActions }) => {
    const url = `/requests/${payload.requestID}/cups/${payload.id}/marketer`;
    const response = await API.post(managers)(url, payload.params);
    // @ts-ignore
    const { initCurrent: init } = getStoreActions().requests;
    return handleResponse(response, init);
  }),

  counter: thunk(async (_actions, payload, { getStoreActions }) => {
    const url = `/requests/${payload.requestID}/cups/${payload.id}/counter`;
    const response = await API.post(managers)(url, payload.params);
    // @ts-ignore
    const { initCurrent: init } = getStoreActions().requests;
    return handleResponse(response, init);
  }),

  finish: thunk(async (_actions, payload, { getStoreActions }) => {
    const url = `/requests/${payload.requestID}/cups/${payload.id}/finish`;
    const response = await API.post(managers)(url, payload.params);
    // @ts-ignore
    const { initCurrent: init } = getStoreActions().requests;
    return handleResponse(response, init);
  }),
};

export type { RequestCupsModel };

export default requestCupsModel;
