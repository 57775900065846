import {
  Action,
  Computed,
  Thunk,
  action,
  computed,
  thunk,
} from 'easy-peasy';
import {
  CLIENT_STATES,
  Client,
  IClient,
  ID,
  createClient,
} from 'models';
import _ from 'lodash/fp';
import { API, handleResponse } from 'libs';

import managers from '../../managers';

interface ClientModel {
  items: IClient[];
  current: IClient;

  init: Action<ClientModel, Client[]>;
  initCurrent: Action<ClientModel, Client>;

  index: Thunk<ClientModel>;
  show: Thunk<ClientModel, ID>;

  count: Computed<ClientModel, number>;
}

const clientModel: ClientModel = {
  items: [],
  current: createClient(),

  init: action((state, payload) => {
    state.items = _.map(createClient)(payload);
  }),

  initCurrent: action((state, payload) => {
    state.current = createClient(payload);
  }),

  index: thunk(async (actions, _payload) => {
    const response = await API.get(managers)('/clients');
    return handleResponse(response, actions.init);
  }),

  show: thunk(async (actions, payload) => {
    const response = await API.get(managers)(`/clients/${payload}`);
    return handleResponse(response, actions.initCurrent);
  }),

  count: computed((state) => _.flow(
    _.filter((item: IClient) => item.state === CLIENT_STATES[2]),
    _.size,
  )(state.items)),
};

export type { ClientModel };

export default clientModel;
