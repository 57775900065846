import { ROUTES } from 'clients-app/libs';
import { Icon, IconName } from 'components/icons';
import { Bodycopy } from 'components/typographies';
import { t } from 'libs';
import { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';

interface Props {
  to: string;
  label: string;
  icon: IconName;
}

const LoggedTab: FunctionComponent<Props> = ({ to, label, icon }: Props) => (
  <Link to={to} className="flex flex-col min-w-[160px] mr-2.5 w-40 h-32 justify-center items-center bg-primary">
    <Icon name={icon} className="text-primary-negative" />
    <Bodycopy className="uppercase text-primary-negative">{label}</Bodycopy>
  </Link>
);

const LoggedMenu: FunctionComponent = () => (
  <div className="w-full px-5">
    <p className="mb-4 text-s-lg text-content">{t('clients.private_area')}</p>
    <div className="flex w-full mb-2 overflow-x-scroll">
      <LoggedTab
        to={ROUTES.consumption.index}
        icon="lightbulb"
        label={t('clients.pages.consumption')}
      />
      <LoggedTab
        to={ROUTES.requests.index}
        icon="document"
        label={t('clients.pages.requests')}
      />
      <LoggedTab
        to={ROUTES.breakdowns.index}
        icon="bad"
        label={t('clients.pages.breakdowns')}
      />
      <LoggedTab
        to={ROUTES.profile.index}
        icon="user"
        label={t('clients.pages.profile')}
      />
    </div>
  </div>
);

export default LoggedMenu;
