import { FunctionComponent } from 'react';
import { classNames } from 'libs';

import { BaseButtonProps } from './types';
import InnerContent from './components/InnerContent';
import LinkWrapper from './components/LinkWrapper';
import BUTTON_CLASSES from './styles';

interface Props extends BaseButtonProps {
 to: string;
}

const ActionButton: FunctionComponent<Props> = (
  {
    to,
    className = '',
    label = '',
    icon,
    iconRight = false,
    size = 'large',
    styles = 'regular',
  }: Props,
) => (
  <LinkWrapper
    to={to}
    className={classNames(BUTTON_CLASSES.button(size, styles, iconRight), className)}
  >
    <InnerContent
      className={BUTTON_CLASSES.icon(iconRight, label)}
      label={label}
      icon={icon}
    />
  </LinkWrapper>
);

export default ActionButton;
