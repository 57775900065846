import { classNames } from 'libs';
import { FunctionComponent } from 'react';

interface Props {
  value: number;
  className?: string;
  small?: boolean;
}

const Badge: FunctionComponent<Props> = ({ value, className = '', small = false }: Props) => (
  <span className={classNames('w-2 rounded-full bg-warning-400 h-2', className, value <= 0 ? 'hidden' : '', small ? 'w-[5px] h-[5px]' : 'w-2 h-2')} />
);

export default Badge;
