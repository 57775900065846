import {
  Action,
  Thunk,
  action,
  thunk,
} from 'easy-peasy';
import {
  API, handleData, handleResponse, momentum,
} from 'libs';
import { ID, ScheduledCut } from 'models';

import managers from '../../managers';

import { Location, ScheduledParams, TransformationCenter } from './types';

interface ScheduledCutModel {
  items: ScheduledCut[];
  current: ScheduledCut;

  init: Action<ScheduledCutModel, ScheduledCut[]>;
  initCurrent: Action<ScheduledCutModel, ScheduledCut>;

  index: Thunk<ScheduledCutModel>;
  show: Thunk<ScheduledCutModel, ID>;
  municipalities: Thunk<ScheduledCutModel, ID>;
  create: Thunk<ScheduledCutModel, ScheduledCut>;
  update: Thunk<ScheduledCutModel, ScheduledCut>;
  destroy: Thunk<ScheduledCutModel, ID>;
}

const defaultScheduledCut: ScheduledCut = {
  start: '',
  end: '',
  tension: 'BT',
  description: '',
  municipalities: [],
  transformationCenters: [],
  lines: [],
  distributorID: 0,
  notify: false,
};

const buildScheduledParams = (scheduledCut = defaultScheduledCut): ScheduledParams => {
  const {
    distributorID,
    start,
    end,
    ...rest
  } = scheduledCut;
  return {
    ...rest,
    distributorID: distributorID === 0 ? '' : `${distributorID}`,
    start: momentum(start).format('YYYY-MM-DD HH:mm'),
    end: momentum(end).format('YYYY-MM-DD HH:mm'),
  };
};

const buildScheduledCut = (scheduledCutParams: ScheduledParams): ScheduledCut => {
  const {
    distributorID,
    start,
    end,
    ...rest
  } = scheduledCutParams;
  return {
    ...rest,
    start: momentum(start).toDate().toISOString(),
    end: momentum(end).toDate().toISOString(),
    distributorID: distributorID === '' ? 0 : parseInt(distributorID, 10),
  };
};

const scheduledCutModel: ScheduledCutModel = {
  items: [],
  current: defaultScheduledCut,

  init: action((state, payload) => {
    state.items = payload;
  }),

  initCurrent: action((state, payload) => {
    state.current = payload;
  }),

  index: thunk(async (actions, _payload) => {
    const response = await API.get(managers)('/scheduled-cuts');
    return handleResponse(response, actions.init);
  }),

  show: thunk(async (actions, payload) => {
    const response = await API.get(managers)(`/scheduled-cuts/${payload}`);
    return handleResponse(response, actions.initCurrent);
  }),

  municipalities: thunk(async (_actions, payload) => {
    const response = await API.get(managers)(`/scheduled-cuts/locations/${payload}`);
    return handleData(response);
  }),

  create: thunk(async (_actions, payload) => {
    const response = await API.post(managers)('/scheduled-cuts', payload);
    return handleResponse(response);
  }),

  update: thunk(async (_actions, payload) => {
    const response = await API.put(managers)(`/scheduled-cuts/${payload.id}`, payload);
    return handleResponse(response);
  }),

  destroy: thunk(async (_actions, payload) => {
    const response = await API.delete(managers)(`/scheduled-cuts/${payload}`);
    return handleResponse(response);
  }),
};

export { buildScheduledParams, buildScheduledCut };

export type {
  ScheduledCutModel,
  Location,
  TransformationCenter,
  ScheduledParams,
};

export default scheduledCutModel;
