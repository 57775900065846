import { FunctionComponent, ReactNode } from 'react';
import _ from 'lodash/fp';
import { Icon } from 'components/icons';
import { ButtonText } from 'components/typographies';

interface Props {
  message: string
  children: ReactNode;
}

// TODO: include a picture or something
// @ts-ignore
const AppError: FunctionComponent<Props> = ({ message, children }: Props) => (
  !_.isEmpty(message)
    ? (
      <div className="flex flex-col items-center justify-center w-full max-w-full min-h-screen px-5 bg-slate-50">
        <Icon
          name="warning"
          size="large-growth"
          className="mb-6 text-danger-400"
        />
        <ButtonText tag="h4" className="text-center text-danger-400">{message}</ButtonText>
      </div>
    ) : children
);

export default AppError;
