import { thunk, Thunk } from 'easy-peasy';
import { buildDocument, CLIENT_TYPES } from 'models';
import { API, handleResponse } from 'libs';

import clients from '../../clients';

import { ClientRegisterParams, ClientUpdateParams } from './types';

const defaultClientRegisterParams: ClientRegisterParams = {
  type: CLIENT_TYPES[0],
  name: '',
  email: '',
  confirmEmail: '',
  identityNumber: '',
  selfie: buildDocument('selfie', ''),
  front: buildDocument('front-id', ''),
  back: buildDocument('back-id', ''),
  documents: [],
  address: '',
  postalCode: '',
  municipality: '',
  province: '',
  phoneNumber: '',
  password: '',
  confirmPassword: '',
  acceptance: false,
};

interface ClientModel {
  create: Thunk<ClientModel, ClientRegisterParams>;
  resend: Thunk<ClientModel, string>;
  validate: Thunk<ClientModel, string>;
  update: Thunk<ClientModel, ClientUpdateParams>;

}

const clientModel: ClientModel = {
  create: thunk(async (_actions, payload) => {
    const response = await API.post(clients)('/registration', payload);
    return handleResponse(response);
  }),

  validate: thunk(async (_actions, payload) => {
    const response = await API.put(clients)('/email-validation', { token: payload });
    return handleResponse(response);
  }),

  resend: thunk(async (_actions, payload) => {
    const response = await API.get(clients)(`/email-validation/${payload}`);
    return handleResponse(response);
  }),

  update: thunk(async (_actions, payload, { getStoreActions }) => {
    // @ts-ignore
    const { initUser } = getStoreActions().session;
    const response = await API.put(clients)('/profile', payload);
    return handleResponse(response, initUser);
  }),
};

export { defaultClientRegisterParams };

export type { ClientRegisterParams, ClientUpdateParams, ClientModel };

export default clientModel;
