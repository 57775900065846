import {
  Action,
  Thunk,
  action,
  thunk,
} from 'easy-peasy';
import { API, handleResponse } from 'libs';
import { AppDocument, ClientDocuments } from 'models';
import _ from 'lodash/fp';

import clients from '../../clients';

import { IVerification } from './types';

interface VerificationModel {
  items: IVerification;

  init: Action<VerificationModel, AppDocument[]>;

  index: Thunk<VerificationModel>;
  create: Thunk<VerificationModel, AppDocument[]>;
  update: Thunk<VerificationModel, AppDocument[]>;
}

const getDocument = (documents: AppDocument[]): ((docType: ClientDocuments) => AppDocument) => (
  (docType: ClientDocuments): AppDocument => _.flow(
    _.find(['docType', docType]),
  )(documents)
);

const createVerification = (documents: AppDocument[] = []): IVerification => ({
  items: documents,
  find: getDocument(documents),
});

const verificationModel: VerificationModel = {
  items: createVerification(),

  init: action((state, payload) => {
    state.items = createVerification(payload);
  }),

  index: thunk(async (actions, _payload) => {
    const response = await API.get(clients)('/profile/verification');
    return handleResponse(response, actions.init);
  }),

  create: thunk(async (actions, payload, { getStoreActions }) => {
    const response = await API.post(clients)('/profile/verification', { documents: payload });
    // @ts-ignore
    const { initUser } = getStoreActions().session;
    return handleResponse(response, initUser);
  }),

  update: thunk(async (actions, payload, { getStoreActions }) => {
    const response = await API.put(clients)('/profile/verification', { documents: payload });
    // @ts-ignore
    const { initUser } = getStoreActions().session;
    return handleResponse(response, initUser);
  }),
};

export type { VerificationModel };

export default verificationModel;
