import { PATHS } from 'clients-app/libs';
import { useClientState } from 'clients-app/store';
import { ExternalLink } from 'components/buttons';
import { useIsMatch } from 'hooks';
import { FunctionComponent } from 'react';

interface Props {
  className?: string;
}

const SocialMedia: FunctionComponent<Props> = ({ className }: Props) => {
  const isHome = useIsMatch(PATHS.index);
  const company = useClientState((state) => state.company.item);

  return (
    <div className={className}>
      <ExternalLink
        to={company.link('twitter')}
        styles={isHome ? 'negative' : 'positive'}
        className="mr-4"
        icon="twitter"
        small={!isHome}
      />
      <ExternalLink
        to={company.link('linkedin')}
        styles={isHome ? 'negative' : 'positive'}
        className="mr-4"
        icon="linkedin"
        small={!isHome}
      />
      <ExternalLink
        to={company.link('instagram')}
        styles={isHome ? 'negative' : 'positive'}
        className="mr-4"
        icon="instagram"
        small={!isHome}
      />
      <ExternalLink
        to={company.link('facebook')}
        styles={isHome ? 'negative' : 'positive'}
        className="mr-4"
        icon="facebook"
        small={!isHome}
      />
    </div>
  );
};

export default SocialMedia;
