import { thunk, Thunk } from 'easy-peasy';
import { API, handleResponse } from 'libs';
import { PasswordParams } from 'models';

import clients from '../../clients';

interface PasswordModel {
  request: Thunk<PasswordModel, string>;
  restore: Thunk<PasswordModel, PasswordParams>;
}

const passwordModel: PasswordModel = {
  request: thunk(async (_actions, payload) => {
    const response = await API.get(clients)(`/password/${payload}`);
    return handleResponse(response);
  }),

  restore: thunk(async (_actions, payload) => {
    const response = await API.post(clients)('/password', payload);
    return handleResponse(response);
  }),
};

export type { PasswordModel };

export default passwordModel;
