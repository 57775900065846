import { useClientState } from 'clients-app/store';
import { Container } from 'components/containers';
import { useIsMatch, useToggle } from 'hooks';
import classnames from 'classnames';
import { FunctionComponent, useContext, useMemo } from 'react';
import { ROUTES } from 'clients-app/libs';
import { Image } from 'components/images';
import { Bodycopy } from 'components/typographies';
import { Icon } from 'components/icons';
import { Link } from 'react-router-dom';

import HeaderContext from './context';
import MobileMenu from './MobileMenu';

const MobileHeader: FunctionComponent = () => {
  const isHome = useIsMatch(ROUTES.index);
  const filled = useContext(HeaderContext);
  const title = useClientState((state) => state.app.title);
  const company = useClientState((state) => state.company.item);
  const [open, toggleOpen] = useToggle(false);
  const logo = useMemo(() => {
    if (open) return company.document('brand');
    return company.document('logo');
  }, [open]);

  const MOBILE_HEADER = classnames('h-14 flex flex-col justify-center lg:hidden', {
    'bg-header': (filled || !isHome) && !open,
    'bg-header-negative': open,
  });
  const TITLE = classnames({ 'text-header-negative': !open, 'text-header': open });
  const ICON = classnames('block w-7', {
    'text-content-negative': !open,
    'text-content-interaction': open,
  });

  return (
    <div className={MOBILE_HEADER}>
      <Container className="flex items-center">
        <button type="button" className={ICON} onClick={toggleOpen}>
          <Icon name="menu" size="large" />
        </button>
        <div className="flex justify-center flex-1">
          {isHome ? <Image src={logo} className="w-[140px]" />
            : <Bodycopy className={TITLE}>{title}</Bodycopy>}
        </div>
        <Link to={ROUTES.profile.index} className={ICON}>
          <Icon name="user" size="large" />
        </Link>
      </Container>
      <MobileMenu open={open} />
    </div>
  );
};

export default MobileHeader;
