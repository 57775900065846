import { FunctionComponent, ReactNode } from 'react';
import { classNames } from 'libs';

import { CONTAINER_SIZES, ContainerSizes } from './styles';

interface Props {
  children: ReactNode;
  size?: ContainerSizes;
  className?: string;
}

const Container: FunctionComponent<Props> = ({ children, size = 'regular', className = '' }: Props) => (
  <div className={classNames(CONTAINER_SIZES[size], className)}>
    {children}
  </div>
);

export default Container;
