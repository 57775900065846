import { FunctionComponent } from 'react';
import _ from 'lodash/fp';
import { Icon, IconName } from 'components/icons';
import { ButtonText } from 'components/typographies';

interface Props {
  className: string;
  label?: string;
  icon?: IconName;
  small?: boolean;
  crop?: boolean;
}

const InnerContent: FunctionComponent<Props> = (
  {
    className,
    label = '',
    icon,
    small = false,
    crop = false,
  }: Props,
) => (
  <>
    {icon && <Icon name={icon} size={small ? 'x-small' : 'small-growth'} className={className} />}
    {!_.isEmpty(label) && (
      <>
        {small
          ? <span className={`text-s-sm ${crop ? 'truncate' : ''}`}>{label}</span>
          : <ButtonText className={crop ? 'truncate' : ''}>{label}</ButtonText>}
      </>
    )}
  </>
);

export default InnerContent;
