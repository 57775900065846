import { FunctionComponent, ReactNode } from 'react';
import { Icon } from 'components/icons';

interface Props {
  title: string;
  children: ReactNode;
}

const DesktopHover: FunctionComponent<Props> = ({ title, children }: Props) => (
  <div className="relative flex items-center ml-10 group/menu">
    <p className="text-l-base text-content-negative mr-1.5">
      {title}
      <span className="h-[3px] w-0 bg-header-negative transition-all duration-200 group-hover/menu:w-8 absolute top-7 left-0" />
    </p>
    <Icon
      name="circle-angle-down"
      size="small"
      className="text-content-negative"
    />
    <div className="absolute top-0 left-0 z-50 hidden w-auto min-w-full pt-14 group-hover/menu:block">
      <div className="p-4 bg-header-negative shadow-bottom">{children}</div>
    </div>
  </div>
);

export default DesktopHover;
