import classnames from 'classnames';
import _ from 'lodash/fp';

import { classNames } from '../../libs';
import { IconName } from '../icons';

const BUTTON_DISABLED = 'disabled:bg-backdrop-disabled disabled:text-content-disabled disabled:cursor-not-allowed disabled:border-backdrop-disabled';
const LINK_DISABLED = 'disabled:text-content-disabled disabled:cursor-not-allowed';

const BUTTON_SIZES = {
  icon: 'px-2 py-2 lg:py-2.5 lg:px-2.5 rounded-md',
  small: 'px-2 py-2 lg:py-2.5 lg:px-6 w-auto',
  large: 'px-9 py-2 lg:py-2.5 lg:px-10',
  fixed: 'px-9 py-4 lg:py-2.5 lg:px-10 z-40 fixed bottom-0 left-0 w-full lg:static lg:w-auto',
};

type ButtonSize = keyof typeof BUTTON_SIZES;

const BUTTON_STYLES = {
  regular: 'border bg-primary border-primary text-primary-negative hover:bg-primary-negative hover:text-primary',
  outlined: 'border text-primary border-primary hover:bg-primary hover:text-primary-negative',
  danger: 'border text-danger-400 border-danger-400 hover:text-content-negative hover:bg-danger-400',
  negative: 'border text-primary bg-primary-negative border-primary-negative hover:text-primary-negative hover:bg-primary',
};

type ButtonStyle = keyof typeof BUTTON_STYLES;

const LINK_STYLES = {
  regular: 'text-content-interaction',
  positive: 'text-content',
  negative: 'text-content-negative',
};

type LinkStyle = keyof typeof LINK_STYLES;

const LINE_STYLES = {
  regular: 'bg-content-interaction',
  positive: 'bg-content',
  negative: 'bg-content-negative',
};

type LineStyle = keyof typeof LINE_STYLES;

const LINE_SIZES = {
  regular: 'h-px group-hover:w-20',
  thick: 'h-[3px] group-hover:w-6',
  none: '',
};

type LineSize = keyof typeof LINE_SIZES;

const NO_ICON = 'left-0 max-w-full';
const HAS_ICON_SMALL = 'left-6 max-w-[calc(100%-24px)]';
const HAS_ICON = 'left-7 lg:left-8 max-w-[calc(100%-28px)] lg:max-w-[calc(100%-32px)]';

const linePosition = (small: boolean, right: boolean, icon?: IconName) => {
  if (icon && !right) {
    return small ? HAS_ICON_SMALL : HAS_ICON;
  }

  return NO_ICON;
};

const buttonClass = (size: ButtonSize, styles: ButtonStyle, right: boolean) => (
  classnames(classNames('inline-flex justify-center items-center', BUTTON_SIZES[size], BUTTON_STYLES[styles], BUTTON_DISABLED), {
    'flex-row-reverse': right,
  })
);

const linkClass = (styles: LinkStyle, right: boolean) => (
  classnames(classNames('group relative inline-flex items-center justify-center', LINK_STYLES[styles], LINK_DISABLED), {
    'flex-row-reverse': right,
  })
);

const iconClass = (right: boolean, label: string) => (
  classnames({
    'ml-2': right && !_.isEmpty(label),
    'mr-2': !right && !_.isEmpty(label),
    'mx-0': _.isEmpty(label),
  })
);

const lineClass = (size: LineSize, styles: LineStyle, disabled: boolean) => (
  classnames(classNames('absolute top-full w-0 transition-all duration-300 ease-in-out', LINE_STYLES[styles], LINE_SIZES[size]), {
    hidden: disabled,
  })
);

const BUTTON_CLASSES = {
  button: buttonClass,
  link: linkClass,
  icon: iconClass,
  position: linePosition,
  line: lineClass,
};

export type {
  ButtonSize,
  ButtonStyle,
  LinkStyle,
  LineStyle,
  LineSize,
};

export default BUTTON_CLASSES;
