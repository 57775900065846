import { useManagerActions } from 'managers-app/store';
import { useEffect } from 'react';

const useAppTitle = (title: string): void => {
  const setTitle = useManagerActions((state) => state.app.setTitle);

  useEffect(() => {
    setTitle(title);
  }, [title]);
};

export default useAppTitle;
