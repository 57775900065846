import _ from 'lodash/fp';
import { FunctionComponent, ReactNode } from 'react';
import classnames from 'classnames';
import { Bodycopy } from 'components/typographies';
import { Close } from 'components/icons';
import { classNames } from 'libs';

interface Props {
  message: string | ReactNode;
  success?: boolean;
  onClose?: () => void;
  className?: string;
}

const FormResponse: FunctionComponent<Props> = (
  {
    message,
    success = false,
    onClose,
    className = '',
  }: Props,
) => {
  const FORM_MESSAGE = classnames(classNames('px-4 py-3 flex border rounded-lg max-w-md w-full', className), {
    'bg-danger-200 text-danger-400  border-danger-400': !success,
    'bg-success-200 text-success-400  border-success-400': success,
  });

  const CLOSE = classnames('flex items-center justify-center w-6 h-6 rounded-full bg-backdrop-primary', {
    'hover:bg-success-400 text-success-400 hover:text-success-200': success,
    'hover:bg-danger-400 text-danger-400 hover:text-danger-200': !success,
  });

  if (_.isEmpty(message)) {
    return null;
  }

  return (
    <div className={FORM_MESSAGE}>
      <div className="flex-1 mr-2">
        {typeof message === 'string'
          ? <Bodycopy className="overflow-hidden max-h-10 text-ellipsis lg:max-h-11">{message}</Bodycopy>
          : message}
      </div>
      <Close onClose={onClose} className={CLOSE} />
    </div>
  );
};

export default FormResponse;
