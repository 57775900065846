import _ from 'lodash/fp';
import { useMatch, useResolvedPath } from 'react-router-dom';

const useIsMatch = (to: string, end = true): boolean => {
  const resolved = useResolvedPath(to);
  const match = useMatch({ path: resolved.pathname, end });

  return !_.isNil(match);
};

export default useIsMatch;
