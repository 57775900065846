import { Thunk, thunk } from 'easy-peasy';
import { ChangeStateParams } from 'models';
import { API, handleResponse } from 'libs';

import clients from '../../clients';

interface RequestWorksModel {
  execute: Thunk<RequestWorksModel, ChangeStateParams>;
  requirement: Thunk<RequestWorksModel, ChangeStateParams>;
  assignment: Thunk<RequestWorksModel, ChangeStateParams>;
  assignmentRequirement: Thunk<RequestWorksModel, ChangeStateParams>;
  administration: Thunk<RequestWorksModel, ChangeStateParams>;
  administrationRequirement: Thunk<RequestWorksModel, ChangeStateParams>;
}

const requestWorksModel: RequestWorksModel = {
  execute: thunk(async (_actions, payload, { getStoreActions }) => {
    const url = `/requests/${payload.requestID}/works/${payload.id}/execute`;
    const response = await API.post(clients)(url, payload.params);
    // @ts-ignore
    const { initCurrent: init } = getStoreActions().requests;
    return handleResponse(response, init);
  }),

  requirement: thunk(async (_actions, payload, { getStoreActions }) => {
    const url = `/requests/${payload.requestID}/works/${payload.id}/requirement`;
    const response = await API.post(clients)(url, payload.params);
    // @ts-ignore
    const { initCurrent: init } = getStoreActions().requests;
    return handleResponse(response, init);
  }),

  assignment: thunk(async (_actions, payload, { getStoreActions }) => {
    const url = `/requests/${payload.requestID}/works/${payload.id}/assignment`;
    const response = await API.post(clients)(url, payload.params);
    // @ts-ignore
    const { initCurrent: init } = getStoreActions().requests;
    return handleResponse(response, init);
  }),

  assignmentRequirement: thunk(async (_actions, payload, { getStoreActions }) => {
    const url = `/requests/${payload.requestID}/works/${payload.id}/assignment-requirement`;
    const response = await API.post(clients)(url, payload.params);
    // @ts-ignore
    const { initCurrent: init } = getStoreActions().requests;
    return handleResponse(response, init);
  }),

  administration: thunk(async (_actions, payload, { getStoreActions }) => {
    const url = `/requests/${payload.requestID}/works/${payload.id}/administration`;
    const response = await API.post(clients)(url, payload.params);
    // @ts-ignore
    const { initCurrent: init } = getStoreActions().requests;
    return handleResponse(response, init);
  }),

  administrationRequirement: thunk(async (_actions, payload, { getStoreActions }) => {
    const url = `/requests/${payload.requestID}/works/${payload.id}/administration-requirement`;
    const response = await API.post(clients)(url, payload.params);
    // @ts-ignore
    const { initCurrent: init } = getStoreActions().requests;
    return handleResponse(response, init);
  }),
};

export type { RequestWorksModel };

export default requestWorksModel;
