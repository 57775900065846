import {
  action,
  Action,
  thunk,
  Thunk,
} from 'easy-peasy';
import { API, handleResponse } from 'libs';
import {
  ClientCompany,
  createClientCompany,
  createSelectables,
  IClientCompany,
  Selectable,
  Selectables,
} from 'models';

import clients from '../../clients';

interface CompanyModel {
  item: IClientCompany;
  selectables: Selectables;

  init: Action<CompanyModel, ClientCompany>;
  initSelectables: Action<CompanyModel, Selectable[]>;

  index: Thunk<CompanyModel>;
  indexSelectables: Thunk<CompanyModel>;
}

const companyModel: CompanyModel = {
  item: createClientCompany(),
  selectables: createSelectables(),

  init: action((state, payload) => {
    state.item = createClientCompany(payload);
  }),

  initSelectables: action((state, payload) => {
    state.selectables = createSelectables(payload);
  }),

  index: thunk(async (actions, _payload) => {
    const response = await API.get(clients)('/company');
    return handleResponse(response, actions.init);
  }),

  indexSelectables: thunk(async (actions, _payload) => {
    const response = await API.get(clients)('/selectables');
    return handleResponse(response, actions.initSelectables);
  }),
};

export type { CompanyModel };

export default companyModel;
