import { FunctionComponent, ReactNode } from 'react';
import classnames from 'classnames';
import { Caption, H3 } from 'components/typographies';
import { Icon } from 'components/icons';

interface Props {
  open: boolean;
  onClose: () => void;
  title: string;
  children: ReactNode;
  action?: boolean;
  subtitle?: string;
  large?: boolean;
  wide?: boolean;
}

const Modal: FunctionComponent<Props> = (
  {
    open,
    onClose,
    title,
    children,
    action = false,
    subtitle,
    large = false,
    wide = false,
  }: Props,
) => {
  if (!open) return null;

  const MODAL = classnames('fixed w-screen h-screen z-50 flex items-end top-0 left-0 bg-overlay justify-center md:items-center', {
    'cursor-pointer': !action,
  });

  const CARD = classnames('p-6 pb-9  max-h-[90vh] rounded-t-xl bg-backdrop-primary w-full md:pb-6 md:rounded-xl overflow-y-auto', {
    'md:max-w-[598px]': wide,
    'md:max-w-md': !wide,
  });

  const TITLE = classnames('flex justify-between', {
    'mb-8': !subtitle,
    'mb-2': subtitle,
    'items-center': !large,
    'items-start': large,
  });

  const TITLE_TEXT = classnames('font-normal', { truncate: !large });
  const CLOSE = classnames('cursor-pointer', { 'mt-1': large });

  return (
    <div className={MODAL} onClick={action ? undefined : onClose}>
      <div className={CARD}>
        <div className={TITLE}>
          <H3 className={TITLE_TEXT}>{title}</H3>
          <div onClick={onClose} className={CLOSE}>
            <Icon name="close" className="text-content-interaction" />
          </div>
        </div>
        {subtitle && <Caption className="mb-8 font-normal">{subtitle}</Caption>}
        {children}
      </div>
    </div>
  );
};

export default Modal;
