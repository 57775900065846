import { Thunk, thunk } from 'easy-peasy';
import { API, handleResponse } from 'libs';
import { ChangeStateParams } from 'models';

import managers from '../../managers';

interface RequestCancelationModel {
  create: Thunk<RequestCancelationModel, ChangeStateParams>;
}

const requestCancelationModel: RequestCancelationModel = {
  create: thunk(async (_actions, payload, { getStoreActions }) => {
    const url = `/requests/${payload.requestID}/cancelation/${payload.id}`;
    const response = await API.post(managers)(url, payload.params);
    // @ts-ignore
    const { initCurrent: init } = getStoreActions().requests;
    return handleResponse(response, init);
  }),
};

export type { RequestCancelationModel };

export default requestCancelationModel;
