import { FunctionComponent, ReactNode } from 'react';
import { classNames } from 'libs';

interface Props {
  children: ReactNode;
  className?: string;
}

const Caption: FunctionComponent<Props> = ({ children, className = '' }: Props) => (
  <p className={classNames('text-s-sm xl:text-l-sm', className)}>
    {children}
  </p>
);

export default Caption;
