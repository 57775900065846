import { useClientState } from 'clients-app/store';
import { FunctionComponent } from 'react';
import classnames from 'classnames';
import { useIsMatch } from 'hooks';
import { PATHS, ROUTES } from 'clients-app/libs';
import { t } from 'libs';
import { Link } from 'react-router-dom';

import DesktopNavItem from './components/DesktopNavItem';
import DesktopHover from './components/DesktopHover';
import ItemNav from './components/ItemNav';

const DesktopMenu: FunctionComponent = () => {
  const isHome = useIsMatch(PATHS.index);
  const loggedIn = useClientState((state) => state.session.loggedIn);
  const company = useClientState((state) => state.company.item);
  const selfconsumption = (
    company.isPremium ? ROUTES.selfconsumption.index : ROUTES.selfconsumption.procedures
  );

  const BUTTON = classnames('block text-l-base-b py-2 px-[30px] border ml-12', {
    'bg-transparent border-primary-negative text-primary-negative  hover:bg-primary-negative hover:text-primary': !isHome,
    'bg-primary-negative border-primary-negative text-primary ml-12 hover:bg-transparent hover:text-primary-negative': isHome,
  });

  return (
    <div className="flex items-center justify-end flex-1">
      {company.isPremium
      && <DesktopNavItem to={ROUTES.breakdowns.index} label={t('clients.pages.breakdowns_cuts')} />}
      <DesktopHover title={t('clients.pages.access_connection')}>
        <ItemNav to={ROUTES.supply.index} label={t('clients.pages.electrical_supply')} />
        <ItemNav to={selfconsumption} label={t('clients.pages.selfconsumption')} />
        <ItemNav to={ROUTES.generation} label={t('clients.pages.energy_generation')} />
        <ItemNav to={ROUTES.nodal} label={t('clients.pages.nodal_capacities')} />
      </DesktopHover>
      <DesktopHover title={t('clients.pages.help')}>
        <ItemNav to={ROUTES.contact.index} label={t('clients.pages.contact')} />
        <ItemNav to={ROUTES.normative} label={t('clients.pages.normative')} />
      </DesktopHover>
      <Link to={ROUTES.profile.index} className={BUTTON}>
        {loggedIn ? t('clients.welcome') : t('clients.private_area')}
      </Link>
    </div>
  );
};

export default DesktopMenu;
