import { Icon } from 'components/icons';
import { FunctionComponent, ReactNode } from 'react';
import classnames from 'classnames';

interface Props {
  title: string;
  children: ReactNode;
  open: boolean;
  onClick: () => void;
}

const MobileAccordion: FunctionComponent<Props> = (
  {
    title,
    children,
    open,
    onClick,
  }: Props,
) => {
  const TITLE = classnames('flex justify-between items-start py-3', { 'mb-3': open });
  const CONTENT = classnames('border-b border-divider-200', {
    'pb-4': open,
    'h-0 overflow-hidden': !open,
  });

  return (
    <div onClick={onClick} className="cursor-pointer">
      <div className={TITLE}>
        <p className="text-s-lg text-content">{title}</p>
        <Icon
          name={open ? 'circle-angle-top' : 'circle-angle-down'}
          className="text-content-interaction min-w-[24px]"
        />
      </div>
      <div className={CONTENT}>
        {children}
      </div>
    </div>
  );
};

export default MobileAccordion;
