import { Thunk, thunk } from 'easy-peasy';
import { API, handleResponse } from 'libs';
import { ChangeStateParams } from 'models';

import managers from '../../managers';

interface RequestPermissionsModel {
  create: Thunk<RequestPermissionsModel, ChangeStateParams>;
  cups: Thunk<RequestPermissionsModel, ChangeStateParams>;
  client: Thunk<RequestPermissionsModel, ChangeStateParams>;
  distributor: Thunk<RequestPermissionsModel, ChangeStateParams>;
  works: Thunk<RequestPermissionsModel, ChangeStateParams>;
}

const requestPermissionsModel: RequestPermissionsModel = {
  create: thunk(async (_actions, payload, { getStoreActions }) => {
    const url = `/requests/${payload.requestID}/permissions/${payload.id}`;
    const response = await API.post(managers)(url, payload.params);
    // @ts-ignore
    const { initCurrent: init } = getStoreActions().requests;
    return handleResponse(response, init);
  }),

  cups: thunk(async (_actions, payload, { getStoreActions }) => {
    const url = `/requests/${payload.requestID}/permissions/${payload.id}/cups`;
    const response = await API.post(managers)(url, payload.params);
    // @ts-ignore
    const { initCurrent: init } = getStoreActions().requests;
    return handleResponse(response, init);
  }),

  client: thunk(async (_actions, payload, { getStoreActions }) => {
    const url = `/requests/${payload.requestID}/permissions/${payload.id}/client`;
    const response = await API.post(managers)(url, payload.params);
    // @ts-ignore
    const { initCurrent: init } = getStoreActions().requests;
    return handleResponse(response, init);
  }),

  distributor: thunk(async (_actions, payload, { getStoreActions }) => {
    const url = `/requests/${payload.requestID}/permissions/${payload.id}/distributor`;
    const response = await API.post(managers)(url, payload.params);
    // @ts-ignore
    const { initCurrent: init } = getStoreActions().requests;
    return handleResponse(response, init);
  }),

  works: thunk(async (_actions, payload, { getStoreActions }) => {
    const url = `/requests/${payload.requestID}/permissions/${payload.id}/works`;
    const response = await API.post(managers)(url, payload.params);
    // @ts-ignore
    const { initCurrent: init } = getStoreActions().requests;
    return handleResponse(response, init);
  }),
};

export type { RequestPermissionsModel };

export default requestPermissionsModel;
