import { Thunk, thunk } from 'easy-peasy';
import { API, handleResponse } from 'libs';
import { ChangeStateParams } from 'models';

import managers from '../../managers';

interface RequestLocationModel {
  report: Thunk<RequestLocationModel, ChangeStateParams>;
  cups: Thunk<RequestLocationModel, ChangeStateParams>;
  client: Thunk<RequestLocationModel, ChangeStateParams>;
  distributor: Thunk<RequestLocationModel, ChangeStateParams>;
  works: Thunk<RequestLocationModel, ChangeStateParams>;
  require: Thunk<RequestLocationModel, ChangeStateParams>;
}

const requestLocationModel: RequestLocationModel = {
  report: thunk(async (_actions, payload, { getStoreActions }) => {
    const url = `/requests/${payload.requestID}/location/${payload.id}/report`;
    const response = await API.post(managers)(url, payload.params);
    // @ts-ignore
    const { initCurrent: init } = getStoreActions().requests;
    return handleResponse(response, init);
  }),

  cups: thunk(async (_actions, payload, { getStoreActions }) => {
    const url = `/requests/${payload.requestID}/location/${payload.id}/cups`;
    const response = await API.post(managers)(url, payload.params);
    // @ts-ignore
    const { initCurrent: init } = getStoreActions().requests;
    return handleResponse(response, init);
  }),

  client: thunk(async (_actions, payload, { getStoreActions }) => {
    const url = `/requests/${payload.requestID}/location/${payload.id}/client`;
    const response = await API.post(managers)(url, payload.params);
    // @ts-ignore
    const { initCurrent: init } = getStoreActions().requests;
    return handleResponse(response, init);
  }),

  distributor: thunk(async (_actions, payload, { getStoreActions }) => {
    const url = `/requests/${payload.requestID}/location/${payload.id}/distributor`;
    const response = await API.post(managers)(url, payload.params);
    // @ts-ignore
    const { initCurrent: init } = getStoreActions().requests;
    return handleResponse(response, init);
  }),

  works: thunk(async (_actions, payload, { getStoreActions }) => {
    const url = `/requests/${payload.requestID}/location/${payload.id}/works`;
    const response = await API.post(managers)(url, payload.params);
    // @ts-ignore
    const { initCurrent: init } = getStoreActions().requests;
    return handleResponse(response, init);
  }),

  require: thunk(async (_actions, payload, { getStoreActions }) => {
    const url = `/requests/${payload.requestID}/location/${payload.id}/require`;
    const response = await API.post(managers)(url, payload.params);
    // @ts-ignore
    const { initCurrent: init } = getStoreActions().requests;
    return handleResponse(response, init);
  }),
};

export type { RequestLocationModel };

export default requestLocationModel;
