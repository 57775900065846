import { ChangeEvent, FocusEvent, FunctionComponent } from 'react';
import _ from 'lodash/fp';
import classnames from 'classnames';

import FormGroup from './components/FormGroup';
import InputError from './components/InputError';
import RadioButton from './components/RadioButton';
import { Option } from './types';

interface Props {
  name: string;
  readOnly?: boolean;
  disabled?: boolean;
  value: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
  options: Option[];
  error?: string;
  touched?: boolean;
  className?: string;
  bold?: boolean;
}

const RadioButtons: FunctionComponent<Props> = (
  {
    name,
    readOnly = false,
    disabled = false,
    value,
    onChange,
    onBlur,
    options,
    error = '',
    touched = false,
    className = '',
    bold = false,
  }: Props,
) => (
  <FormGroup className={className}>
    {_.map((option: Option) => (
      <RadioButton
        key={option.id}
        id={option.id}
        name={name}
        value={option.id}
        placeholder={option.label}
        checked={value === option.id}
        onChange={onChange}
        onBlur={onBlur}
        readOnly={readOnly}
        disabled={disabled}
        description={option.description}
        bold={bold}
        className="mb-5 last-of-type:mb-0"
      />
    ))(options)}
    <InputError
      className={classnames({ hidden: !touched || disabled, block: touched && !_.isEmpty(error) })}
      error={error}
    />
  </FormGroup>
);

export default RadioButtons;
