import { ROUTES } from 'clients-app/libs';
import { ButtonLink, InternalLink } from 'components/buttons';
import { Tag } from 'components/tags';
import { Caption, H3 } from 'components/typographies';
import { t } from 'libs';
import { IContract } from 'models';
import { FunctionComponent, ReactNode } from 'react';

interface BaseCardProps {
  children: ReactNode;
}

const BaseCard: FunctionComponent<BaseCardProps> = ({ children }: BaseCardProps) => (
  <div className="min-w-[310px] lg:min-w-[380px] block border-r border-divider-200 px-6 last:border-0">
    {children}
  </div>
);

interface Props {
  contract: IContract;
}

const ContractCard: FunctionComponent<Props> = ({ contract }: Props) => {
  if (!contract.validated) {
    return (
      <BaseCard>
        <div className="flex items-center mb-2">
          <H3>{contract.cups}</H3>
        </div>
        <div className="mb-6">
          <Caption className="mb-4 text-content-disabled">
            {t('clients.contracts.pending_contract')}
          </Caption>
          <Caption className="inline-block px-2 py-1 bg-backdrop-secondary">
            {t(`models.contract.${contract.owner}`)}
          </Caption>
        </div>
      </BaseCard>
    );
  }

  return (
    <BaseCard>
      <div className="flex items-center mb-2">
        <H3 className="mr-4 truncate">{contract.naming}</H3>
        <Tag styles={contract.state} label={t(`models.contract.${contract.status}`)} />
      </div>
      <div className="mb-6">
        <Caption className="mb-4 text-content-disabled">
          {t('power', { power: contract.power })}
        </Caption>
        <Caption className="inline-block px-2 py-1 bg-backdrop-secondary">
          {t(`models.contract.${contract.owner}`)}
        </Caption>
      </div>
      <div className="flex items-center justify-between">
        <ButtonLink
          to={`${ROUTES.consumption.index}?contract=${contract.id}`}
          label={t('clients.contracts.consumption')}
          styles="outlined"
        />
        <InternalLink
          to={ROUTES.contracts.show(contract.id)}
          label={t('clients.contracts.see_contract')}
        />
      </div>
    </BaseCard>
  );
};

export default ContractCard;
