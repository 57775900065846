import { FunctionComponent } from 'react';
import classnames from 'classnames';
import { classNames, t } from 'libs';

import { FILTER_TAG_STYLES, FilterTagStyles } from './styles';

interface Props {
  label: string;
  onSelect: () => void;
  selected: boolean;
  number?: number;
  className?: string;
  color?: FilterTagStyles;
}

const FilterTag: FunctionComponent<Props> = (
  {
    label,
    onSelect,
    selected,
    number,
    className = '',
    color,
  }: Props,
) => {
  const TAG = classnames(classNames('flex items-center cursor-pointer px-2 border py-0.5 border-divider-400 rounded-lg', className), {
    'bg-content text-content-negative': selected,
    'bg-backdrop-primary text-content': !selected,
  });

  if (number === 0) return null;

  return (
    <div className={TAG} onClick={onSelect}>
      {color && (
        <span className={classNames('w-2 h-2 min-w-[8px] min-h-[8px] mr-2 bg-sarg-400 rounded-full', FILTER_TAG_STYLES[color])} />
      )}
      <p className="text-l-base">{label}</p>
      {number && (
        <>
        &nbsp;
          <p className="text-l-base">{t('managers.parentesis', { number })}</p>
        </>
      )}
    </div>
  );
};

export default FilterTag;
