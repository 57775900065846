import { useState } from 'react';

const useHandleResponse = (): [string, (r: string | boolean) => void] => {
  const [message, setMessage] = useState('');

  const handleResponse = (r: string | boolean): void => (
    typeof r === 'string' ? setMessage(r) : setMessage('')
  );

  return [message, handleResponse];
};

export default useHandleResponse;
