import { Squared } from 'components/decorators';
import { Icon, IconName } from 'components/icons';
import { ButtonText } from 'components/typographies';
import { FunctionComponent } from 'react';
import classnames from 'classnames';

interface Props {
  icon: IconName
  label: string;
  disabled?: boolean;
}

const InnerCard: FunctionComponent<Props> = ({ icon, label, disabled = false }: Props) => {
  const LABEL = classnames('mr-auto', {
    'text-content': !disabled,
    'text-content-disabled': disabled,
  });

  const ARROW = classnames('ml-auto', {
    'text-content-interaction': !disabled,
    'text-content-disabled': disabled,
  });

  return (
    <>
      <Squared size="small-growth" className="mr-4 lg:mr-5" styles={disabled ? 'disabled' : 'regular'}>
        <Icon name={icon} size="medium-growth" />
      </Squared>
      <ButtonText className={LABEL}>{label}</ButtonText>
      <Icon name="circle-angle-right" className={ARROW} animate />
    </>
  );
};

export default InnerCard;
