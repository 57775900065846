import {
  Action,
  Thunk,
  action,
  thunk,
} from 'easy-peasy';
import { API, handleResponse } from 'libs';
import { AppDocument, ID, RejectionParams } from 'models';

import managers from '../../managers';

interface VerificationModel {
  items: AppDocument[];

  init: Action<VerificationModel, AppDocument[]>;

  index: Thunk<VerificationModel, ID>;
  verify: Thunk<VerificationModel, ID>;
  reject: Thunk<VerificationModel, RejectionParams>;
}

const verificationModel: VerificationModel = {
  items: [],

  init: action((state, payload) => {
    state.items = payload;
  }),

  index: thunk(async (actions, payload) => {
    const response = await API.get(managers)(`/clients/${payload}/verification`);
    return handleResponse(response, actions.init);
  }),

  verify: thunk(async (_actions, payload, { getStoreActions }) => {
    const response = await API.put(managers)(`/clients/${payload}/verification/verify`);
    // @ts-ignore
    const { initCurrent, index } = getStoreActions().clients;
    index();
    return handleResponse(response, initCurrent);
  }),

  reject: thunk(async (_actions, payload, { getStoreActions }) => {
    const response = await API.put(managers)(`/clients/${payload.id}/verification/reject`, {
      cause: payload.cause,
    });
    // @ts-ignore
    const { initCurrent, index } = getStoreActions().clients;
    index();
    return handleResponse(response, initCurrent);
  }),
};

export type { VerificationModel };

export default verificationModel;
