import { FunctionComponent, ReactNode } from 'react';
import classnames from 'classnames';
import _ from 'lodash/fp';
import { classNames } from 'libs';

import { CheckProps, FieldProps } from './types';
import FormGroup from './components/FormGroup';
import Label from './components/Label';
import Input from './components/Input';
import InputError from './components/InputError';
import { TYPOGRAPHY } from './styles';

interface Props extends CheckProps, FieldProps {
  children: ReactNode;
}

const CheckField: FunctionComponent<Props> = (
  {
    id,
    name,
    readOnly = false,
    disabled = false,
    required = false,
    className = '',
    value,
    onChange,
    onBlur,
    touched = false,
    error = '',
    children,
  }: Props,
) => {
  const hasError = touched && !_.isEmpty(error);

  const FIELD = classNames('flex', className);

  const CHECK = classnames('min-w-[20px] w-5 h-5 block border flex items-center mr-2', {
    'border-content cursor-pointer': !disabled,
    'border-content-disabled cursor-not-allowed': disabled,
    'bg-content ring-[2px] ring-inset ring-backdrop-primary': value,
    'bg-backdrop-primary': !value,
    'bg-content-disabled': disabled && value,
  });

  const MESSAGE = classnames(TYPOGRAPHY, {
    'cursor-pointer': !disabled,
    'cursor-not-allowed': disabled,
  });

  const ERROR = classnames({
    hidden: !touched || disabled,
    block: hasError,
  });

  return (
    <FormGroup className={FIELD}>
      <Label
        value=""
        htmlFor={id}
        className={CHECK}
      />
      <label htmlFor={id} className={MESSAGE}>
        {children}
      </label>
      <Input
        type="checkbox"
        id={id}
        name={name}
        readOnly={readOnly}
        disabled={disabled}
        required={required}
        className="hidden"
        checked={value}
        onChange={onChange}
        onBlur={onBlur}
      />
      <InputError error={error} className={ERROR} />
    </FormGroup>
  );
};

export default CheckField;
