import { FunctionComponent, ReactNode } from 'react';
import { classNames } from 'libs';

interface Props {
  children: ReactNode;
  className?: string;
}

const FormGroup: FunctionComponent<Props> = ({ children, className = '' }) => (
  <div className={classNames('max-w-md relative w-full', className)}>
    {children}
  </div>
);

export default FormGroup;
