import { Client } from 'models/client';
import { Distributor } from 'models/distributor/types';
import { AppDocument, DocType, RequestDocuments } from 'models/document';
import { RequestTypes } from 'models/manager';
import { Days } from 'features/requests';

import { ID } from '../app/types';

type ClientData = {
  clientType: string;
  clientName: string;
  clientIdentityNumber: string;
  clientEmail: string;
  clientPhoneNumber: string;
  clientAddress: string;
  clientPostalCode: string;
  clientMunicipality: string;
  clientProvince: string;
}

type RequestData = {
  municipality: string;
  province: string;
  terrain: string;
}

type AddressData = {
  address: string;
  postalCode: string;
}

type RegularInstallationData = {
  maxInstallationPower: string;
  tension: string;
  use: string;
  useDescription: string;
  installationType: string;
}

type ConsumptionData = {
  tension: string;
  installationPower: string;
  primaryPowerSource: string;
  primaryPowerSourceDescription: string;
}

type SelfconsumptionData = {
  selfconsumptionType: string;
  connectionType: string;
  dischargedIntoNetwork: boolean;
  admitedCompensation: boolean;
}

type SpecsData = {
  hibridization: boolean;
  hibridizationDescription: string;
  accumulation: boolean;
  accumulationDescription: string;
  environmentalImpactEvaluation: boolean;
  amountOfGuarantee: string;
  node: string;
}

type CommonData = {
  createdAt?: string;
  observations: string;
  clientID: number;
  distributorID: number;
  companyID: number;
  acceptance: boolean;
}

type SupplyPointCupsRequest =
ClientData & RequestData & CommonData & AddressData & RegularInstallationData & {
  cadastralReference: string;
  technicalReport: AppDocument;
  project: AppDocument;
  charges: AppDocument;
  authorization: AppDocument;
}

type SupplyPointRequest =
ClientData & RequestData & CommonData & AddressData & RegularInstallationData & {
  cadastralReference: string;
  technicalReport: AppDocument;
  technicalBlueprint: AppDocument;
  authorization: AppDocument;
}

type CupsRequest =
ClientData & RequestData & CommonData & AddressData & RegularInstallationData & {
  cadastralReference: string;
  installationBulletin: AppDocument;
  license: AppDocument;
  ownership: AppDocument;
  authorization: AppDocument;
}

type CauRequest =
RequestData & CommonData & AddressData & ConsumptionData & SelfconsumptionData & {
  cups: string;
}

type SelfconsumptionRequest =
ClientData & RequestData & CommonData & AddressData &
ConsumptionData & SelfconsumptionData & SpecsData & {
  cups: string;
  hiredPotency: string;
  cadastralReference: string;
  technicalReport: AppDocument;
  technicalBlueprint: AppDocument;
  warranty: AppDocument;
  environmentalImpactReceipt: AppDocument;
  constitutionOfGuarantee: AppDocument;
  authorization: AppDocument;
}

type GenerationRequest =
ClientData & RequestData & CommonData & ConsumptionData & SpecsData & {
  cadastralReference: string;
  technicalBlueprint: AppDocument;
  warranty: AppDocument;
  environmentalImpactReceipt: AppDocument;
  constitutionOfGuarantee: AppDocument;
  T243: AppDocument;
  generalSituationPlan: AppDocument;
  privateLocationPlan: AppDocument;
  significantDistances: AppDocument;
  generalImplementationPlan: AppDocument;
  generalImplementationPlanDWG: AppDocument;
  authorization: AppDocument;
}

const LITE_STEPS = [
  'Documentación',
  'Capacidad y viabilidad',
  'Propuesta',
  'Permisos',
  'CUPS',
  'CAU',
] as const;

const REQUEST_STEPS = [
  ...LITE_STEPS,
  'Propuesta previa',
  'Ubicación instalación',
  'Obras',
  'Contador',
  'Cancelada',
] as const;

type RequestSteps = typeof REQUEST_STEPS[number];

const LITE_STATES = [
  'Analizando documentación',
  'Documentación requerida',
  'Analizando capacidad y viabilidad',
  'Afección aguas arriba',
  'Denegado por capacidad y/o viabilidad',
  'Propuesta previa recibida',
  'Revisando propuesta previa',
  'Elaborando permisos',
  'Permisos emitidos',
  'Cancelada por el gestor',
  'Cancelada',
] as const;

const REQUEST_STATES = [
  ...LITE_STATES,
  'Preparando doc aguas arriba',
  'Esperando respuesta aguas arriba',
  'Validando capacidad y viabilidad',
  'Denegado por capacidad y/o viabilidad',
  'Denegada',
  'Envio propuesta previa',
  'Propuesta previa enviada',
  'Revisión propuesta previa',
  'Ubicación instalaciones y derechos de extensión',
  'Revisando informe de instalaciones',
  'Empezar obras en la red',
  'Elaborando permisos de acceso y conexión',
  'Ejecutando obras necesarias',
  'Ejecutando obras de acceso y conexión',
  'Esperando autorización',
  'Puesta en servicio de la instalación',
  'Cesión instalaciones',
  'Analizando cesión',
  'Documentación cesión',
  'Autorización de la administración',
  'Analizando autorización',
  'Documentación autorización',
  'Obras finalizadas',
  'Elaborando CUPS',
  'Esperando solicitud comercializadora',
  'Actualización de estado',
  'Proceso finalizado',
] as const;

type RequestStates = typeof REQUEST_STATES[number];

type Annotation = {
  id?: number;
  createdAt?: string;
  writter: string;
  comment: string;
}

type RequestStep = {
  id: number;
  number: number;
  name: RequestSteps;
  description: RequestStates;
}

type HisoricalMetadata = {
  managerComment?: string;
  clientComment?: string;
  responsable: string | 'Tú' | 'Distribuidora';
  requirements: DocType[];
  workRequirements: DocType[];
  workTime: string;
  distributorWorks: boolean;
  clientWorks: boolean;
  accepted: boolean;
  date: string;
  times: string[];
  presence: boolean;
  cups: string[];
}

type Historical = {
  id: number;
  createdAt?: string;

  type: RequestTypes;
  limitDate: string;
  finishDate: string;
  daysToLimit: number;
  totalDays: number;

  stepName: RequestSteps;
  stepDescription: RequestStates;

  metadata: HisoricalMetadata;
  documents: AppDocument[];
}

type RequestMetadata = {
  daysLeft: number;
  distributorWorks: boolean;
  clientWorks: boolean;
  workRequirements: DocType[];
  requirements: DocType[];
  workTime: Days;
  works: boolean;
  distributorWorksFinish: boolean;
  clientWorksFinish: boolean;
}

type RequestState = {
  name: string;
  clientDescription: RequestStates;
  managerDescription: RequestStates;
  clientLimit: string;
  managerLimit: string;
  responsable: string | 'Tú' | 'Distribuidora';
}

type Request = {
  id: number;
  createdAt?: string;
  authomatism: boolean;
  type: RequestTypes;
  code: string;
  power: string;
  address: string;
  totalDays: number;
  finishDate: string;

  state: RequestState;
  metadata: RequestMetadata;

  documents: AppDocument[];
  steps: RequestStep[];
  historicals: Historical[];

  clientData: ClientData | null;
  distributor: Distributor;
  client: Client;
}

type DefaultParams = {
  companyID: number;
  clientID: number;
  container: string;
}

type NewRequest = {
  uri: string;
  request: SupplyPointRequest | CupsRequest | CauRequest
  | SelfconsumptionRequest | GenerationRequest | SupplyPointCupsRequest;
}

type RequestParams = {
  managerComment?: string;
  clientComment?: string;
  documents?: AppDocument[];
  abbreviated?: boolean;
  days?: number;
  responsable: string;
  type?: string;
  upstream?: boolean;
  requirements?: string[];
  distributorWorks?: boolean;
  clientWorks?: boolean;
  workRequirements?: string[];
  createdAt?: string;
  workTime?: Days;
  distributorWorksFinish?: boolean;
  clientWorksFinish?: boolean;
  assignment?: boolean;
  date?: string;
  times?: string[];
  presence?: boolean;
  cups?: string[];
}

type ChangeStateParams = {
  requestID: ID;
  id: ID;
  params: RequestParams;
}

interface IHistoricals {
  items: Historical[];
  byStep: (step: RequestSteps) => Historical[];
  finishStepDate: (step: RequestSteps) => string;
  limitStepDate: (step: RequestSteps) => string;
  finishWorksStepDate: () => string;
  limitWorksStepDate: () => string;
  clientWorks: () => Historical[];
  distributorWorks: () => Historical[];
  current: () => Historical;
  first: () => Historical;
}

interface IRequest extends Request {
  userName: string;
  userID: string;
  clientToLimit: number;
  managerToLimit: number;
  finished: boolean;
  closestLimit: () => string;
  document: (docType: RequestDocuments) => string;
  status: () => RequestStates;
  history: IHistoricals;
  cover: (step: RequestSteps) => boolean;
  hasWorks: boolean;
}

export {
  REQUEST_STATES,
  REQUEST_STEPS,
  LITE_STATES,
  LITE_STEPS,
};

export type {
  ClientData,
  RequestData,
  AddressData,
  RegularInstallationData,
  ConsumptionData,
  SelfconsumptionData,
  SpecsData,
  CommonData,
  SupplyPointRequest,
  SupplyPointCupsRequest,
  CupsRequest,
  CauRequest,
  SelfconsumptionRequest,
  GenerationRequest,
  RequestStep,
  Historical,
  Request,
  DefaultParams,
  NewRequest,
  IRequest,
  RequestStates,
  RequestSteps,
  IHistoricals,
  ChangeStateParams,
  RequestParams,
  Annotation,
};
