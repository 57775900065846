import { Thunk, thunk } from 'easy-peasy';
import { ChangeStateParams } from 'models';
import { API, handleResponse } from 'libs';

import clients from '../../clients';

interface RequestPreproposalModel {
  accept: Thunk<RequestPreproposalModel, ChangeStateParams>;
  reject: Thunk<RequestPreproposalModel, ChangeStateParams>;
  requirement: Thunk<RequestPreproposalModel, ChangeStateParams>;
}

const requestPreproposalModel: RequestPreproposalModel = {
  accept: thunk(async (_actions, payload, { getStoreActions }) => {
    const url = `/requests/${payload.requestID}/pre-proposal/${payload.id}/accept`;
    const response = await API.post(clients)(url, payload.params);
    // @ts-ignore
    const { initCurrent: init } = getStoreActions().requests;
    return handleResponse(response, init);
  }),

  reject: thunk(async (_actions, payload, { getStoreActions }) => {
    const url = `/requests/${payload.requestID}/pre-proposal/${payload.id}/reject`;
    const response = await API.post(clients)(url, payload.params);
    // @ts-ignore
    const { initCurrent: init } = getStoreActions().requests;
    return handleResponse(response, init);
  }),

  requirement: thunk(async (_actions, payload, { getStoreActions }) => {
    const url = `/requests/${payload.requestID}/pre-proposal/${payload.id}/requirement`;
    const response = await API.post(clients)(url, payload.params);
    // @ts-ignore
    const { initCurrent: init } = getStoreActions().requests;
    return handleResponse(response, init);
  }),
};

export type { RequestPreproposalModel };

export default requestPreproposalModel;
