import { FunctionComponent, useEffect, useState } from 'react';
import _ from 'lodash/fp';

import HeaderContext from './context';
import MobileHeader from './MobileHeader';
import DesktopHeader from './DesktopHeader';

const Header: FunctionComponent = () => {
  const [filled, setFilled] = useState(false);

  const onScroll = (): void => {
    const { scrollY } = window;
    setFilled(_.gte(scrollY)(10));
  };

  useEffect(() => {
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  return (
    <HeaderContext.Provider value={filled}>
      <header className="fixed top-0 left-0 z-50 flex flex-col justify-center w-full h-14 xl:h-16">
        <MobileHeader />
        <DesktopHeader />
      </header>
    </HeaderContext.Provider>
  );
};

export default Header;
