import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const useForbidenRedirect = (condition: boolean, route: string, from = false): void => {
  const navigate = useNavigate();
  const location = useLocation();
  const fromLocation = { replace: true, state: { from: location } };

  useEffect(() => {
    if (condition) {
      if (from) {
        navigate(route, fromLocation);
        return;
      }
      navigate(route);
    }
  }, [condition]);
};

export default useForbidenRedirect;
