import { FunctionComponent } from 'react';
import { classNames } from 'libs';
import classnames from 'classnames';

import ICONS, { IconName } from './icons';
import { ICON_SIZES, IconSize, ANIMATE } from './styles';

interface Props {
  name: IconName;
  size?: IconSize;
  animate?: boolean;
  className?: string;
}

const Icon: FunctionComponent<Props> = (
  {
    name,
    size = 'medium',
    animate = false,
    className = '',
  }: Props,
) => (
  <span className={classnames(classNames(`flex items-center justify-center aspect-square ${ICON_SIZES[size]}`, className), { [`${ANIMATE}`]: animate })}>
    <svg viewBox={ICONS[name].viewBox} xmlns="http://www.w3.org/2000/svg" className="w-full h-full fill-current stroke-current">
      {ICONS[name].object}
    </svg>
  </span>
);

export default Icon;
