import { ROUTES } from 'clients-app/libs';
import { t } from 'libs';
import { FunctionComponent } from 'react';
import { useClientState } from 'clients-app/store';

import TabItem from './TabItem';

const BottomMenu: FunctionComponent = () => {
  const company = useClientState((state) => state.company.item);

  return (
    <div className="fixed bottom-0 left-0 z-40 w-full shadow-top lg:hidden bg-footer-negative">
      <div className="flex mx-auto w-full max-w-screen-xs py-2.5">
        <TabItem
          to={ROUTES.consumption.index}
          label={t('clients.pages.consumption')}
          icon="lightbulb"
          match={ROUTES.consumption.index}
        />
        <TabItem
          to={ROUTES.requests.index}
          label={t('clients.pages.requests')}
          icon="document"
          match={ROUTES.requests.index}
        />
        {company.isPremium && (
        <TabItem
          to={ROUTES.breakdowns.index}
          label={t('clients.pages.breakdowns')}
          icon="bad"
        />
        )}
        <TabItem
          to={ROUTES.profile.index}
          label={t('clients.pages.profile')}
          icon="user"
          match={ROUTES.profile.index}
        />
      </div>
    </div>
  );
};

export default BottomMenu;
