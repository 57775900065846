import { FormikConfig, FormikValues, useFormik } from 'formik';
import { ChangeEvent, useEffect } from 'react';

const useCustomForm = <T extends FormikValues>(opts: FormikConfig<T>) => {
  const formik = useFormik<T>(opts);

  useEffect(() => {
    formik.validateForm();
  }, []);

  const onBlurDocument = (name: string): (() => void) => () => {
    formik.setFieldTouched(`${name}.url`, true);
  };

  const handleCheckChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { checked, name } = e.target;
    formik.setFieldValue(name, checked);
  };

  return {
    ...formik,
    onBlurDocument,
    handleCheckChange,
  };
};

export default useCustomForm;
