import { FunctionComponent } from 'react';
import { classNames } from 'libs';

import { LabelProps } from '../types';

const LABEL = 'truncate';

const Label: FunctionComponent<LabelProps> = (
  {
    value,
    htmlFor,
    className = '',
    required = false,
  }: LabelProps,
) => (
  <label
    className={classNames(LABEL, className)}
    htmlFor={htmlFor}
    title={value}
  >
    {value}
    {required && <sup>*</sup>}
  </label>
);

export default Label;
