import { Container } from 'components/containers';
import { FunctionComponent } from 'react';
import { useClientState } from 'clients-app/store';
import { Outlet } from 'react-router-dom';

import { Navigation } from './header';
import Footer from './footer';

const Access: FunctionComponent = () => {
  const company = useClientState((state) => state.company.item);

  return (
    <>
      <Navigation />
      <main className="flex flex-col flex-1 pt-14 xl:pt-16">
        <div className="flex flex-col justify-center flex-1 xl:flex-row">
          <div className="flex flex-col items-end flex-1">
            <Container className="flex-1 mt-7 mb-14 xl:mt-14 lg:mb-36">
              <Outlet />
            </Container>
            <Footer type="form" />
          </div>
          <div
            className="flex-1 hidden bg-center bg-cover bg-primary xl:block"
            style={{ backgroundImage: `url(${company.document('login')})` }}
          />
        </div>
      </main>
    </>
  );
};

export default Access;
