import { Thunk, thunk } from 'easy-peasy';
import { API, handleResponse } from 'libs';
import { ChangeStateParams } from 'models';

import managers from '../../managers';

interface RequestPreproposalModel {
  validate: Thunk<RequestPreproposalModel, ChangeStateParams>;
  desestimate: Thunk<RequestPreproposalModel, ChangeStateParams>;
  upstream: Thunk<RequestPreproposalModel, ChangeStateParams>;
  propose: Thunk<RequestPreproposalModel, ChangeStateParams>;
  require: Thunk<RequestPreproposalModel, ChangeStateParams>;
  tramitate: Thunk<RequestPreproposalModel, ChangeStateParams>;
  deny: Thunk<RequestPreproposalModel, ChangeStateParams>;
}

const requestPreproposalModel: RequestPreproposalModel = {
  validate: thunk(async (_actions, payload, { getStoreActions }) => {
    const url = `/requests/${payload.requestID}/pre-proposal/${payload.id}/validate`;
    const response = await API.post(managers)(url, payload.params);
    // @ts-ignore
    const { initCurrent: init } = getStoreActions().requests;
    return handleResponse(response, init);
  }),

  desestimate: thunk(async (_actions, payload, { getStoreActions }) => {
    const url = `/requests/${payload.requestID}/pre-proposal/${payload.id}/desestimate`;
    const response = await API.post(managers)(url, payload.params);
    // @ts-ignore
    const { initCurrent: init } = getStoreActions().requests;
    return handleResponse(response, init);
  }),

  upstream: thunk(async (_actions, payload, { getStoreActions }) => {
    const url = `/requests/${payload.requestID}/pre-proposal/${payload.id}/upstream`;
    const response = await API.post(managers)(url, payload.params);
    // @ts-ignore
    const { initCurrent: init } = getStoreActions().requests;
    return handleResponse(response, init);
  }),

  propose: thunk(async (_actions, payload, { getStoreActions }) => {
    const url = `/requests/${payload.requestID}/pre-proposal/${payload.id}/propose`;
    const response = await API.post(managers)(url, payload.params);
    // @ts-ignore
    const { initCurrent: init } = getStoreActions().requests;
    return handleResponse(response, init);
  }),

  require: thunk(async (_actions, payload, { getStoreActions }) => {
    const url = `/requests/${payload.requestID}/pre-proposal/${payload.id}/require`;
    const response = await API.post(managers)(url, payload.params);
    // @ts-ignore
    const { initCurrent: init } = getStoreActions().requests;
    return handleResponse(response, init);
  }),

  tramitate: thunk(async (_actions, payload, { getStoreActions }) => {
    const url = `/requests/${payload.requestID}/pre-proposal/${payload.id}/tramitate`;
    const response = await API.post(managers)(url, payload.params);
    // @ts-ignore
    const { initCurrent: init } = getStoreActions().requests;
    return handleResponse(response, init);
  }),

  deny: thunk(async (_actions, payload, { getStoreActions }) => {
    const url = `/requests/${payload.requestID}/pre-proposal/${payload.id}/deny`;
    const response = await API.post(managers)(url, payload.params);
    // @ts-ignore
    const { initCurrent: init } = getStoreActions().requests;
    return handleResponse(response, init);
  }),
};

export type { RequestPreproposalModel };

export default requestPreproposalModel;
