import { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { useIsMatch } from 'hooks';

interface Props {
  to: string;
  label: string;
}

const DesktopNavItem: FunctionComponent<Props> = ({ to, label }: Props) => {
  const isMatch = useIsMatch(to);
  const LINK = classnames('text-l-base text-content-negative relative group ml-10');
  const LINE = classnames('h-[3px] w-0 bg-header-negative transition-all duration-200 group-hover:w-8 absolute top-7 left-0', {
    '!w-8': isMatch,
  });

  return (
    <Link to={to} className={LINK}>
      <span>{label}</span>
      <span className={LINE} />
    </Link>
  );
};

export default DesktopNavItem;
