import { ChangeEvent, useState } from 'react';
import _ from 'lodash/fp';

const useSearch = (): [
  string, ((e: ChangeEvent<HTMLInputElement>) => void), ((value: string) => boolean),
] => {
  const [search, setSearch] = useState('');

  const isIncluded = (value: string): boolean => (
    _.isEmpty(search) ? true : value.toLowerCase().includes(search.toLowerCase())
  );

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setSearch(e.target.value);
  };

  return [search, handleOnChange, isIncluded];
};

export default useSearch;
