import { useEffect, useState } from 'react';

import { Params, StoreRequest } from './types';
import useHandleResponse from './useHandleResponse';

const useLoadingRequest = (request: StoreRequest, params: Params): [boolean, string] => {
  const [message, handleResponse] = useHandleResponse();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    request(params)
      .then((response: string | boolean) => handleResponse(response))
      .finally(() => setIsLoading(false));

    return () => setIsLoading(true);
  }, []);

  return [isLoading, message];
};

export default useLoadingRequest;
