import { FunctionComponent } from 'react';
import { Container } from 'components/containers';

import BaseFooter from './BaseFooter';
import GDPR from './GDPR';
import SocialMedia from './SocialMedia';

const FooterPrivate: FunctionComponent = () => (
  <BaseFooter className="relative py-5 shadow-top bg-footer-negative mb-14 lg:mb-0">
    <div className="hidden full:block box-border absolute top-0 h-full left-full w-[calc((100vw-1440px)/2)] bg-footer-negative" />
    <Container size="small">
      <div className="items-center justify-between md:flex">
        <GDPR />
        <SocialMedia className="mt-8 md:mt-0" />
      </div>
    </Container>
  </BaseFooter>
);

export default FooterPrivate;
