import { useState } from 'react';

const useHistory = <T, >(initial: T[] = []) => {
  const [history, setHistory] = useState(initial);

  const push = (item: T) => {
    setHistory((old) => [...old, item]);
  };

  const back = () => {
    setHistory((old) => old.slice(0, -1));
  };

  return {
    push,
    back,
    current: history[history.length - 1],
  };
};

export default useHistory;
