import { Bodycopy } from 'components/typographies';
import { classNames } from 'libs';
import { FunctionComponent, ReactNode } from 'react';

interface Props {
  empty: boolean;
  message: string;
  children: ReactNode;
  className?: string;
}

// @ts-ignore
const EmptyRow: FunctionComponent<Props> = (
  {
    empty,
    message,
    children,
    className = '',
  }: Props,
) => (
  empty ? (
    <div className={classNames('w-full flex justify-center items-center h-[148px] border-y border-divider-200', className)}>
      <Bodycopy className="text-content-disabled">{message}</Bodycopy>
    </div>
  ) : children
);

export default EmptyRow;
