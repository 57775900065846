import { FunctionComponent, ReactNode } from 'react';
import { classNames } from 'libs';

interface Props {
  children: ReactNode;
  className?: string;
}

const H2: FunctionComponent<Props> = ({ children, className = '' }: Props) => (
  <h2 className={classNames('text-s-lg xl:text-l-lg', className)}>
    {children}
  </h2>
);

export default H2;
