import aliasModel, { AliasModel } from './aliases';
import appModel, { AppModel } from './app';
import clientModel, { ClientModel } from './client';
import companyModel, { CompanyModel } from './company';
import contactModel, { ContactModel } from './contact';
import contractModel, { ContractModel } from './contracts';
import distributorModel, { DistributorModel } from './distributor';
import faqModel, { FAQModel } from './faq';
import locationsModel, { LocationModel } from './locations';
import passwordModel, { PasswordModel } from './password';
import sessionModel, { SessionModel } from './session';
import verificationModel, { VerificationModel } from './verification';
import consumptionModel, { ConsumptionModel } from './consumption';
import requestModel, { RequestModel } from './requests';
import requestDocumentModel, { RequestDocumentModel } from './request-document';
import requestPreproposalModel, { RequestPreproposalModel } from './request-preproposal';
import requestProposalModel, { RequestProposalModel } from './request-proposal';
import requestLocationModel, { RequestLocationModel } from './request-location';
import requestWorksModel, { RequestWorksModel } from './request-works';

interface ClientStore {
  alias: AliasModel,
  app: AppModel
  client: ClientModel;
  company: CompanyModel;
  consumptions: ConsumptionModel;
  contact: ContactModel;
  contracts: ContractModel;
  distributor: DistributorModel;
  documentation: RequestDocumentModel;
  faq: FAQModel;
  location: RequestLocationModel;
  locations: LocationModel;
  password: PasswordModel;
  preproposal: RequestPreproposalModel;
  proposal: RequestProposalModel;
  session: SessionModel;
  requests: RequestModel;
  verification: VerificationModel;
  works: RequestWorksModel;
}

const clientStore: ClientStore = {
  alias: aliasModel,
  app: appModel,
  client: clientModel,
  company: companyModel,
  consumptions: consumptionModel,
  contact: contactModel,
  contracts: contractModel,
  distributor: distributorModel,
  documentation: requestDocumentModel,
  faq: faqModel,
  location: requestLocationModel,
  locations: locationsModel,
  password: passwordModel,
  preproposal: requestPreproposalModel,
  proposal: requestProposalModel,
  session: sessionModel,
  requests: requestModel,
  verification: verificationModel,
  works: requestWorksModel,
};

export type { ClientStore };

export default clientStore;
