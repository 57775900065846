import { useConditionalRedirect } from 'hooks';
import {
  indexed,
  layout,
  page,
  route,
} from 'libs';
import { Dashboard } from 'managers-app/layouts';
import { PATHS, ROUTES } from 'managers-app/libs';
import { useManagerState } from 'managers-app/store';
import { FunctionComponent, lazy } from 'react';
import { Outlet, RouteObject } from 'react-router-dom';

const Settings = lazy(() => import('./Settings'));
const Texts = lazy(() => import('./Texts'));
const Links = lazy(() => import('./Links'));
const Nodals = lazy(() => import('./Nodals'));
const Documents = lazy(() => import('./Documents'));

const SettingsPermissions: FunctionComponent = () => {
  const user = useManagerState((state) => state.session.user);
  useConditionalRedirect(!user.section('managers'), ROUTES.profile);

  return <Outlet />;
};

const SettingsRoutes: RouteObject[] = [
  layout(
    <Dashboard />,
    route(
      PATHS.settings.index,
      layout(
        <SettingsPermissions />,
        indexed(<Settings />),
        layout(
          <Settings />,
          page(PATHS.settings.texts, <Texts />),
          page(PATHS.settings.links, <Links />),
          page(PATHS.settings.nodal, <Nodals />),
          page(PATHS.settings.legal, <Documents />),
        ),
      ),
    ),
  ),
];

export default SettingsRoutes;
