import { classNames, t } from 'libs';
import { FunctionComponent, ReactNode } from 'react';
import { ButtonText } from 'components/typographies';

interface Props {
  loading: boolean;
  children: ReactNode;
  message?: string;
  className?: string;
}

// TODO: include a picture or something
// @ts-ignore
const SectionLoader: FunctionComponent<Props> = (
  {
    loading,
    children,
    message = t('loading'),
    className = 'h-[148px]',
  }: Props,
) => (
  loading
    ? (
      <div className={classNames('flex flex-col items-center justify-center', className)}>
        <div className="flex items-center justify-center flex-1 w-full bg-divider-200 animate-pulse">
          <ButtonText tag="h4" className="text-center text-notice-400">{message}</ButtonText>
        </div>
      </div>
    ) : children
);

export default SectionLoader;
