import _ from 'lodash/fp';
import { AppDocument } from 'models';
import {
  Action, Thunk, action, thunk,
} from 'easy-peasy';
import { API, handleResponse } from 'libs';

import managers from '../../managers';

import { IDocuments, LegalDocument, LEGAL_DOCUMENTS } from './types';

interface DocumentModel {
  documents: IDocuments;

  init: Action<DocumentModel, AppDocument[]>;

  index: Thunk<DocumentModel>;
  create: Thunk<DocumentModel, AppDocument>;
  update: Thunk<DocumentModel, AppDocument>;
}

const getDocument = (documents: AppDocument[]): ((docType: LegalDocument) => AppDocument) => (
  (docType: LegalDocument): AppDocument => _.flow(
    _.find(['docType', docType]),
  )(documents)
);

const createDocuments = (documents: AppDocument[] = []): IDocuments => ({
  items: documents,
  find: getDocument(documents),
});

const documentModel: DocumentModel = {
  documents: createDocuments(),

  init: action((state, payload) => {
    state.documents = createDocuments(payload);
  }),

  index: thunk(async (actions, _payload) => {
    const response = await API.get(managers)('/company-documents');
    return handleResponse(response, actions.init);
  }),

  create: thunk(async (actions, payload) => {
    const response = await API.post(managers)('/company-documents', payload);
    return handleResponse(response, actions.init);
  }),

  update: thunk(async (actions, payload) => {
    const response = await API.put(managers)(`/company-documents/${payload.id}`, payload);
    return handleResponse(response, actions.init);
  }),
};

export { LEGAL_DOCUMENTS };

export type { DocumentModel, LegalDocument };

export default documentModel;
