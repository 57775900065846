import { useClientState } from 'clients-app/store';
import { InternalLink } from 'components/buttons';
import { Container } from 'components/containers';
import { FunctionComponent } from 'react';

const Back: FunctionComponent = () => {
  const label = useClientState((state) => state.app.label);
  const back = useClientState((state) => state.app.back);

  if (!back || !label) return null;

  return (
    <Container size="small" className="pt-4 mb-4 lg:mb-8 xl:mb-10 lg:pt-8 xl:pt-12">
      <InternalLink
        icon="angle-left"
        to={back as string}
        label={label}
        styles="positive"
      />
    </Container>
  );
};

export default Back;
