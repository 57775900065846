import { Thunk, thunk } from 'easy-peasy';
import { API, handleResponse } from 'libs';
import { ChangeStateParams } from 'models';

import managers from '../../managers';

interface RequestWorksModel {
  startWorks: Thunk<RequestWorksModel, ChangeStateParams>;
  authorization: Thunk<RequestWorksModel, ChangeStateParams>;
  continue: Thunk<RequestWorksModel, ChangeStateParams>;
  service: Thunk<RequestWorksModel, ChangeStateParams>;
  require: Thunk<RequestWorksModel, ChangeStateParams>;
  validate: Thunk<RequestWorksModel, ChangeStateParams>;
  requireAssignment: Thunk<RequestWorksModel, ChangeStateParams>;
  assignment: Thunk<RequestWorksModel, ChangeStateParams>;
  requireAdministration: Thunk<RequestWorksModel, ChangeStateParams>;
  start: Thunk<RequestWorksModel, ChangeStateParams>;
  finish: Thunk<RequestWorksModel, ChangeStateParams>;
}

const requestWorksModel: RequestWorksModel = {
  startWorks: thunk(async (_actions, payload, { getStoreActions }) => {
    const url = `/requests/${payload.requestID}/works/${payload.id}/start-works`;
    const response = await API.post(managers)(url, payload.params);
    // @ts-ignore
    const { initCurrent: init } = getStoreActions().requests;
    return handleResponse(response, init);
  }),
  authorization: thunk(async (_actions, payload, { getStoreActions }) => {
    const url = `/requests/${payload.requestID}/works/${payload.id}/authorization`;
    const response = await API.post(managers)(url, payload.params);
    // @ts-ignore
    const { initCurrent: init } = getStoreActions().requests;
    return handleResponse(response, init);
  }),

  continue: thunk(async (_actions, payload, { getStoreActions }) => {
    const url = `/requests/${payload.requestID}/works/${payload.id}/continue`;
    const response = await API.post(managers)(url, payload.params);
    // @ts-ignore
    const { initCurrent: init } = getStoreActions().requests;
    return handleResponse(response, init);
  }),

  service: thunk(async (_actions, payload, { getStoreActions }) => {
    const url = `/requests/${payload.requestID}/works/${payload.id}/service`;
    const response = await API.post(managers)(url, payload.params);
    // @ts-ignore
    const { initCurrent: init } = getStoreActions().requests;
    return handleResponse(response, init);
  }),

  require: thunk(async (_actions, payload, { getStoreActions }) => {
    const url = `/requests/${payload.requestID}/works/${payload.id}/require`;
    const response = await API.post(managers)(url, payload.params);
    // @ts-ignore
    const { initCurrent: init } = getStoreActions().requests;
    return handleResponse(response, init);
  }),

  validate: thunk(async (_actions, payload, { getStoreActions }) => {
    const url = `/requests/${payload.requestID}/works/${payload.id}/validate`;
    const response = await API.post(managers)(url, payload.params);
    // @ts-ignore
    const { initCurrent: init } = getStoreActions().requests;
    return handleResponse(response, init);
  }),

  requireAssignment: thunk(async (_actions, payload, { getStoreActions }) => {
    const url = `/requests/${payload.requestID}/works/${payload.id}/require-assignment`;
    const response = await API.post(managers)(url, payload.params);
    // @ts-ignore
    const { initCurrent: init } = getStoreActions().requests;
    return handleResponse(response, init);
  }),

  assignment: thunk(async (_actions, payload, { getStoreActions }) => {
    const url = `/requests/${payload.requestID}/works/${payload.id}/assignment`;
    const response = await API.post(managers)(url, payload.params);
    // @ts-ignore
    const { initCurrent: init } = getStoreActions().requests;
    return handleResponse(response, init);
  }),

  requireAdministration: thunk(async (_actions, payload, { getStoreActions }) => {
    const url = `/requests/${payload.requestID}/works/${payload.id}/require-administration`;
    const response = await API.post(managers)(url, payload.params);
    // @ts-ignore
    const { initCurrent: init } = getStoreActions().requests;
    return handleResponse(response, init);
  }),

  finish: thunk(async (_actions, payload, { getStoreActions }) => {
    const url = `/requests/${payload.requestID}/works/${payload.id}/finish`;
    const response = await API.post(managers)(url, payload.params);
    // @ts-ignore
    const { initCurrent: init } = getStoreActions().requests;
    return handleResponse(response, init);
  }),

  start: thunk(async (_actions, payload, { getStoreActions }) => {
    const url = `/requests/${payload.requestID}/works/${payload.id}/start`;
    const response = await API.post(managers)(url, payload.params);
    // @ts-ignore
    const { initCurrent: init } = getStoreActions().requests;
    return handleResponse(response, init);
  }),
};

export type { RequestWorksModel };

export default requestWorksModel;
