import { FunctionComponent, ReactNode } from 'react';

interface Props {
  children?: ReactNode;
  className?: string;
}

const Sidebar: FunctionComponent<Props> = ({ children, className }: Props) => (
  <div className={className}>
    <div className="lg:sticky top-32">
      {children}
    </div>
  </div>
);

export default Sidebar;
