import { useManagerActions } from 'managers-app/store';
import { useEffect } from 'react';

const useAppClose = (route: string): void => {
  const setClose = useManagerActions((state) => state.app.setClose);

  useEffect(() => {
    setClose(route);
  }, [route]);
};

export default useAppClose;
