import { FunctionComponent } from 'react';
import { classNames } from 'libs';
import _ from 'lodash/fp';

import { BaseLinkProps } from './types';
import InnerContent from './components/InnerContent';
import BUTTON_CLASSES from './styles';
import LineDecorator from './components/LineDecorator';

interface Props extends BaseLinkProps {
  to: string;
  crop?: boolean;
}

const ExternalLink: FunctionComponent<Props> = (
  {
    to,
    className = '',
    label = '',
    icon,
    iconRight = false,
    small = false,
    size = 'regular',
    styles = 'regular',
    crop = false,
  }: Props,
) => (
  !_.isUndefined(to) ? (
    <a
      href={to}
      className={classNames(BUTTON_CLASSES.link(styles, iconRight), className)}
      target="_blank"
      rel="noreferrer"
    >
      <InnerContent
        className={BUTTON_CLASSES.icon(iconRight, label)}
        label={label}
        icon={icon}
        small={small}
        crop={crop}
      />
      <LineDecorator
        className={classNames(
          BUTTON_CLASSES.line(size, styles, false),
          BUTTON_CLASSES.position(small, iconRight, icon),
        )}
      />
    </a>
  ) : null
);

export default ExternalLink;
