import { FunctionComponent, Suspense } from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { Provider, ErrorBoundary } from '@rollbar/react';

import 'typeface-oxygen';

import ClientsRouter from './clients-app/ClientsApp';
import ManagersRouter from './managers-app/ManagersApp';

const rollbarConfig = {
  accessToken: '4076497e02dd48f08906a388642a2798',
  environment: process.env.NODE_ENV || 'development',
};

const Reloader: FunctionComponent = () => {
  window.location.reload();

  return null;
};

const router = createBrowserRouter([
  {
    errorElement: <Reloader />,
    children: [
      ...ClientsRouter,
      ...ManagersRouter,
    ],
  },
]);

const App: FunctionComponent = () => (
  <Provider config={rollbarConfig}>
    <ErrorBoundary>
      <Suspense>
        <RouterProvider router={router} />
      </Suspense>
    </ErrorBoundary>
  </Provider>
);

export default App;
