import { classNames } from 'libs';
import { FunctionComponent, ReactNode } from 'react';

interface Props {
  src: string;
  className?: string;
  children: ReactNode;
}

const BackgroundImage: FunctionComponent<Props> = ({ src, className = '', children }: Props) => (
  <div className={classNames('bg-cover bg-center bg-primary', className)} style={{ backgroundImage: `url('${src}')` }}>
    {children}
  </div>
);

export default BackgroundImage;
