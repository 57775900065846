import { Thunk, thunk } from 'easy-peasy';
import { API, handleResponse } from 'libs';
import { ChangeStateParams } from 'models';

import clients from '../../clients';

interface RequestDocumentModel {
  create: Thunk<RequestDocumentModel, ChangeStateParams>;
}

const requestDocumentModel: RequestDocumentModel = {
  create: thunk(async (_actions, payload, { getStoreActions }) => {
    const url = `/requests/${payload.requestID}/documentation/${payload.id}`;
    const response = await API.post(clients)(url, payload.params);
    // @ts-ignore
    const { initCurrent: init } = getStoreActions().requests;
    return handleResponse(response, init);
  }),
};

export type { RequestDocumentModel };

export default requestDocumentModel;
