import { FunctionComponent, useMemo } from 'react';
import _ from 'lodash/fp';
import classnames from 'classnames';
import { classNames } from 'libs';

import { FieldProps, TextareaProps } from './types';
import FormGroup from './components/FormGroup';
import Label from './components/Label';
import InputError from './components/InputError';
import { TYPOGRAPHY } from './styles';

interface Props extends TextareaProps, FieldProps {
  large?: boolean
}

const Textarea: FunctionComponent<Props> = (
  {
    id,
    name,
    readOnly = false,
    disabled = false,
    required = false,
    className = '',
    value,
    placeholder,
    onChange,
    onBlur,
    maxLength,
    touched = false,
    error = '',
    large = false,
  }: Props,
) => {
  const hasValue = !_.isEmpty(value);
  const hasError = touched && !_.isEmpty(error);
  const width = window.innerWidth;

  const FIELD = classnames('mb-8 border', {
    'border-content-disabled': disabled,
    'border-danger-400': hasError,
    'border-divider-200': !hasValue && !hasError,
    'border-content': hasValue && !hasError,
    '!max-w-none': large,
  });

  const LABEL = classnames('text-s-sm bg-backdrop-primary absolute px-1.5 -top-2 left-3 z-[5] max-w-[calc(100%-24px)]', {
    hidden: !hasValue,
    'text-content-disabled': disabled,
    'text-content': _.isEmpty(error),
    'text-danger-400': hasError && !disabled,
  });

  const INPUT = classnames(classNames('w-full block bg-transparent px-4 py-3 text-content placeholder:text-content-inactive placeholder:disabled:text-content-disabled disabled:text-content-disabled', className), {
    'cursor-not-allowed': disabled || readOnly,
  });

  const ERROR = classnames({
    hidden: !touched || disabled,
    block: hasError,
  });

  const HELPER = classnames(classNames(TYPOGRAPHY, 'pr-4 pt-3'), {
    'text-content': hasValue && !disabled,
    'text-content-inactive': !hasValue && !disabled,
    'text-content-disabled': disabled,
  });

  const measures = useMemo(() => ({
    maxLenghtSmall: large ? 130 : 37,
    maxLenghtLarge: large ? 130 : 47,
    rowsSmall: large ? 130 : 43,
    rowsLarge: large ? 130 : 53,
  }), []);

  const size = useMemo(() => _.size(value as string), [value]);
  const rows = useMemo(() => (
    !_.isUndefined(maxLength)
      ? Math.ceil(
        maxLength / (_.gt(width, 768) ? measures.maxLenghtLarge : measures.maxLenghtLarge),
      )
      : Math.ceil(size / (_.gt(width, 768) ? measures.rowsLarge : measures.rowsSmall))
  ), [maxLength, size]);

  return (
    <FormGroup className={FIELD}>
      <Label
        value={placeholder}
        htmlFor={id}
        className={LABEL}
        required={required}
      />
      <div className="flex items-start">
        <textarea
          id={id}
          name={name}
          placeholder={`${placeholder}${required ? '*' : ''}`}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          readOnly={readOnly}
          disabled={disabled}
          required={required}
          className={INPUT}
          style={{ resize: 'none' }}
          rows={rows}
          maxLength={maxLength}
          autoComplete="none"
        />
        {!_.isUndefined(maxLength) && <p className={HELPER}>{`${size}/${maxLength}`}</p>}
      </div>
      <InputError error={error} className={ERROR} />
    </FormGroup>
  );
};

export default Textarea;
