import { useForbidenRedirect } from 'hooks';
import { usePrivateAsyncRequest } from 'managers-app/hooks';
import { ROUTES } from 'managers-app/libs';
import { useManagerActions, useManagerState } from 'managers-app/store';
import { FunctionComponent } from 'react';
import { Outlet } from 'react-router-dom';

const Private: FunctionComponent = () => {
  const loggedIn = useManagerState((state) => state.session.loggedIn);
  const distributors = useManagerActions((actions) => actions.distributor.index);
  const clients = useManagerActions((actions) => actions.clients.index);
  const authorizations = useManagerActions((actions) => actions.authorizations.index);
  useForbidenRedirect(!loggedIn, ROUTES.session, true);
  usePrivateAsyncRequest(distributors);
  usePrivateAsyncRequest(clients);
  usePrivateAsyncRequest(authorizations);

  return <Outlet />;
};

export default Private;
