import { useConditionalRedirect } from 'hooks';
import {
  indexed, layout, page, route,
} from 'libs';
import { Dashboard } from 'managers-app/layouts';
import { PATHS, ROUTES } from 'managers-app/libs';
import { useManagerState } from 'managers-app/store';
import { FunctionComponent, lazy } from 'react';
import { Outlet, RouteObject } from 'react-router-dom';

const ScheduledCuts = lazy(() => import('./ScheduledCuts'));
const ScheduledCutNew = lazy(() => import('./ScheduledCutNew'));
const ScheduledCutEdit = lazy(() => import('./ScheduledCutEdit'));

const ScheduledCutsPermissions: FunctionComponent = () => {
  const user = useManagerState((state) => state.session.user);
  const company = useManagerState((state) => state.company.item);
  const canScheduledCuts = user.section('scheduled-cuts') && company.isPremium;
  useConditionalRedirect(!canScheduledCuts, ROUTES.profile);

  return <Outlet />;
};

const ScheduledCutsRoutes: RouteObject[] = [
  layout(
    <Dashboard />,
    route(
      PATHS.scheduled.index,
      layout(
        <ScheduledCutsPermissions />,
        indexed(<ScheduledCuts />),
        page(PATHS.scheduled.new, <ScheduledCutNew />),
        page(PATHS.scheduled.edit, <ScheduledCutEdit />),
      ),
    ),
  ),
];

export default ScheduledCutsRoutes;
