import { Icon, IconName } from 'components/icons';
import { useIsMatch } from 'hooks';
import { FunctionComponent } from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { Bodycopy, ButtonText } from 'components/typographies';

interface Props {
  label: string;
  to: string;
  icon: IconName;
  match?: string;
}

const MenuItem: FunctionComponent<Props> = (
  {
    icon,
    label,
    to,
    match,
  }: Props,
) => {
  const isMatch = useIsMatch(match ?? to, false);
  const LINE = classnames('absolute top-full transition-all duration-300 ease-in-out bg-primary h-[3px] group-hover:w-6', {
    'w-6': isMatch,
    'w-0': !isMatch,
  });

  return (
    <Link to={to} className="relative flex items-center pb-2 mb-8 group">
      <Icon name={icon} className="mr-4" />
      <div className={LINE} />
      {isMatch ? <ButtonText>{label}</ButtonText> : <Bodycopy>{label}</Bodycopy>}
    </Link>
  );
};

export default MenuItem;
