import { FunctionComponent, ReactNode } from 'react';
import _ from 'lodash/fp';
import { classNames } from 'libs';
import { Icon } from 'components/icons';
import { Bodycopy } from 'components/typographies';
import { Squared } from 'components/decorators';
import classnames from 'classnames';

import {
  CONTAINER,
  NOTIFICATION_STYLES,
  NotificationStyle,
} from './styles';

interface Props {
  content: string | ReactNode;
  children?: ReactNode;
  styles?: NotificationStyle;
  className?: string;
}

const Notification: FunctionComponent<Props> = (
  {
    content,
    children,
    styles = 'regular',
    className = '',
  }: Props,
) => (
  <div className={classNames(CONTAINER, NOTIFICATION_STYLES[styles], className)}>
    <Squared
      size="medium"
      styles={_.isEqual(styles, 'regular') ? 'info' : 'warning'}
      className="p-2 absolute -top-[19px] left-[calc(50%-19px)] md:static md:mr-6"
    >
      <Icon name={_.isEqual(styles, 'regular') ? 'info' : 'warning'} />
    </Squared>
    {typeof content !== 'string' ? content : (
      <Bodycopy tag="div" className={classnames('mb-2 text-center md:mb-0 md:text-left', { 'max-w-2xl': children })}>
        {content}
      </Bodycopy>
    )}
    <div className="md:ml-auto">
      {children}
    </div>
  </div>
);

export default Notification;
