import { FunctionComponent } from 'react';
import classnames from 'classnames';
import { NavLink } from 'react-router-dom';
import { useIsMatch } from 'hooks';
import { Icon } from 'components/icons';

interface Props {
  to: string;
  label: string;
}

const MobileNavItem: FunctionComponent<Props> = ({ to, label }: Props) => {
  const isMatch = useIsMatch(to);
  const NAV_ITEM_LABEL = classnames('text-s-lg', {
    'font-bold': isMatch,
  });

  return (
    <NavLink to={to} className="flex items-center justify-between py-3 border-b border-divider-200 text-content">
      <span className={NAV_ITEM_LABEL}>{label}</span>
      <Icon name="circle-angle-right" className="text-content-interaction" />
    </NavLink>
  );
};

export default MobileNavItem;
