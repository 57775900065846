import { PageError } from 'components/errors';
import { PageLoader } from 'components/loaders';
import { useLoadingRequest, usePathParams } from 'hooks';
import { useManagerActions } from 'managers-app/store';
import { FunctionComponent } from 'react';
import { Outlet } from 'react-router-dom';

const ClientLoader: FunctionComponent = () => {
  const { id } = usePathParams();
  const show = useManagerActions((actions) => actions.clients.show);
  const [loading, message] = useLoadingRequest(show, id);

  return (
    <PageLoader loading={loading}>
      <PageError message={message}>
        <Outlet />
      </PageError>
    </PageLoader>
  );
};

export default ClientLoader;
