import { useForbidenRedirect } from 'hooks';
import { ROUTES } from 'managers-app/libs';
import { useManagerState } from 'managers-app/store';
import { FunctionComponent } from 'react';
import { Outlet } from 'react-router-dom';
import { Container } from 'components/containers';

import Navigation from './header';

const Public: FunctionComponent = () => {
  const loggedIn = useManagerState((state) => state.session.loggedIn);
  useForbidenRedirect(loggedIn, ROUTES.index);

  return (
    <>
      <Navigation />
      <main className="flex flex-col items-center justify-center flex-1 pt-14 xl:pt-16">
        <Container size="x-small">
          <Outlet />
        </Container>
      </main>
    </>
  );
};

export default Public;
