import { IRequest } from 'models';
import { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import { ROUTES } from 'clients-app/libs';
import { Caption, H3 } from 'components/typographies';
import { classNames, momentum, t } from 'libs';
import { Tag } from 'components/tags';
import { Icon } from 'components/icons';
import { Squared } from 'components/decorators';
import { useClientState } from 'clients-app/store';

interface Props {
  request: IRequest;
  className?: string;
}

const RequestCard: FunctionComponent<Props> = ({ request, className }: Props) => {
  const company = useClientState((state) => state.company.item);
  const isCanceled = (
    request.state.name === 'Cancelada'
    || request.state.clientDescription === 'Denegado por capacidad y/o viabilidad'
    || request.state.clientDescription === 'Denegada'
  );

  return (
    <Link to={ROUTES.requests.show.index(request.code)} className={classNames('relative flex flex-col w-full max-w-[336px] min-w-[336px] px-4 py-6 border-r border-divider-200 group', className)}>
      <div className="flex flex-1 pb-6 mb-6 border-b border-divider-200">
        <div className="flex flex-col flex-1 max-w-[278px]">
          <Tag
            // eslint-disable-next-line no-nested-ternary
            label={request.state.clientDescription === 'Esperando autorización'
              ? t(`requests.steps.${request.type}.client.${request.state.clientDescription}`, { responsable: request.state.responsable })
              : t(`requests.steps.${request.type}.client.${request.state.clientDescription}`)}
            styles={request.finished ? 'success' : 'warning'}
            className="absolute top-0 left-4"
          />
          <H3 className="mb-3.5">
            {company.isPremium && request.authomatism
              ? t('requests.SAyC')
              : t(`requests.${request.type}`)}
          </H3>
          <div className="mt-auto">
            <Caption className="mb-1 truncate text-content-disabled">
              {t('requests.request_code', { code: request.code })}
            </Caption>
            <Caption className="truncate text-content-disabled">
              {request.address}
            </Caption>
          </div>
        </div>
        <div>
          <Icon name="circle-angle-right" animate className="text-content-interaction" />
        </div>
      </div>
      {momentum(request.state.clientLimit).year() < 1
        ? (
          <div className="flex items-center">
            <Squared size="small" className="flex items-center justify-center mr-4">
              <Icon name="calendar" />
            </Squared>
            <div className="">
              <Caption className="text-content-disabled">
                {t('requests.remaining_days', { days: '-' })}
              </Caption>
              <Caption className="text-content-disabled">-</Caption>
            </div>
          </div>
        ) : (
          <div className="flex items-center">
            <Squared size="small" className="flex items-center justify-center mr-4">
              <Icon name="calendar" />
            </Squared>
            <div className="">
              {(isCanceled || request.finished)
                ? <Caption className="text-content-disabled">{t('requests.finished')}</Caption>
                : (
                  <>
                    {request.state.clientDescription === 'Obras finalizadas' ? (
                      <Caption className="text-content-disabled">{t('requests.waiting')}</Caption>
                    ) : (
                      <>
                        {request.clientToLimit <= 0 ? (
                          <Caption className="text-content-disabled">{t('requests.deadline_expired')}</Caption>
                        ) : (
                          <Caption className="text-content-disabled">
                            {t('requests.remaining_days', { days: request.clientToLimit })}
                          </Caption>
                        )}
                      </>
                    )}
                  </>
                )}
              <Caption className="text-content-disabled">
                {momentum(request.state.clientLimit).format('DD MMMM')}
              </Caption>
            </div>
          </div>
        )}
    </Link>
  );
};

export default RequestCard;
