import { FunctionComponent } from 'react';
import { Container } from 'components/containers';

import BaseFooter from './BaseFooter';
import GDPR from './GDPR';

const FooterForm: FunctionComponent = () => (
  <BaseFooter className="py-5 shadow-top bg-footer-negative mb-14 lg:mb-0">
    <Container>
      <GDPR />
    </Container>
  </BaseFooter>
);

export default FooterForm;
