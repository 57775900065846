import { Thunk, thunk } from 'easy-peasy';
import { ChangeStateParams } from 'models';
import { API, handleResponse } from 'libs';

import managers from '../../managers';

interface RequestCounterModel {
  update: Thunk<RequestCounterModel, ChangeStateParams>;
  finish: Thunk<RequestCounterModel, ChangeStateParams>;
}

const requestCounterModel: RequestCounterModel = {
  update: thunk(async (_actions, payload, { getStoreActions }) => {
    const url = `/requests/${payload.requestID}/counter/${payload.id}/update`;
    const response = await API.post(managers)(url, payload.params);
    // @ts-ignore
    const { initCurrent: init } = getStoreActions().requests;
    return handleResponse(response, init);
  }),

  finish: thunk(async (_actions, payload, { getStoreActions }) => {
    const url = `/requests/${payload.requestID}/counter/${payload.id}/finish`;
    const response = await API.post(managers)(url, payload.params);
    // @ts-ignore
    const { initCurrent: init } = getStoreActions().requests;
    return handleResponse(response, init);
  }),
};

export type { RequestCounterModel };

export default requestCounterModel;
