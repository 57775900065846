import { FormikConfig, FormikValues, useFormik } from 'formik';
import {
  CLIENT_DATA,
  IClient,
  Municipality,
  Province,
  createClient,
} from 'models';
import { ChangeEvent, useEffect, useMemo } from 'react';
import _ from 'lodash/fp';

const useRequestsForm = <T extends FormikValues>(
  opts: FormikConfig<T>,
  locations: Province[],
  client: IClient = createClient(),
) => {
  const formik = useFormik<T>(opts);

  const provinces = useMemo(() => _.map('name')(locations), [locations]);

  const municipalitiesList: Municipality[] = useMemo(() => (
    _.flatMap('municipalities')(locations)
  ), [locations]);

  const municipalities = useMemo(() => _.flow(
    _.filter((location: Province) => location.name === formik.values.province),
    _.flatMap('municipalities'),
    _.orderBy(['name'], ['asc']),
    _.map('name'),
  )(locations), [formik.values.province, locations]);

  const postalcodes = useMemo(() => _.flow(
    _.filter((location: Province) => location.name === formik.values.province),
    _.flatMap('municipalities'),
    _.filter((municipality) => municipality.name === formik.values.municipality),
    _.flatMap('postalCodes'),
    _.map('code'),
  )(locations), [formik.values.municipality, locations]);

  const isHolder = _.isEqual(formik.values.clientType, 'Titular');
  const isOther = _.isEqual('Otros');

  const handleDistributorChange = (): void => {
    if (formik.values.municipality === '') return;
    // @ts-ignore
    const municipality: Municipality | undefined = _.find(
      ['name', formik.values.municipality],
    )(municipalitiesList);
    formik.setFieldValue('distributorID', municipality?.distributorID);
  };

  useEffect(() => {
    formik.validateForm();
  }, []);

  useEffect(() => {
    // @ts-ignore
    formik.setValues({
      clientType: formik.values.clientType,
      clientName: isHolder ? client.name : '',
      clientIdentityNumber: isHolder ? client.identityNumber : '',
      clientEmail: isHolder ? client.email : '',
      clientPhoneNumber: isHolder ? client.phoneNumber : '',
      clientAddress: isHolder ? client.address : '',
      clientPostalCode: isHolder ? client.postalCode : '',
      clientMunicipality: isHolder ? client.municipality : '',
      clientProvince: isHolder ? client.province : '',
      ..._.omit(CLIENT_DATA)(formik.values),
    });
  }, [formik.values.clientType]);

  useEffect(() => {
    formik.setFieldValue('municipality', '');
    formik.setFieldValue('postalCode', '');
  }, [formik.values.province]);

  useEffect(() => {
    formik.setFieldValue('postalCode', '');
    handleDistributorChange();
  }, [formik.values.municipality]);

  useEffect(() => {
    if (isOther(formik.values.use)) return;
    formik.setFieldValue('useDescription', '');
  }, [formik.values.use]);

  useEffect(() => {
    if (isOther(formik.values.primaryPowerSource)) return;
    formik.setFieldValue('primaryPowerSourceDescription', '');
  }, [formik.values.primaryPowerSource]);

  const onBlurDocument = (name: string): (() => void) => () => {
    formik.setFieldTouched(`${name}.url`, true);
  };

  const handleCheckChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { checked, name } = e.target;
    formik.setFieldValue(name, checked);
  };

  return {
    ...formik,
    onBlurDocument,
    handleCheckChange,
    provinces,
    municipalities,
    postalcodes,
    isHolder,
    isOther,
  };
};

export default useRequestsForm;
