import { FunctionComponent } from 'react';
import { Outlet } from 'react-router-dom';

import { Header } from './header';
import Aside from './aside';
import Footer from './footer';
import Back from './components/Back';
import BottomMenu from './bottom-menu';

const Dashboard: FunctionComponent = () => (
  <>
    <Header />
    <main className="flex flex-col flex-1 w-full mx-auto pt-14 xl:pt-16 max-w-screen-full">
      <div className="flex flex-1 max-w-full">
        <Aside />
        <div className="flex flex-col flex-1 max-w-full lg:max-w-[calc(100%-230px)] xl:max-w-[calc(100%-292px)]">
          <div className="flex flex-col flex-1">
            <Back />
            <Outlet />
          </div>
          <Footer type="private" />
          <BottomMenu />
        </div>
      </div>
    </main>
  </>
);

export default Dashboard;
