import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const useToggle = (initialState: boolean): [boolean, () => void] => {
  const [active, setActive] = useState(initialState);
  const location = useLocation();

  const onToggle = () => setActive(!active);

  useEffect(() => {
    setActive(false);

    return () => {
      setActive(false);
    };
  }, [location]);

  return [active, onToggle];
};

export default useToggle;
