import { FunctionComponent } from 'react';
import classnames from 'classnames';
import { Bodycopy } from 'components/typographies';

import { RadioProps } from '../types';

import Input from './Input';
import Label from './Label';

const RadioButton: FunctionComponent<RadioProps> = (
  {
    id,
    name,
    readOnly = false,
    disabled = false,
    className = '',
    value,
    placeholder,
    checked,
    onChange,
    onBlur,
    description,
    bold,
  }: RadioProps,
) => {
  const RADIO = classnames('min-w-[20px] w-5 h-5 block border flex items-center rounded-full mr-2', {
    'border-content cursor-pointer': !disabled,
    'border-content-disabled cursor-not-allowed': disabled,
    'bg-content ring-[2px] ring-inset ring-backdrop-primary': checked,
    'bg-backdrop-primary': !checked,
    'bg-content-disabled': disabled && checked,
  });

  const LABEL = classnames({
    'text-l-base': !bold,
    'text-l-base-b': bold,
    'text-content cursor-pointer': !disabled,
    'text-content-disabled cursor-not-allowed': disabled,
  });

  const INPUT = classnames('flex items-center', {
    'mb-0.5': description,
  });

  const DESCRIPTION = classnames('ml-7', { 'text-content-disabled': disabled });

  return (
    <div className={className}>
      <div className={INPUT}>
        <Input
          type="radio"
          name={name}
          id={id}
          value={value}
          checked={checked}
          onChange={onChange}
          onBlur={onBlur}
          readOnly={readOnly}
          disabled={disabled}
          className="hidden"
        />
        <Label
          value=""
          htmlFor={id}
          className={RADIO}
        />
        <Label
          value={placeholder}
          htmlFor={id}
          className={LABEL}
        />
      </div>
      {description && (
      <Bodycopy className={DESCRIPTION}>{description}</Bodycopy>
      )}
    </div>
  );
};

export default RadioButton;
