import { ID } from 'models';

const PATHS = {
  index: 'gestor/',
  profile: 'perfil',
  session: 'acceder',
  password: {
    reset: 'recuperar-contrasena',
    restore: 'restaurar-contrasena',
  },
  requests: {
    index: 'solicitudes/',
    show: ':id',
  },
  scheduled: {
    index: 'cortes-programados/',
    edit: ':id',
    new: 'nuevo-corte-programado',
  },
  unpayment: 'cortes-por-impago',
  authorizations: {
    index: 'autorizaciones/',
    pending: 'pendientes',
    show: ':id',
  },
  clients: {
    index: 'clientes/',
    pending: 'pendientes',
    show: {
      index: ':id/',
      verification: 'verificacion',
      requests: {
        index: 'solicitudes/',
        cups: 'solicitud-cups',
        supply: 'solicitud-nuevo-punto-de-suministro',
        supplycups: 'solicitud-suministro-electrico',
        cau: 'solicitud-cau',
        selfconsumption: 'solicitud-autoconsumo',
        generation: 'solicitud-generacion',
      },
    },
  },
  managers: {
    index: 'gestores/',
    new: 'nuevo-gestor',
    edit: ':id',
  },
  settings: {
    index: 'editor/',
    texts: 'texts',
    links: 'enlaces',
    nodal: 'capacidades-nodales',
    legal: 'documentos-legales',
  },
};

const ROUTES = {
  index: '/gestor/',
  profile: '/gestor/perfil',
  session: '/gestor/acceder',
  password: {
    reset: '/gestor/recuperar-contrasena',
    restore: '/gestor/restaurar-contrasena',
  },
  requests: {
    index: '/gestor/solicitudes/',
    show: (id: ID): string => `/gestor/solicitudes/${id}`,
  },
  scheduled: {
    index: '/gestor/cortes-programados/',
    edit: (id: ID): string => `/gestor/cortes-programados/${id}`,
    new: '/gestor/cortes-programados/nuevo-corte-programado',
  },
  unpayment: '/gestor/cortes-por-impago',
  authorizations: {
    index: '/gestor/autorizaciones',
    pending: '/gestor/autorizaciones/pendientes',
    show: (id: ID): string => `/gestor/autorizaciones/${id}`,
  },
  clients: {
    index: '/gestor/clientes/',
    pending: '/gestor/clientes/pendientes',
    show: {
      index: (id: ID): string => `/gestor/clientes/${id}`,
      verification: (id: ID): string => `/gestor/clientes/${id}/verificacion`,
      requests: {
        index: (id: ID): string => `/gestor/clientes/${id}/solicitudes`,
        cups: (id: ID): string => `/gestor/clientes/${id}/solicitudes/solicitud-cups`,
        supply: (id: ID): string => `/gestor/clientes/${id}/solicitudes/solicitud-nuevo-punto-de-suministro`,
        supplycups: (id: ID): string => `/gestor/clientes/${id}/solicitudes/solicitud-suministro-electrico`,
        cau: (id: ID): string => `/gestor/clientes/${id}/solicitudes/solicitud-cau`,
        selfconsumption: (id: ID): string => `/gestor/clientes/${id}/solicitudes/solicitud-autoconsumo`,
        generation: (id: ID): string => `/gestor/clientes/${id}/solicitudes/solicitud-generacion`,
      },
    },
  },
  managers: {
    index: '/gestor/gestores/',
    new: '/gestor/gestores/nuevo-gestor',
    edit: (id: ID): string => `/gestor/gestores/${id}`,
  },
  settings: {
    index: '/gestor/editor/',
    texts: '/gestor/editor/texts',
    links: '/gestor/editor/enlaces',
    nodal: '/gestor/editor/capacidades-nodales',
    legal: '/gestor/editor/documentos-legales',
  },
};

export { PATHS, ROUTES };
