import { useConditionalRedirect } from 'hooks';
import {
  indexed,
  layout,
  page,
  route,
} from 'libs';
import { Dashboard } from 'managers-app/layouts';
import { PATHS, ROUTES } from 'managers-app/libs';
import { useManagerState } from 'managers-app/store';
import { FunctionComponent, lazy } from 'react';
import { Outlet, RouteObject } from 'react-router-dom';

const Managers = lazy(() => import('./Managers'));
const ManagerNew = lazy(() => import('./ManagerNew'));
const ManagerEdit = lazy(() => import('./ManagerEdit'));

const ManagersPermissions: FunctionComponent = () => {
  const user = useManagerState((state) => state.session.user);
  useConditionalRedirect(!user.section('managers'), ROUTES.profile);

  return <Outlet />;
};

const ManagersRoutes: RouteObject[] = [
  layout(
    <Dashboard />,
    route(
      PATHS.managers.index,
      layout(
        <ManagersPermissions />,
        indexed(<Managers />),
        page(PATHS.managers.new, <ManagerNew />),
        page(PATHS.managers.edit, <ManagerEdit />),
      ),
    ),
  ),
];

export default ManagersRoutes;
