import annotationModel, { AnnotationModel } from './annotations';
import appModel, { AppModel } from './app';
import authorizationsModel, { AuthorizationsModel } from './authorizations';
import clientModel, { ClientModel } from './clients';
import companyModel, { CompanyModel } from './company';
import distributorModel, { DistributorModel } from './distributors';
import documentModel, { DocumentModel } from './document';
import linkModel, { LinkModel } from './link';
import locationModel, { LocationModel } from './locations';
import managerModel, { ManagerModel } from './managers';
import nodalModel, { NodalModel } from './nodal';
import nonPaymentCutModel, { NonPaymentCutModel } from './non-payment-cuts';
import passwordModel, { PasswordModel } from './password';
import requestCancelationModel, { RequestCancelationModel } from './request-cancelation';
import requestCounterModel, { RequestCounterModel } from './request-counter';
import requestCupsModel, { RequestCupsModel } from './request-cups';
import requestDocumentModel, { RequestDocumentModel } from './request-document';
import requestLocationModel, { RequestLocationModel } from './request-location';
import requestPermissionsModel, { RequestPermissionsModel } from './request-permissions';
import requestPreproposalModel, { RequestPreproposalModel } from './request-preproposal';
import requestProposalModel, { RequestProposalModel } from './request-proposal';
import requestViabilityModel, { RequestViabilityModel } from './request-viability';
import requestWorksModel, { RequestWorksModel } from './request-works';
import requestModel, { RequestModel } from './requests';
import scheduledCutModel, { ScheduledCutModel } from './scheduled-cuts';
import sessionModel, { SessionModel } from './session';
import textModel, { TextModel } from './text';
import verificationModel, { VerificationModel } from './verification';

interface ManagerStore {
  annotations: AnnotationModel;
  app: AppModel;
  authorizations: AuthorizationsModel;
  cancelation: RequestCancelationModel;
  clients: ClientModel;
  company: CompanyModel;
  counter: RequestCounterModel;
  cups: RequestCupsModel;
  distributor: DistributorModel;
  document: DocumentModel;
  documentation: RequestDocumentModel;
  link: LinkModel;
  location: RequestLocationModel;
  locations: LocationModel;
  managers: ManagerModel;
  nodal: NodalModel;
  nonPaymentCuts: NonPaymentCutModel;
  password: PasswordModel;
  permissions: RequestPermissionsModel;
  prepoposal: RequestPreproposalModel;
  proposal: RequestProposalModel;
  requests: RequestModel;
  scheduledCuts: ScheduledCutModel;
  session: SessionModel;
  text: TextModel;
  verification: VerificationModel;
  viability: RequestViabilityModel;
  works: RequestWorksModel;
}

const managerStore: ManagerStore = {
  annotations: annotationModel,
  app: appModel,
  authorizations: authorizationsModel,
  cancelation: requestCancelationModel,
  clients: clientModel,
  company: companyModel,
  counter: requestCounterModel,
  cups: requestCupsModel,
  distributor: distributorModel,
  document: documentModel,
  documentation: requestDocumentModel,
  link: linkModel,
  location: requestLocationModel,
  locations: locationModel,
  managers: managerModel,
  nodal: nodalModel,
  nonPaymentCuts: nonPaymentCutModel,
  password: passwordModel,
  permissions: requestPermissionsModel,
  prepoposal: requestPreproposalModel,
  proposal: requestProposalModel,
  requests: requestModel,
  scheduledCuts: scheduledCutModel,
  session: sessionModel,
  text: textModel,
  verification: verificationModel,
  viability: requestViabilityModel,
  works: requestWorksModel,
};

export type { ManagerStore };

export default managerStore;
