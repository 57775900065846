import { TagStyles } from 'components/tags';
import _ from 'lodash/fp';
import createNonPaymentCut from 'models/non-payment-cut';

import {
  Contract,
  IContract,
  OWNERS,
  Authorization,
  Alias,
} from './types';

const UNVALID_TECNOLOGY_TYPES = [
  'Electrónico con registrador',
  'Electrónico sin registrador',
  'Electromecánico',
  'Telegestión',
];
const MAX_POWER = 15;

const defaultContract: Contract = {
  id: '',
  alias: { id: 0, name: '', cups: '' },
  owner: 'HOLDER',
  name: '',
  identityNumber: '',
  cups: '',
  cupsType: '',
  status: '',
  tariff: '',
  address: '',
  postalCode: '',
  city: '',
  province: '',
  municipality: '',
  pcp1: '',
  pcp2: '',
  pcp3: '',
  pcp4: '',
  pcp5: '',
  pcp6: '',
  meterTecnologyType: '',
  type: '',
  voltage: '',
  maxInstallationPower: '',
  deviceID: '',
  scheduledCuts: [],
};

const setLocation = (contract: Contract): string => (
  `${contract.address}, ${contract.postalCode} - ${contract.municipality}, ${contract.province}`
);

const contratstate = (state: string): TagStyles => {
  if (_.isEqual(state, 'A')) return 'success';
  if (_.isEqual(state, 'S')) return 'warning';
  if (_.isEqual(state, 'D')) return 'danger';
  return 'notice';
};

const show = (contract = defaultContract): boolean => {
  if ((UNVALID_TECNOLOGY_TYPES.includes(contract.meterTecnologyType)
      && parseInt(contract.maxInstallationPower, 10) >= MAX_POWER)
      || !_.isEqual(contract.status, 'A')) {
    return false;
  }

  return true;
};

const maxPower = _.flow(
  _.pick(['pcp1', 'pcp2', 'pcp3', 'pcp4', 'pcp5', 'pcp6']),
  _.valuesIn,
  _.map(parseFloat),
  _.compact,
  _.max,
);

const createContract = (contract = defaultContract): IContract => ({
  ...contract,
  location: setLocation(contract),
  powerHigh: parseFloat(contract.pcp4 || contract.pcp1).toLocaleString(),
  powerLow: parseFloat(contract.pcp6 || contract.pcp3).toLocaleString(),
  holder: contract.owner === OWNERS[0],
  naming: contract.alias.name || contract.address,
  state: contratstate(contract.status),
  power: parseFloat(contract.maxInstallationPower).toLocaleString(),
  tension: parseFloat(contract.voltage).toLocaleString(),
  maxPower: maxPower(contract),
  unpayment: _.isNil(contract.nonPaymentCut) ? null : createNonPaymentCut(contract.nonPaymentCut),
  show: show(contract),
});

export { createContract, OWNERS };

export type {
  Contract,
  IContract,
  Authorization,
  Alias,
};
