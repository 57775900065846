import { FunctionComponent } from 'react';
import { classNames } from 'libs';

import ButtonWrapper from './components/ButtonWrapper';
import { BaseButtonProps } from './types';
import BUTTON_CLASSES from './styles';
import InnerContent from './components/InnerContent';

interface Props extends BaseButtonProps {
  onClick?: () => void;
  disabled?: boolean;
}

const ActionButton: FunctionComponent<Props> = (
  {
    onClick,
    className = '',
    label = '',
    icon,
    iconRight = false,
    size = 'large',
    styles = 'regular',
    disabled = false,
  }: Props,
) => (
  <ButtonWrapper
    onClick={onClick}
    className={classNames(BUTTON_CLASSES.button(size, styles, iconRight), className)}
    disabled={disabled}
  >
    <InnerContent
      className={BUTTON_CLASSES.icon(iconRight, label)}
      label={label}
      icon={icon}
    />
  </ButtonWrapper>
);

export default ActionButton;
