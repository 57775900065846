import { FormEvent, FunctionComponent, ReactNode } from 'react';
import { classNames } from 'libs';

interface Props {
  onSubmit: (e?: FormEvent<HTMLFormElement> | undefined) => void;
  children: ReactNode;
  className?: string;
}

const Form: FunctionComponent<Props> = ({ onSubmit, children, className = '' }: Props) => (
  <form onSubmit={onSubmit} className={classNames('w-full block', className)}>
    {children}
  </form>
);

export default Form;
