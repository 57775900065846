import { useEffect, useMemo, useState } from 'react';

const useClosestTop = (margin: string, ids: string[]): string | undefined => {
  const [intersecting, setIntersecting] = useState<Record<string, boolean>>({});

  const observers = useMemo(() => (
    ids.reduce((acc, curr) => ({
      ...acc,
      [curr]: new IntersectionObserver(([entry]) => setIntersecting((old) => ({
        ...old,
        [curr]: entry.isIntersecting,
      })), { rootMargin: margin }),
    }), {}) as Record<string, IntersectionObserver>
  ), [margin]);

  useEffect(() => {
    const elements = ids.map((id) => document.getElementById(id));
    elements.forEach((element) => {
      if (!element) return;

      observers[element.id].observe(element);
    });

    return () => {
      elements.forEach((element) => {
        if (!element) return;

        observers[element.id].disconnect();
      });
    };
  }, [observers]);

  return ids.find((id) => intersecting[id]);
};

export default useClosestTop;
