import {
  Action,
  Thunk,
  action,
  thunk,
} from 'easy-peasy';
import { API, handleResponse } from 'libs';
import { Setting, Text } from 'models';
import _ from 'lodash/fp';

import managers from '../../managers';

import { ITexts } from './types';

interface TextModel {
  texts: ITexts;

  init: Action<TextModel, Setting[]>;

  index: Thunk<TextModel>;
  create: Thunk<TextModel, Setting>;
  update: Thunk<TextModel, Setting>;
}

const getText = (texts: Setting[]): ((key: Text) => Setting) => (
  (key: Text): Setting => _.flow(
    _.find(['key', key]),
  )(texts)
);

const createTexts = (texts: Setting[] = []): ITexts => ({
  items: texts,
  find: getText(texts),
});

const textModel: TextModel = {
  texts: createTexts(),

  init: action((state, payload) => {
    state.texts = createTexts(payload);
  }),

  index: thunk(async (actions, _payload) => {
    const response = await API.get(managers)('/texts');
    return handleResponse(response, actions.init);
  }),

  create: thunk(async (actions, payload) => {
    const response = await API.post(managers)('/texts', payload);
    return handleResponse(response, actions.init);
  }),

  update: thunk(async (actions, payload) => {
    const response = await API.put(managers)(`/texts/${payload.id}`, payload);
    return handleResponse(response, actions.init);
  }),
};

export type { TextModel };

export default textModel;
