import { useClientState } from 'clients-app/store';
import _ from 'lodash/fp';
import { Municipality, PostalCode } from 'models';

interface Locations {
  postalcodes: PostalCode[];
  municipalities: Municipality[];
}

const useLocations = (): Locations => {
  const provinces = useClientState((state) => state.locations.items);

  const municipalities = _.flow(
    _.flatMap('municipalities'),
  )(provinces);

  const postalcodes = _.flow(
    _.flatMap('postalCodes'),
  )(municipalities);

  return { postalcodes, municipalities };
};

export default useLocations;
