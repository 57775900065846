import { FunctionComponent } from 'react';
import { classNames } from 'libs';

import { TYPOGRAPHY } from '../styles';

const INPUT = 'focus:outline-none bg-transparent';

type Props = React.InputHTMLAttributes<HTMLInputElement>;

const Input: FunctionComponent<Props> = (
  {
    className,
    ...rest
  }: Props,
) => (
  <input
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...rest}
    className={classNames(TYPOGRAPHY, INPUT, className)}
  />
);

export default Input;
