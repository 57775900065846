import { AxiosError, AxiosResponse } from 'axios';
import { createInstance, isUnauthorized } from 'libs';

import store from '.';

const handleUnauthorized = (
  (error: AxiosError): Promise<AxiosResponse> => {
    if (isUnauthorized(error)) {
      // @ts-ignore
      store.getActions().session.destroy();
    }

    return Promise.reject(error);
  }
);

const managers = createInstance('manager', handleUnauthorized);

export default managers;
