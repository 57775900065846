import { FunctionComponent } from 'react';
import { classNames } from 'libs';

interface Props {
  error?: string;
  className?: string;
}

const ERROR = 'text-s-sm absolute top-full mt-1 left-0 text-danger-400';

const InputError: FunctionComponent<Props> = ({ error, className = '' }) => (
  <p className={classNames(ERROR, className)}>{error}</p>
);

export default InputError;
