import { FunctionComponent, ReactNode } from 'react';

interface Props {
  children: ReactNode;
  onClick?: () => void;
  className?: string;
  disabled?: boolean;
}

const ButtonWrapper: FunctionComponent<Props> = (
  {
    children,
    onClick,
    className = '',
    disabled = false,
  },
) => (
  <button
    type={onClick ? 'button' : 'submit'}
    onClick={onClick}
    className={className}
    disabled={disabled}
  >
    {children}
  </button>
);

export default ButtonWrapper;
