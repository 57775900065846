import { FunctionComponent, ReactNode } from 'react';
import { classNames } from 'libs';

interface Props {
  children: ReactNode;
  className?: string;
}

const H3: FunctionComponent<Props> = ({ children, className = '' }: Props) => (
  <h3 className={classNames('text-s-md-b xl:text-l-md-b', className)}>
    {children}
  </h3>
);

export default H3;
