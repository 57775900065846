import { ChangeEvent, FunctionComponent } from 'react';
import _ from 'lodash/fp';
import { useToggle } from 'hooks';
import classnames from 'classnames';
import { DOCUMENTS, classNames, t } from 'libs';
import { AppDocument, DocType, buildDocument } from 'models';
import { Modal } from 'components/modals';
import { Bodycopy } from 'components/typographies';
import { Icon } from 'components/icons';

import Input from './components/Input';
import FormGroup from './components/FormGroup';
import InputError from './components/InputError';
import { TYPOGRAPHY } from './styles';
import { FieldProps, FilesProps } from './types';

interface Props extends FilesProps, FieldProps {
  container: string;
  docType: DocType;
  maxSize?: number;
}

const calculateSize = _.flow(
  _.map((file: File) => file.size),
  _.sum,
);

const FilesField: FunctionComponent<Props> = (
  {
    id,
    name,
    readOnly = false,
    disabled = false,
    required = false,
    className = '',
    values,
    onChange,
    onBlur,
    accept = '.pdf',
    touched = false,
    error = '',
    maxSize = 0,
    container,
    docType,
  }: Props,
) => {
  const [open, toggleOpen] = useToggle(false);
  const hasValue = !_.isEmpty(values);
  const hasError = touched && !_.isEmpty(error);

  const handleChange = async (e: ChangeEvent<HTMLInputElement>): Promise<void> => {
    const { cancelable, target } = e;
    const { files } = target;
    if (cancelable || _.isNil(files)) return;

    if (DOCUMENTS.tooBig(calculateSize(files), maxSize) && maxSize > 0) {
      toggleOpen();
      return;
    }
    const doc = buildDocument(docType, container);
    const docsB64: AppDocument[] = await DOCUMENTS.readFiles(files, { ...doc });
    onChange(name, [...docsB64]);
  };

  const handleOnDelete = (filename: string): (() => void) => (
    (): void => {
      const newValues = _.filter((doc: AppDocument) => doc.filename !== filename)(values);
      onChange(name, [...newValues]);
    }
  );

  const ERROR = classnames({
    hidden: !touched || disabled,
    block: hasError,
  });

  const MAX = classnames(classNames(TYPOGRAPHY, 'mr-1'), {
    hidden: maxSize === 0,
    'text-content': hasValue && !disabled,
    'text-content-inactive': !hasValue && !disabled,
    'text-content-disabled': disabled,
  });

  const LABEL = classnames('flex items-center cursor-pointer text-content-interaction', {
    'mb-2': hasValue,
  });

  return (
    <FormGroup className={className}>
      <label htmlFor={id} className={LABEL}>
        <span className="inline-flex items-center justify-center mr-1 text-s-base-b xl:text-l-base-b">
          <Icon name="attach" className="mr-2" />
          {t('models.documents.placeholder.others')}
        </span>
        <span className={MAX}>{t('max_size', { size: maxSize })}</span>
      </label>
      {_.map((doc: AppDocument) => (
        <div key={doc.filename} className="flex mb-1">
          <Icon name="attach" className="mr-2 text-content-disabled" />
          <Bodycopy className="truncate text-content-disabled">{doc.filename}</Bodycopy>
          <button type="button" className="ml-2 text-content-interaction" onClick={handleOnDelete(doc.filename)}>
            <Icon name="close" />
          </button>
        </div>
      ))(values)}
      <Input
        id={id}
        name={name}
        type="file"
        className="hidden"
        onChange={handleChange}
        readOnly={readOnly}
        disabled={disabled}
        required={required}
        onClick={onBlur}
        accept={accept}
        multiple
      />
      <InputError error={error} className={ERROR} />
      <Modal open={open} title={t('error')} onClose={toggleOpen}>
        <Bodycopy>{t('errors.max_size', { size: maxSize })}</Bodycopy>
      </Modal>
    </FormGroup>
  );
};

export default FilesField;
