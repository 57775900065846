import { InternalLink } from 'components/buttons';
import { Container } from 'components/containers';
import { useManagerState } from 'managers-app/store';
import { FunctionComponent } from 'react';

const Back: FunctionComponent = () => {
  const label = useManagerState((state) => state.app.label);
  const back = useManagerState((state) => state.app.back);

  if (!back || !label) return null;

  return (
    <Container size="large" className="mb-4">
      <InternalLink
        icon="back-arrow"
        to={back as string}
        label={label}
        styles="positive"
      />
    </Container>
  );
};

export default Back;
