import { Thunk, thunk } from 'easy-peasy';
import { API, handleData } from 'libs';
import { FAQType } from 'models';

import clients from '../../clients';

interface FAQModel {
  index: Thunk<FAQModel, FAQType>;
}

const faqModel: FAQModel = {
  index: thunk(async (_actions, payload) => {
    const response = await API.get(clients)(`/faqs/${payload}`);
    return handleData(response);
  }),
};

export type { FAQModel };

export default faqModel;
