import { saveAs } from 'file-saver';
import { AppDocument } from 'models';
import _ from 'lodash/fp';

import t from '../locales';

const classNames = (...classes: (string | undefined)[]): string => classes.filter(Boolean).join(' ');

const download = (document: AppDocument): void => {
  const extension = _.flow(
    _.split('.'),
    _.last,
  )(document.filename);

  saveAs(document.url, `${t(`models.documents.${document.docType}`)}.${extension}`);
};

export { classNames, download };
