import { useEffect } from 'react';
import { useClientActions } from 'clients-app/store';

const useAppClose = (route: string): void => {
  const setClose = useClientActions((state) => state.app.setClose);

  useEffect(() => {
    setClose(route);
  }, [route]);
};

export default useAppClose;
