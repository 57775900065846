import { classNames } from 'libs';
import { FunctionComponent } from 'react';

interface Props {
  src: string;
  alt?: string;
  className?: string;
}

const Image: FunctionComponent<Props> = ({ src, alt = '', className = '' }) => (
  <img src={src} alt={alt} className={classNames('block max-w-full max-h-full', className)} />
);

export default Image;
