import _ from 'lodash/fp';
import {
  action,
  Action,
  computed,
  Computed,
  thunk,
  Thunk,
} from 'easy-peasy';
import {
  API,
  COOKIES,
  handleResponse,
  momentum,
} from 'libs';
import {
  Client,
  createClient,
  IClient,
  SessionParams,
} from 'models';

import clients from '../../clients';

import { SessionResponse } from './types';

interface SessionModel {
  user: IClient;
  token: string;

  init: Action<SessionModel, SessionResponse>;
  initUser: Action<SessionModel, Client>;
  destroy: Action<SessionModel>;

  create: Thunk<SessionModel, SessionParams>;
  update: Thunk<SessionModel>;

  loggedIn: Computed<SessionModel, boolean>;
}

const sessionModel: SessionModel = {
  user: createClient(),
  token: '',

  init: action((state, payload) => {
    // @ts-ignore
    const user: Client = _.omit(['token'])(payload);
    const { token } = payload;
    COOKIES.set('client', token, momentum().add(1, 'month').toDate());
    clients.defaults.headers.Authorization = token;
    state.token = token;
    state.user = createClient(user);
  }),

  initUser: action((state, payload) => {
    state.user = createClient(payload);
  }),

  destroy: action((state) => {
    COOKIES.remove('client');
    clients.defaults.headers.Authorization = '';
    state.token = '';
  }),

  create: thunk(async (actions, payload) => {
    const response = await API.post(clients)('/session', payload);
    return handleResponse(response, actions.init);
  }),

  update: thunk(async (actins, _payload) => {
    const token = COOKIES.get('client');
    if (_.isUndefined(token)) return true;

    const response = await API.put(clients)('/session', { token });
    return handleResponse(response, actins.init);
  }),

  loggedIn: computed((state) => state.token !== ''),
};

export type { SessionModel };

export default sessionModel;
