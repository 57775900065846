import { FunctionComponent } from 'react';

interface Props {
  className: string;
}

const LineDecorator: FunctionComponent<Props> = ({ className }: Props) => (
  <span className={className} />
);

export default LineDecorator;
