import { FunctionComponent, ReactNode } from 'react';
import _ from 'lodash/fp';
import { Icon } from 'components/icons';
import { ButtonText } from 'components/typographies';
import { classNames } from 'libs';

interface Props {
  message: string;
  children: ReactNode;
  className?: string;
}

// TODO: include a picture or something
// @ts-ignore
const ErrorSection: FunctionComponent<Props> = (
  {
    message,
    children,
    className = 'h-[148px]',
  }: Props,
) => (
  !_.isEmpty(message)
    ? (
      <div className={classNames('flex flex-col items-center justify-center', className)}>
        <Icon
          name="warning"
          size="large-growth"
          className="mb-6 text-danger-400"
        />
        <ButtonText tag="h4" className="text-center text-danger-400">{message}</ButtonText>
      </div>
    ) : children
);

export default ErrorSection;
