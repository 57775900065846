import { action, Action } from 'easy-peasy';

interface AppModel {
  title: string;
  label: string;
  close: string;
  back: string | (() => void);

  setTitle: Action<AppModel, string>;
  setLabel: Action<AppModel, string>;
  setClose: Action<AppModel, string>;
  setBack: Action<AppModel, string | (() => void)>;
}

const appModel: AppModel = {
  title: '',
  close: '',
  back: '',
  label: '',

  setTitle: action((state, payload) => {
    state.title = payload;
  }),

  setLabel: action((state, payload) => {
    state.label = payload;
  }),

  setClose: action((state, payload) => {
    state.close = payload;
  }),

  setBack: action((state, payload) => {
    state.back = payload;
  }),
};

export type { AppModel };

export default appModel;
