import _ from 'lodash/fp';
import {
  action,
  Action,
  computed,
  Computed,
  thunk,
  Thunk,
} from 'easy-peasy';
import {
  API,
  COOKIES,
  handleResponse,
  momentum,
} from 'libs';
import {
  Manager,
  createManager,
  IManager,
  SessionParams,
  PasswordParams,
} from 'models';

import managers from '../../managers';

import { NameParams, SessionResponse } from './types';

interface SessionModel {
  user: IManager;
  token: string;

  init: Action<SessionModel, SessionResponse>;
  initUser: Action<SessionModel, Manager>;
  destroy: Action<SessionModel>;

  create: Thunk<SessionModel, SessionParams>;
  update: Thunk<SessionModel>;

  name: Thunk<SessionModel, NameParams>;
  password: Thunk<SessionModel, PasswordParams>;

  loggedIn: Computed<SessionModel, boolean>;
}

const sessionModel: SessionModel = {
  user: createManager(),
  token: '',

  init: action((state, payload) => {
    // @ts-ignore
    const user: Manager = _.omit(['token'])(payload);
    const { token } = payload;
    managers.defaults.headers.common.Authorization = token;
    COOKIES.set('manager', token, momentum().add(1, 'month').toDate());
    state.token = token;
    state.user = createManager(user);
  }),

  initUser: action((state, payload) => {
    state.user = createManager(payload);
  }),

  destroy: action((state) => {
    COOKIES.remove('manager');
    managers.defaults.headers.common.Authorization = '';
    state.token = '';
  }),

  create: thunk(async (actions, payload) => {
    const response = await API.post(managers)('/session', payload);
    return handleResponse(response, actions.init);
  }),

  update: thunk(async (actins, _payload) => {
    const token = COOKIES.get('manager');
    if (_.isUndefined(token)) return true;

    const response = await API.put(managers)('/session', { token });
    return handleResponse(response, actins.init);
  }),

  name: thunk(async (actions, payload) => {
    const response = await API.put(managers)('/profile', payload);
    return handleResponse(response, actions.initUser);
  }),

  password: thunk(async (actions, payload) => {
    const response = await API.put(managers)('/profile/password', payload);
    return handleResponse(response, actions.initUser);
  }),

  loggedIn: computed((state) => state.token !== ''),
};

export type { SessionModel, PasswordParams, NameParams };

export default sessionModel;
