import { useClientActions } from 'clients-app/store';
import { useEffect } from 'react';

const useAppBack = (route: string | (() => void)): void => {
  const setBack = useClientActions((state) => state.app.setBack);

  useEffect(() => {
    setBack(route);
  }, [route]);
};

export default useAppBack;
