import { createStore, EasyPeasyConfig, Store } from 'easy-peasy';
import _ from 'lodash/fp';

import model from './models';
import { useClientState, useClientActions } from './hooks';
import {
  ClientRegisterParams,
  ClientUpdateParams,
  defaultClientRegisterParams,
} from './models/client';
import { FraudParams, IncidentParams, MessageParams } from './models/contact';
import { EnergyByHour, PowerByMonth } from './models/consumption';
import { Breakdown } from './models/contracts';
import { RequestAppointment } from './models/requests';

const buildClientStore = (overrideConfig: EasyPeasyConfig = {}): Store<typeof model> => {
  const defaultConfig = {
    injections: {},
  };

  const config = _.defaultsDeep(defaultConfig, overrideConfig);

  if (process.env.NODE_ENV === 'development') {
    return createStore(model, {
      devTools: true,
      ...config,
    });
  }

  return createStore(model, config);
};

export {
  useClientState,
  useClientActions,
  defaultClientRegisterParams,
};

export type {
  ClientRegisterParams,
  ClientUpdateParams,
  FraudParams,
  IncidentParams,
  MessageParams,
  EnergyByHour,
  PowerByMonth,
  Breakdown,
  RequestAppointment,
};

export default buildClientStore();
