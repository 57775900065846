import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const useConditionalRedirect = (condition: boolean, redirect: string) => {
  const navigate = useNavigate();
  useEffect(() => {
    if (condition) {
      navigate(redirect);
    }
  }, []);
};

export default useConditionalRedirect;
