import { useClientActions } from 'clients-app/store';
import { useEffect } from 'react';

const useAppReturn = (route: string, label: string) => {
  const setBack = useClientActions((actions) => actions.app.setBack);
  const setLabel = useClientActions((actions) => actions.app.setLabel);

  useEffect(() => {
    setBack(route);
    setLabel(label);
  }, [route, label]);
};

export default useAppReturn;
