import { useConditionalRedirect } from 'hooks';
import {
  indexed,
  layout,
  page,
  route,
} from 'libs';
import { ClientLoader } from 'managers-app/features/clients';
import { usePrivateAsyncRequest } from 'managers-app/hooks';
import { Dashboard, Plain } from 'managers-app/layouts';
import { PATHS, ROUTES } from 'managers-app/libs';
import { useManagerActions, useManagerState } from 'managers-app/store';
import { FunctionComponent, lazy } from 'react';
import { Outlet, RouteObject } from 'react-router-dom';

const Clients = lazy(() => import('./Clients'));
const AllClients = lazy(() => import('./AllClients'));
const PendingClients = lazy(() => import('./PendingClients'));
const Client = lazy(() => import('./Client'));
const ClientVerification = lazy(() => import('./ClientVerification'));
const ClientRequests = lazy(() => import('./ClientRequests'));
const NewCauRequest = lazy(() => import('./requests/NewCauRequest'));
const NewCupsRequest = lazy(() => import('./requests/NewCupsRequest'));
const NewSupplyRequest = lazy(() => import('./requests/NewSupplyRequest'));
const NewSupplyCupsRequest = lazy(() => import('./requests/NewSupplyCupsRequest'));
const NewSelfconsumptionRequest = lazy(() => import('./requests/NewSelfconsumptionRequest'));
const NewGenerationRequest = lazy(() => import('./requests/NewGenerationRequest'));

const ClientPermissions: FunctionComponent = () => {
  const user = useManagerState((state) => state.session.user);
  const selectables = useManagerActions((actions) => actions.company.indexSelectables);
  const locations = useManagerActions((actions) => actions.locations.index);
  useConditionalRedirect((!user.section('iclients') && !user.requestable), ROUTES.profile);
  usePrivateAsyncRequest(selectables);
  usePrivateAsyncRequest(locations);

  return <Outlet />;
};

const ClientRoutes: RouteObject[] = [
  route(
    PATHS.clients.index,
    layout(
      <ClientPermissions />,
      layout(
        <Dashboard />,
        layout(
          <Clients />,
          indexed(<AllClients />),
          page(PATHS.clients.pending, <PendingClients />),
        ),
      ),
      route(
        PATHS.clients.show.index,
        layout(
          <ClientLoader />,
          layout(
            <Dashboard />,
            layout(
              <Client />,
              page(PATHS.clients.show.verification, <ClientVerification />),
            ),
          ),
          route(
            PATHS.clients.show.requests.index,
            layout(
              <Dashboard />,
              layout(
                <Client />,
                indexed(<ClientRequests />),
              ),
            ),
            layout(
              <Plain />,
              page(PATHS.clients.show.requests.cau, <NewCauRequest />),
              page(PATHS.clients.show.requests.cups, <NewCupsRequest />),
              page(PATHS.clients.show.requests.supply, <NewSupplyRequest />),
              page(PATHS.clients.show.requests.supplycups, <NewSupplyCupsRequest />),
              page(PATHS.clients.show.requests.selfconsumption, <NewSelfconsumptionRequest />),
              page(PATHS.clients.show.requests.generation, <NewGenerationRequest />),
            ),
          ),
        ),
      ),
    ),
  ),
];

export default ClientRoutes;
