import {
  ComponentProps,
  FunctionComponent,
  ReactNode,
  useMemo,
  useRef,
} from 'react';
import _ from 'lodash/fp';
import { useClosestTop } from 'clients-app/hooks';
import { Container } from 'components/containers';
import { ButtonLink } from 'components/buttons';

import { MenuItem, Topbar, Sidebar } from './components';

interface Props {
  menuItems: Omit<ComponentProps<typeof MenuItem>, 'active'>[];
  children: ReactNode;
  button?: Omit<ComponentProps<typeof ButtonLink>, 'className' | 'iconRight' | 'size' | 'styles'>;
}

const AnchorsScreen: FunctionComponent<Props> = (
  {
    menuItems,
    children,
    button,
  }: Props,
) => {
  const wrapper = useRef<HTMLDivElement>(null);
  const closest = useClosestTop(`-${(wrapper.current?.offsetTop || 0) + 16}px`, menuItems.map((tab) => tab.id));

  const mappedMenuItems = useMemo(() => (
    menuItems.map((tab) => ({
      ...tab,
      active: closest === tab.id,
    }))
  ), [menuItems, closest]);

  return (
    <Container className="flex-1 lg:flex">
      <Topbar menuItems={mappedMenuItems} />
      <Sidebar className="hidden w-1/4 lg:block">
        <div className="space-y-6">
          {_.map((item: ComponentProps<typeof MenuItem>) => (
            <MenuItem
              key={item.id}
              id={item.id}
              label={item.label}
              active={item.active}
            />
          ))(mappedMenuItems)}
        </div>
        {_.all(_.negate(_.isUndefined), [button?.to, button?.label]) && (
          <ButtonLink
            to={button?.to ?? ''}
            label={button?.label}
            icon={button?.icon}
            className="mt-14"
          />
        )}
      </Sidebar>
      <div ref={wrapper} className="flex flex-col lg:w-3/4 lg:items-end mt-20 lg:mt-0 lg:pt-16 lg:pl-16 lg:ml-auto [&_*:target]:scroll-mt-32 lg:[&_*:target]:scroll-mt-20">
        {children}
      </div>
      <div className="block lg:hidden">
        {_.all(_.negate(_.isUndefined), [button?.to, button?.label]) && (
          <ButtonLink
            to={button?.to ?? ''}
            label={button?.label}
            icon={button?.icon}
            size="fixed"
          />
        )}
      </div>
    </Container>
  );
};

export default AnchorsScreen;
