import {
  Action,
  Thunk,
  action,
  thunk,
} from 'easy-peasy';
import { API, handleResponse } from 'libs';
import { Province } from 'models';

import managers from '../../managers';

interface LocationModel {
  items: Province[];

  init: Action<LocationModel, Province[]>;

  index: Thunk<LocationModel>;
}

const locationModel: LocationModel = {
  items: [],

  init: action((state, payload) => {
    state.items = payload;
  }),

  index: thunk(async (actions, _payload) => {
    const response = await API.get(managers)('/locations');
    return handleResponse(response, actions.init);
  }),
};

export type { LocationModel };

export default locationModel;
