import { Params, StoreRequest } from 'hooks';
import { useManagerState } from 'managers-app/store';
import { useEffect } from 'react';

const usePrivateAsyncRequest = (request: StoreRequest, params: Params) => {
  const loggedIn = useManagerState((state) => state.session.loggedIn);

  useEffect(() => {
    if (loggedIn) {
      request(params);
    }
  }, [loggedIn]);
};

export default usePrivateAsyncRequest;
