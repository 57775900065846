import {
  Action,
  Computed,
  Thunk,
  action,
  computed,
  thunk,
} from 'easy-peasy';
import { API, handleResponse } from 'libs';
import { Distributor } from 'models';

import clients from '../../clients';

interface DistributorModel {
  items: Distributor[];

  init: Action<DistributorModel, Distributor[]>;

  index: Thunk<DistributorModel>;

  hasSection: Computed<DistributorModel, boolean>;
}

const distributorModel: DistributorModel = {
  items: [],

  init: action((state, payload) => {
    state.items = payload;
  }),

  index: thunk(async (actions, _payload) => {
    const response = await API.get(clients)('/distributors');
    return handleResponse(response, actions.init);
  }),

  hasSection: computed((state) => state.items.length > 1),
};

export type { DistributorModel };

export default distributorModel;
