import { TagStyles } from 'components/tags';

const CLIENT_TYPES = [
  'PARTICULAR',
  'COMPANY',
  'SELF_EMPLOYED',
] as const;

type ClientType = typeof CLIENT_TYPES[number];

const CLIENT_STATES = [
  'EMAIL_PENDING',
  'UNVALIDATED',
  'PENDING',
  'VALIDATED',
  'REJECTED',
] as const;

type ClientState = typeof CLIENT_STATES[number];

type Client = {
  id: number;
  createdAt: string;
  type: ClientType;
  name: string;
  email: string;
  identityNumber: string;
  address: string;
  postalCode: string;
  municipality: string;
  province: string;
  phoneNumber: string;
  state: ClientState;
};

interface IClient extends Client {
  particular: boolean;
  location: string;
  validated: boolean;
  rejected: boolean;
  pending: boolean;
  emailPending: boolean;
  stateColor: TagStyles;
}

export { CLIENT_TYPES, CLIENT_STATES };

export type { Client, IClient, ClientState };
