import distributors from '../../features/distributors/distributors.json';
import contracts from '../../features/contracts/contracts.json';
import home from '../../features/home/home.json';
import procedures from '../../features/procedures/procedures.json';
import breakdowns from '../../features/breakdowns/breakdowns.json';
import supply from '../../views/electrical-supply/electrical-supply.json';
import selfconsumption from '../../views/selfconsumption/selfconsumption.json';
import profile from '../../views/profile/profile.json';
import consumptions from '../../features/consumption/consumptions.json';

import connections from './connections.json';
import register from './register.json';
import help from './help.json';
import app from './app.json';
import pages from './pages.json';

const clients = {
  ...app,
  pages,
  distributors,
  home,
  procedures,
  supply,
  breakdowns,
  selfconsumption,
  connections,
  help,
  profile,
  register,
  contracts,
  consumptions,
};

export default clients;
