import {
  Action,
  Thunk,
  action,
  thunk,
} from 'easy-peasy';
import {
  AppDocument,
  INonPaymentCut,
  NonPaymentCut,
  createNonPaymentCut,
} from 'models';
import _ from 'lodash/fp';
import { API, handleResponse } from 'libs';

import managers from '../../managers';

interface NonPaymentCutModel {
  items: INonPaymentCut[];

  init: Action<NonPaymentCutModel, NonPaymentCut[]>;

  index: Thunk<NonPaymentCutModel>;

  create: Thunk<NonPaymentCutModel, AppDocument>;
}

const nonPaymentCutModel: NonPaymentCutModel = {
  items: [],

  init: action((state, payload) => {
    const npcs = _.map(createNonPaymentCut)(payload);
    state.items = npcs;
  }),

  index: thunk(async (actions, _payload) => {
    const response = await API.get(managers)('/non-payment-cuts');
    return handleResponse(response, actions.init);
  }),

  create: thunk(async (actions, payload) => {
    const response = await API.post(managers)('/non-payment-cuts', payload);
    return handleResponse(response, actions.init);
  }),
};

export type { NonPaymentCutModel };

export default nonPaymentCutModel;
