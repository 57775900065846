import { useEffect } from 'react';
import TagManager from 'react-gtm-module';

const useGTM = (gtmId: string): void => {
  useEffect(() => {
    if (gtmId) {
      TagManager.initialize({ gtmId });
    }

    return () => {
      TagManager.initialize({ gtmId: '' });
    };
  }, [gtmId]);
};

export default useGTM;
