import { FunctionComponent, ReactNode } from 'react';
import { ButtonText } from 'components/typographies';
import { t } from 'libs';

import Loader from './Loader';

interface Props {
  loading: boolean;
  children: ReactNode;
  message?: string;
}

// TODO: include a picture or something
// @ts-ignore
const AppLoader: FunctionComponent<Props> = (
  {
    loading,
    children,
    message = t('loading'),
  }: Props,
) => (
  loading
    ? (
      <div className="flex flex-col items-center justify-center w-full max-w-full min-h-screen px-5 bg-slate-50">
        <Loader />
        <ButtonText tag="h4" className="text-center text-notice-400">{message}</ButtonText>
      </div>
    ) : children
);

export default AppLoader;
