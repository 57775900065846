import { Container } from 'components/containers';
import { Icon } from 'components/icons';
import { H3 } from 'components/typographies';
import { t } from 'libs';
import { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';

interface Props {
  title: string;
  back?: string | (() => void);
  close: string;
  backButton: boolean;
  hasBack: boolean;
  hasClose: boolean;
}

const WithNavigation: FunctionComponent<Props> = (
  {
    title,
    back,
    close,
    backButton,
    hasBack,
    hasClose,
  }: Props,
) => (
  <header className="fixed top-0 left-0 z-30 flex items-center w-full h-14 shadow-inner-bottom xl:h-16 bg-header-negative xl:bg-header">
    <Container className="flex items-center">
      <div className="flex-1">
        {hasBack && (
        <>
          {backButton
            ? (
              <button type="button" className="inline-flex items-center text-content-interaction xl:text-content-negative text-l-base-b" onClick={back as () => void}>
                <Icon name="circle-angle-left" className="lg:mr-2" />
                <span className="hidden lg:block">{t('back')}</span>
              </button>
            ) : (
              <Link to={back as string} className="inline-flex items-center text-content-interaction xl:text-content-negative text-l-base-b">
                <Icon name="circle-angle-left" className="lg:mr-2" />
                <span className="hidden lg:block">{t('back')}</span>
              </Link>
            )}
        </>
        )}
      </div>
      <div className="flex-1">
        <H3 className="text-center xl:text-content-negative !font-normal truncate">{title}</H3>
      </div>
      <div className="flex flex-col items-end flex-1">
        {hasClose && (
        <Link to={close} className="block text-content-interaction xl:text-content-negative">
          <Icon name="close" />
        </Link>
        )}
      </div>
    </Container>
  </header>
);

export default WithNavigation;
