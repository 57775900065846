import { FunctionComponent } from 'react';
import { Container } from 'components/containers';

import BaseFooter from './BaseFooter';
import GDPR from './GDPR';
import SocialMedia from './SocialMedia';

const FooterDefault: FunctionComponent = () => (
  <BaseFooter className="py-5 shadow-top bg-footer-negative">
    <Container>
      <div className="items-center justify-between md:flex">
        <GDPR />
        <SocialMedia className="mt-8 md:mt-0" />
      </div>
    </Container>
  </BaseFooter>
);

export default FooterDefault;
