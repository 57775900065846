import { FunctionComponent } from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { useIsMatch } from 'hooks';

interface Props {
  to: string;
  label: string;
}

const ItemNav: FunctionComponent<Props> = ({ to, label }: Props) => {
  const isMatch = useIsMatch(to);
  const LINK = classnames('block mb-4 text-content text-l-base relative group/item', {
    'font-bold': isMatch,
  });
  const LINE = classnames('h-[3px] w-0 bg-header transition-all duration-200 group-hover/item:w-8 absolute top-7 left-0', {
    '!w-8': isMatch,
  });

  return (
    <Link to={to} className={LINK}>
      <span>{label}</span>
      <span className={LINE} />
    </Link>
  );
};

export default ItemNav;
