import { AppDocument } from 'models';

const LEGAL_DOCUMENTS = ['privacy', 'cookies', 'legal', 'quality', 'authorized-contract'];

type LegalDocument = typeof LEGAL_DOCUMENTS[number];

interface IDocuments {
  items: AppDocument[];
  find: (docType: LegalDocument) => AppDocument;
}

export { LEGAL_DOCUMENTS };

export type { IDocuments, LegalDocument };
