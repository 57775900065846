import { Thunk, thunk } from 'easy-peasy';
import { API, handleResponse } from 'libs';
import { ChangeStateParams } from 'models';

import clients from '../../clients';

interface RequestProposalModel {
  accept: Thunk<RequestProposalModel, ChangeStateParams>;
  reject: Thunk<RequestProposalModel, ChangeStateParams>;
}

const requestProposalModel: RequestProposalModel = {
  accept: thunk(async (_actions, payload, { getStoreActions }) => {
    const url = `/requests/${payload.requestID}/proposal/${payload.id}/accept`;
    const response = await API.post(clients)(url, payload.params);
    // @ts-ignore
    const { initCurrent: init } = getStoreActions().requests;
    return handleResponse(response, init);
  }),

  reject: thunk(async (_actions, payload, { getStoreActions }) => {
    const url = `/requests/${payload.requestID}/proposal/${payload.id}/reject`;
    const response = await API.post(clients)(url, payload.params);
    // @ts-ignore
    const { initCurrent: init } = getStoreActions().requests;
    return handleResponse(response, init);
  }),
};

export type { RequestProposalModel };

export default requestProposalModel;
