import { classNames } from 'libs';
import { FunctionComponent } from 'react';

import { TAG_STYLES, TAG_TEXT_STYLES, TagStyles } from './styles';

interface Props {
  label: string;
  styles?: TagStyles;
  className?: string;
  tag?: 'div' | 'span';
}

const Tag: FunctionComponent<Props> = (
  {
    label,
    styles = 'regular',
    className = '',
    tag: Wrap = 'div',
  }: Props,
) => (
  <Wrap className={classNames('inline-flex', className)}>
    <Wrap className="h-[17px] flex flex-col justify-center">
      <Wrap className={classNames('min-w-[6px] w-1.5 aspect-square rounded-full mr-1', TAG_STYLES[styles])} />
    </Wrap>
    <Wrap className={classNames('text-s-sm font-bold', TAG_TEXT_STYLES[styles])}>
      {label}
    </Wrap>
  </Wrap>
);

export default Tag;
