import {
  Action,
  Thunk,
  action,
  thunk,
} from 'easy-peasy';
import { API, handleResponse } from 'libs';
import { ID, Manager, defaultmanager } from 'models';

import managers from '../../managers';

interface ManagerModel {
  items: Manager[];
  current: Manager;

  init: Action<ManagerModel, Manager[]>;
  initCurrent: Action<ManagerModel, Manager>;

  index: Thunk<ManagerModel>;
  create: Thunk<ManagerModel, Manager>;
  show: Thunk<ManagerModel, ID>;
  update: Thunk<ManagerModel, Manager>;
  destroy: Thunk<ManagerModel, ID>;
}

const managerModel: ManagerModel = {
  items: [],
  current: defaultmanager,

  init: action((state, payload) => {
    state.items = payload;
  }),

  initCurrent: action((state, payload) => {
    state.current = payload;
  }),

  index: thunk(async (actions, _payload) => {
    const response = await API.get(managers)('/managers');
    return handleResponse(response, actions.init);
  }),

  create: thunk(async (actions, payload) => {
    const response = await API.post(managers)('/managers', payload);
    return handleResponse(response, actions.initCurrent);
  }),

  show: thunk(async (actions, payload) => {
    const response = await API.get(managers)(`/managers/${payload}`);
    return handleResponse(response, actions.initCurrent);
  }),

  update: thunk(async (actions, payload) => {
    const response = await API.put(managers)(`/managers/${payload.id}`, payload);
    return handleResponse(response, actions.initCurrent);
  }),

  destroy: thunk(async (_actions, payload) => {
    const response = await API.delete(managers)(`/managers/${payload}`);
    return handleResponse(response);
  }),
};

export type { ManagerModel };

export default managerModel;
