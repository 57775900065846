import { FunctionComponent } from 'react';
import { classNames } from 'libs';

import { BaseLinkProps } from './types';
import ButtonWrapper from './components/ButtonWrapper';
import InnerContent from './components/InnerContent';
import LineDecorator from './components/LineDecorator';
import BUTTON_CLASSES from './styles';

interface Props extends BaseLinkProps {
  onClick?: () => void;
  disabled?: boolean;
}

const ActionLink: FunctionComponent<Props> = (
  {
    onClick,
    className = '',
    label = '',
    icon,
    iconRight = false,
    small = false,
    size = 'regular',
    styles = 'regular',
    disabled = false,
  }: Props,
) => (
  <ButtonWrapper
    onClick={onClick}
    className={classNames(BUTTON_CLASSES.link(styles, iconRight), className)}
    disabled={disabled}
  >
    <InnerContent
      className={BUTTON_CLASSES.icon(iconRight, label)}
      label={label}
      icon={icon}
      small={small}
    />
    <LineDecorator
      className={classNames(
        BUTTON_CLASSES.line(size, styles, disabled),
        BUTTON_CLASSES.position(small, iconRight, icon),
      )}
    />
  </ButtonWrapper>
);

export default ActionLink;
