import { useClientActions } from 'clients-app/store';
import { ActionButton, ActionLink } from 'components/buttons';
import { Form, FormResponse, TextField } from 'components/forms';
import { Modal } from 'components/modals';
import { Bodycopy } from 'components/typographies';
import { useCustomForm, useHandleMessage, useToggle } from 'hooks';
import { t } from 'libs';
import { Alias } from 'models';
import { FunctionComponent } from 'react';
import * as yup from 'yup';

interface Props {
  alias: Alias;
}

const schema = yup.object().shape({
  name: yup.string()
    .required(t('errors.required')),
});

const AliasForm: FunctionComponent<Props> = ({ alias }: Props) => {
  const create = useClientActions((actions) => actions.alias.create);
  const update = useClientActions((actions) => actions.alias.update);
  const destroy = useClientActions((actions) => actions.alias.destroy);
  const [open, toggleOpen] = useToggle(false);
  const [message, handleResponse] = useHandleMessage(t('clients.contracts.alias_updated'));

  const handleOnSubmit = async (params: Alias): Promise<void> => {
    handleResponse(alias.id ? await update(params) : await create(params));
  };

  const handleOnDestroy = async (): Promise<void> => {
    handleResponse(await destroy(alias.id));
  };

  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    isValid,
    isSubmitting,
  } = useCustomForm({
    initialValues: alias,
    onSubmit: handleOnSubmit,
    validationSchema: schema,
  });

  return (
    <div className="flex items-baseline">
      <Bodycopy className="truncate">{values.name || '-'}</Bodycopy>
      <ActionLink
        label={t('clients.contracts.change')}
        onClick={toggleOpen}
        className="ml-8"
        small
      />
      <Modal open={open} onClose={toggleOpen} title={t('clients.contracts.change_alias')} action>
        <Form onSubmit={handleSubmit}>
          <TextField
            id="name"
            name="name"
            placeholder={t('models.contract.alias')}
            value={values.name}
            onChange={handleChange}
            onBlur={handleBlur}
            touched={touched.name}
            error={errors.name}
            required
          />
          <div className="flex items-center justify-between mb-8">
            <ActionButton
              label={t('delete')}
              onClick={handleOnDestroy}
              styles="danger"
              size="small"
            />
            <div>
              <ActionButton
                label={t('send')}
                disabled={!isValid || isSubmitting}
                size="small"
              />
              <ActionButton
                label={t('cancel')}
                onClick={toggleOpen}
                styles="outlined"
                size="small"
              />
            </div>
          </div>
          <FormResponse success={message.success} message={message.message} />
        </Form>
      </Modal>
    </div>
  );
};

export default AliasForm;
