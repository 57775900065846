import { ROUTES } from 'clients-app/libs';
import { useClientState } from 'clients-app/store';
import { ExternalLink, InternalLink } from 'components/buttons';
import { Container } from 'components/containers';
import { Image } from 'components/images';
import { t } from 'libs';
import { FunctionComponent } from 'react';

import BaseFooter from './BaseFooter';
import SocialMedia from './SocialMedia';
import GDPR from './GDPR';

const FooterHome: FunctionComponent = () => {
  const company = useClientState((state) => state.company.item);

  return (
    <BaseFooter className="py-10 bg-footer">
      <Container>
        <div className="mb-4 border-b border-divider-negative">
          <div className="flex flex-row-reverse items-center justify-between mb-10 md:mb-14 md:flex-row">
            <Image src={company.document('logo')} className="w-[140px] md:w-[165px]" />
            <div className="flex flex-col items-start">
              <InternalLink
                to={ROUTES.contact.index}
                label={t('clients.pages.contact')}
                styles="negative"
                className="mb-4"
              />
              <ExternalLink
                to={company.link('web')}
                label={t('clients.pages.about')}
                styles="negative"
                className="mb-4"
              />
              <InternalLink
                to={ROUTES.normative}
                label={t('clients.pages.normative')}
                className="mb-4"
                styles="negative"
              />
            </div>
          </div>
          <SocialMedia className="flex mb-4 md:hidden" />
        </div>
        <div className="items-center justify-between md:flex">
          <GDPR />
          <SocialMedia className="hidden md:flex" />
        </div>
      </Container>
    </BaseFooter>
  );
};

export default FooterHome;
