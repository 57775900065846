import {
  Action,
  Thunk,
  action,
  thunk,
} from 'easy-peasy';
import { API, DOCUMENTS, handleResponse } from 'libs';
import {
  AppDocument,
  ID,
  IRequest,
  NewRequest,
  Request,
  createRequest,
} from 'models';
import { saveAs } from 'file-saver';
import _ from 'lodash/fp';

import clients from '../../clients';

import { RequestAppointment } from './types';

interface RequestModel {
  items: IRequest[];
  current: IRequest;

  init: Action<RequestModel, Request[]>;
  initCurrent: Action<RequestModel, Request>;

  index: Thunk<RequestModel>;
  show: Thunk<RequestModel, ID>;
  create: Thunk<RequestModel, NewRequest>;
  appointment: Thunk<RequestModel, RequestAppointment>;
  download: Thunk<RequestModel, AppDocument>;
}

const requestModel: RequestModel = {
  items: [],
  current: createRequest(),

  init: action((state, payload) => {
    const requests = _.map(createRequest)(payload);
    state.items = requests;
  }),

  initCurrent: action((state, payload) => {
    state.current = createRequest(payload);
  }),

  index: thunk(async (actions, _payload) => {
    const response = await API.get(clients)('/requests');
    return handleResponse(response, actions.init);
  }),

  show: thunk(async (actions, payload) => {
    const response = await API.get(clients)(`/requests/${payload}`);
    return handleResponse(response, actions.initCurrent);
  }),

  create: thunk(async (actions, payload) => {
    const response = await API.post(clients)(payload.uri, payload.request);
    return handleResponse(response, actions.download);
  }),

  appointment: thunk(async (_actions, payload) => {
    const response = await API.post(clients)(`/requests/${payload.id}/appointment`, payload);
    return handleResponse(response);
  }),

  download: thunk(async (_actions, params) => {
    const file = await DOCUMENTS.base64ToPDF(params.base64, params.filename);

    saveAs(file);
  }),
};

export type { RequestModel, RequestAppointment };

export default requestModel;
