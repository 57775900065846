import { useEffect } from 'react';

import { Params, StoreRequest } from './types';

const useAsyncRequest = (request: StoreRequest, params: Params) => {
  useEffect(() => {
    request(params);
  }, []);
};

export default useAsyncRequest;
