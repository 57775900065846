import { Thunk, thunk } from 'easy-peasy';
import { API, handleResponse } from 'libs';
import { ChangeStateParams } from 'models';

import clients from '../../clients';

interface RequestLocationModel {
  payment: Thunk<RequestLocationModel, ChangeStateParams>;
  requirement: Thunk<RequestLocationModel, ChangeStateParams>;
}

const requestLocationModel: RequestLocationModel = {
  payment: thunk(async (_actions, payload, { getStoreActions }) => {
    const url = `/requests/${payload.requestID}/location/${payload.id}/payment`;
    const response = await API.post(clients)(url, payload.params);
    // @ts-ignore
    const { initCurrent: init } = getStoreActions().requests;
    return handleResponse(response, init);
  }),

  requirement: thunk(async (_actions, payload, { getStoreActions }) => {
    const url = `/requests/${payload.requestID}/location/${payload.id}/requirement`;
    const response = await API.post(clients)(url, payload.params);
    // @ts-ignore
    const { initCurrent: init } = getStoreActions().requests;
    return handleResponse(response, init);
  }),
};

export type { RequestLocationModel };

export default requestLocationModel;
