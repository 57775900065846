import { FunctionComponent, lazy } from 'react';
import { useManagerState } from 'managers-app/store';
import { PATHS, ROUTES } from 'managers-app/libs';
import { useConditionalRedirect } from 'hooks';
import { Outlet, RouteObject } from 'react-router-dom';
import {
  indexed,
  layout,
  page,
  route,
} from 'libs';
import { Dashboard } from 'managers-app/layouts';

const Authorizations = lazy(() => import('./Authorizations'));
const AllAuthorizations = lazy(() => import('./AllAuthorizations'));
const PendingAuthorizations = lazy(() => import('./PendingAuthorizations'));
const Authorization = lazy(() => import('./Authorization'));

const AuthorizationPermissions: FunctionComponent = () => {
  const user = useManagerState((state) => state.session.user);
  useConditionalRedirect(!user.section('authorizations'), ROUTES.profile);

  return <Outlet />;
};

const AuthorizationsRoutes: RouteObject[] = [
  layout(
    <Dashboard />,
    route(
      PATHS.authorizations.index,
      layout(
        <AuthorizationPermissions />,
        layout(
          <Authorizations />,
          indexed(<AllAuthorizations />),
          page(PATHS.authorizations.pending, <PendingAuthorizations />),
        ),
        page(PATHS.authorizations.show, <Authorization />),
      ),
    ),
  ),
];

export default AuthorizationsRoutes;
