import { FunctionComponent, ReactNode } from 'react';
import { classNames } from 'libs';

import { SectionMargins, SECTION_MARGIN } from './styles';

interface Props {
  children: ReactNode;
  id?: string;
  margin?: SectionMargins;
  className?: string;
}

const Section: FunctionComponent<Props> = (
  {
    children,
    id = '',
    margin = 'small',
    className = '',
  }: Props,
) => (
  <section id={id} className={classNames('w-full', SECTION_MARGIN[margin], className)}>
    {children}
  </section>
);

export default Section;
