import { ComponentProps, FunctionComponent } from 'react';
import { Container } from 'components/containers';
import _ from 'lodash/fp';

import MenuItem from './MenuItem';

interface Props {
  menuItems:ComponentProps<typeof MenuItem>[];
}

const Topbar: FunctionComponent<Props> = ({ menuItems }: Props) => (
  <div className="fixed left-0 right-0 z-40 bg-backdrop-primary top-14 lg:hidden">
    <Container className="flex space-x-6 overflow-hidden overflow-x-auto h-14 shadow-bottom">
      {_.map((item: ComponentProps<typeof MenuItem>) => (
        <MenuItem
          key={item.id}
          id={item.id}
          label={item.label}
          active={item.active}
        />
      ))(menuItems)}
    </Container>
  </div>
);

export default Topbar;
