import { classNames } from 'libs';
import { FunctionComponent, ReactNode } from 'react';

interface Props {
  children: ReactNode;
  className?: string;
}

const BaseFooter: FunctionComponent<Props> = ({ children, className }: Props) => (
  <footer className={classNames('w-full', className)}>
    {children}
  </footer>
);

export default BaseFooter;
