import { FunctionComponent, ReactNode } from 'react';
import { classNames } from 'libs';

import Container from './Container';
import { ContainerSizes } from './styles';

interface Props {
  children: ReactNode;
  size?: ContainerSizes;
  className?: string;
}

const HorizontalContainer: FunctionComponent<Props> = ({ children, size, className = '' }: Props) => (
  <Container size={size} className={classNames('flex overflow-x-auto', className)}>
    {children}
  </Container>
);

export default HorizontalContainer;
