import _ from 'lodash/fp';

import {
  EDITION,
  Edition,
  IManager,
  Manager,
  REQUESTS,
  ROLES,
  Request,
  Role,
  SECTIONS,
  Section,
  CONTACTS,
} from './types';

const defaultmanager: Manager = {
  name: '',
  email: '',
  role: 'USER',

  requests: [],
  distributors: [],
  sections: [],
  edition: [],
  contacts: [],
};

const isAdmin = (role: Role): boolean => _.isEqual(ROLES[0], role);

const canRequest = (requests: Request[]): ((requestType: Request) => boolean) => (
  (requestType: Request): boolean => _.includes(requestType)(requests)
);

const canSection = (sections: Section[]): ((sectionType: Section) => boolean) => (
  (sectionType: Section): boolean => _.includes(sectionType)(sections)
);

const canEdition = (editions: Edition[]): ((editionType: Edition) => boolean) => (
  (editionType: Edition): boolean => _.includes(editionType)(editions)
);

const request = (role: Role): ((requests: Request[]) => (requesType: Request) => boolean) => (
  (requests: Request[]): ((requestType: Request) => boolean) => (
    (requestType: Request): boolean => isAdmin(role) || canRequest(requests)(requestType)
  )
);

const section = (role: Role): ((sections: Section[]) => (sectionType: Section) => boolean) => (
  (sections: Section[]): ((sectionType: Section) => boolean) => (
    (sectionType: Section): boolean => isAdmin(role) || canSection(sections)(sectionType)
  )
);

const edit = (role: Role): ((editions: Edition[]) => (editionType: Edition) => boolean) => (
  (editions: Edition[]): ((editionType: Edition) => boolean) => (
    (editionType: Edition): boolean => isAdmin(role) || canEdition(editions)(editionType)
  )
);

const editable = (role: Role): ((editions: Edition[]) => boolean) => (
  // @ts-ignore
  (editions: Edition[]): boolean => _.flow(
    _.map((editionType: Edition) => edit(role)(editions)(editionType)),
    _.compact,
    _.size,
    _.gt(_, 0),
  )(EDITION)
);

const requestable = (role: Role): ((requests: Request[]) => boolean) => (
  // @ts-ignore
  (requests: Request[]): boolean => _.flow(
    _.map((requestType: Request) => request(role)(requests)(requestType)),
    _.compact,
    _.size,
    _.gt(_, 0),
  )(REQUESTS)
);

const createManager = (manager = defaultmanager): IManager => ({
  ...manager,
  edit: edit(manager.role)(manager.edition),
  request: request(manager.role)(manager.requests),
  section: section(manager.role)(manager.sections),
  editable: editable(manager.role)(manager.edition),
  requestable: requestable(manager.role)(manager.requests),
  admin: isAdmin(manager.role),
});

export type { Manager, IManager, Request as RequestTypes };

export {
  createManager,
  defaultmanager,
  REQUESTS,
  ROLES,
  SECTIONS,
  EDITION,
  CONTACTS,
};
