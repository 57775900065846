import { Distributor } from 'models/distributor/types';

const TENSIONS = [
  'BT',
  'MT',
  'AT1',
] as const;

type Tension = typeof TENSIONS[number];

interface ScheduledCut {
  id?: number;
  createdAt?: string;
  start: string;
  end: string;
  tension: Tension;
  description: string;
  notify: boolean;

  municipalities: string[];
  transformationCenters: string[];
  lines: string[];

  distributor?: Distributor;
  distributorID: number;
}

export { TENSIONS };

export type { ScheduledCut, Tension };
