import { useState } from 'react';
import _ from 'lodash/fp';

const useActiveIndex = (): [_.LodashIsEqual1x1, (id: number) => void] => {
  const [index, activate] = useState(0);

  const equal = _.isEqual(index);

  const activateIndex = (id: number) => {
    activate(equal(id) ? 0 : id);
  };

  return [equal, activateIndex];
};

export default useActiveIndex;
