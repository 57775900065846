import { Thunk, thunk } from 'easy-peasy';
import { API, handleResponse } from 'libs';
import { ChangeStateParams } from 'models';

import managers from '../../managers';

interface RequestProposalModel {
  create: Thunk<RequestProposalModel, ChangeStateParams>;
  deny: Thunk<RequestProposalModel, ChangeStateParams>;
}

const requestProposalModel: RequestProposalModel = {
  create: thunk(async (_actions, payload, { getStoreActions }) => {
    const url = `/requests/${payload.requestID}/proposal/${payload.id}`;
    const response = await API.post(managers)(url, payload.params);
    // @ts-ignore
    const { initCurrent: init } = getStoreActions().requests;
    return handleResponse(response, init);
  }),

  deny: thunk(async (_actions, payload, { getStoreActions }) => {
    const url = `/requests/${payload.requestID}/proposal/${payload.id}/deny`;
    const response = await API.post(managers)(url, payload.params);
    // @ts-ignore
    const { initCurrent: init } = getStoreActions().requests;
    return handleResponse(response, init);
  }),
};

export type { RequestProposalModel };

export default requestProposalModel;
