import {
  Action,
  Thunk,
  action,
  thunk,
} from 'easy-peasy';
import {
  API,
  DOCUMENTS,
  handleResponse,
  momentum,
} from 'libs';
import {
  AppDocument,
  ID,
  IRequest,
  NewRequest,
  Request,
  RequestTypes,
  createRequest,
} from 'models';
import { saveAs } from 'file-saver';
import _ from 'lodash/fp';
import { Moment } from 'moment';

import managers from '../../managers';

import { Filter } from './types';

const DEFAULT_FILTER: Filter = {
  distributor: '',
  type: '',
  states: [],
  end: [null, null],
  create: [null, null],
  status: '',
};

const INITIAL_FILTER: Filter = {
  distributor: '',
  type: '',
  states: [],
  end: [null, null],
  create: [null, null],
  status: 'ACTIVE',
};

interface RequestModel {
  items: IRequest[];
  current: IRequest;
  selection: RequestTypes[];
  currentDate: Moment;
  view: 'month' | 'week';
  filter: Filter;

  init: Action<RequestModel, Request[]>;
  initCurrent: Action<RequestModel, Request>;
  setSelection: Action<RequestModel, RequestTypes[]>;
  setCurrentDate: Action<RequestModel, Moment>;
  setView: Action<RequestModel, 'month' | 'week'>;
  setFilter: Action<RequestModel, Filter>;

  index: Thunk<RequestModel>;
  unfinished: Thunk<RequestModel>;
  show: Thunk<RequestModel, ID>;
  create: Thunk<RequestModel, NewRequest>;
  download: Thunk<RequestModel, AppDocument>;
}

const requestModel: RequestModel = {
  items: [],
  current: createRequest(),
  selection: [],
  currentDate: momentum(),
  view: 'month',
  filter: INITIAL_FILTER,

  init: action((state, payload) => {
    const requests = _.map(createRequest)(payload);
    state.items = requests;
  }),

  initCurrent: action((state, payload) => {
    state.current = createRequest(payload);
  }),

  setSelection: action((state, payload) => {
    state.selection = payload;
  }),

  setCurrentDate: action((state, payload) => {
    state.currentDate = payload;
  }),

  setView: action((state, payload) => {
    state.view = payload;
  }),

  setFilter: action((state, payload) => {
    state.filter = payload;
  }),

  index: thunk(async (actions, _payload) => {
    const response = await API.get(managers)('/requests');
    return handleResponse(response, actions.init);
  }),

  unfinished: thunk(async (actions, _payload) => {
    const response = await API.get(managers)('/requests/unfinished');
    return handleResponse(response, actions.init);
  }),

  show: thunk(async (actions, payload) => {
    const response = await API.get(managers)(`/requests/${payload}`);
    return handleResponse(response, actions.initCurrent);
  }),

  create: thunk(async (actions, payload) => {
    const response = await API.post(managers)(payload.uri, payload.request);
    return handleResponse(response, actions.download);
  }),

  download: thunk(async (_actions, params) => {
    const file = await DOCUMENTS.base64ToPDF(params.base64, params.filename);

    saveAs(file);
  }),
};

export { DEFAULT_FILTER };

export type { RequestModel, Filter };

export default requestModel;
