import { FunctionComponent, ReactNode } from 'react';
import { classNames } from 'libs';

interface Props {
  children: ReactNode;
  className?: string;
  tag?: keyof Pick<JSX.IntrinsicElements, 'span' | 'p' | 'h4' | 'h5' | 'h6'>;
}

const ButtonText: FunctionComponent<Props> = (
  {
    children,
    className = '',
    tag: Tag = 'span',
  }: Props,
) => (
  <Tag className={classNames('text-s-base-b xl:text-l-base-b', className)}>
    {children}
  </Tag>
);

export default ButtonText;
