import { FunctionComponent, ReactNode } from 'react';
import _ from 'lodash/fp';
import classnames from 'classnames';
import { classNames } from 'libs';

import dropdown from './components/dropdown.png';
import { FieldProps, Option, SelectProps } from './types';
import FormGroup from './components/FormGroup';
import Label from './components/Label';
import InputError from './components/InputError';
import { TYPOGRAPHY } from './styles';

interface Props extends SelectProps, FieldProps {
  options?: Option[];
  helper?: string | ReactNode;
  maxLength?: number;
}

const SelectFieldID: FunctionComponent<Props> = (
  {
    id,
    name,
    readOnly = false,
    disabled = false,
    required = false,
    className = 'mb-8',
    value,
    placeholder,
    onChange,
    onBlur,
    arrow = '',
    touched = false,
    error = '',
    options,
    helper,
    maxLength = 35,
  },
) => {
  const hasValue = !_.isEqual('', value);
  const hasError = touched && !_.isEmpty(error);

  const FIELD = classnames(classNames('border', className), {
    'border-content-disabled': disabled,
    'border-danger-400': hasError,
    'border-divider-200': !hasValue && !hasError,
    'border-content': hasValue && !hasError,
  });

  const LABEL = classnames('text-s-sm bg-backdrop-primary absolute px-1.5 -top-2 left-3 z-[5] max-w-[calc(100%-24px)]', {
    hidden: !hasValue,
    'text-content-disabled': disabled,
    'text-content': _.isEmpty(error),
    'text-danger-400': hasError && !disabled,
  });

  const INPUT = classnames('appearance-none cursor-pointer disabled:cursor-not-allowed disabled:text-content-disabled w-full block px-4 py-3', {
    'text-content': hasValue,
    'text-content-inactive': !hasValue,
  });

  const ERROR = classnames({
    hidden: !touched || disabled,
    block: hasError,
  });

  const HELPER = classnames(classNames(TYPOGRAPHY, 'pr-4 pt-3'), {
    'text-content': hasValue && !disabled,
    'text-content-inactive': !hasValue && !disabled,
    'text-content-disabled': disabled,
  });

  const labeled = (labeledValue: string) => (
    _.size(labeledValue) > maxLength ? `${labeledValue.substring(0, maxLength)}...` : labeledValue
  );

  return (
    <FormGroup className={FIELD}>
      <Label
        value={placeholder}
        htmlFor={id}
        className={LABEL}
        required={required}
      />
      <div className="flex items-start">
        <select
          id={id}
          name={name}
          value={value}
          onChange={readOnly ? undefined : onChange}
          onBlur={onBlur}
          disabled={disabled}
          className={INPUT}
          autoComplete="none"
          style={{
            backgroundImage: `url(${_.isEmpty(arrow) ? dropdown : arrow})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: '98%',
            backgroundColor: 'transparent',
            backgroundSize: '1.5rem',
          }}
        >
          <option value="">
            {`${placeholder} ${required ? '*' : ''}`}
          </option>
          {_.map((option: Option) => (
            <option key={option.id} value={option.id}>
              {option.id === value ? labeled(option.label) : option.label}
            </option>
          ))(options)}
        </select>
        {!_.isUndefined(helper) && (
          <div>
            {typeof helper === 'string' ? (
              <p className={HELPER}>
                {helper}
              </p>
            ) : helper}
          </div>
        )}
      </div>
      <InputError error={error} className={ERROR} />
    </FormGroup>
  );
};

export default SelectFieldID;
