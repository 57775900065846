type Setting = {
  id: number;
  createdAt?: string;
  key: string;
  value: string;
};

type ContactNumber = Setting & {
  schedule: string;
};

const COLORS = [
  'primary',
  'primary-negative',
  'shade-100',
  'shade-300',
  'shade-500',
  'divider-400',
  'divider-200',
  'divider-negative',
  'backdrop-primary',
  'backdrop-secondary',
  'backdrop-disabled',
  'header',
  'header-negative',
  'footer',
  'footer-negative',
  'content',
  'interaction',
  'content-secondary',
  'content-negative',
  'content-negative-secondary',
  'content-inactive',
  'content-disabled',
] as const;

type Color = typeof COLORS[number];

const TEXTS = [
  'banner',
  'responsible',
  'drop_out',
  'register_check',
  'request_check',
  'contact_check',
  'incident_check',
  'fraud_check',
  'authorized_check',
  'contract_check',
] as const;

type Text = typeof TEXTS[number];

const LINKS = [
  'twitter',
  'linkedin',
  'facebook',
  'instagram',
  'web',
] as const;

type Link = typeof LINKS[number];

const CONTACT_NUMBERS = [
  'selfconsumption',
  'breakdowns_and_cuts',
  'other_queries',
] as const;

type Contact = typeof CONTACT_NUMBERS[number];

export {
  COLORS,
  TEXTS,
  LINKS,
};

export type {
  Setting,
  Color,
  Text,
  Link,
  Contact,
  ContactNumber,
};
