import { momentum, t } from 'libs';

import { NonPaymentCut, INonPaymentCut } from './types';

const setDate = (value: string): string => {
  const date = momentum(value);

  if (date.year() === 0) {
    return '-';
  }

  return date.format('DD/MM/YYYY HH:mm');
};

const setLast = (npc: NonPaymentCut): string => {
  const reconnection = momentum(npc.reconnectionDate);
  const cancellation = momentum(npc.cancellationDate);
  const cut = momentum(npc.cutDate);
  const init = momentum(npc.initDate);
  const format = 'dddd, DD MMM YYYY';

  if (reconnection.year() !== 0) {
    return t('models.unpayment.reconnection_date', { date: reconnection.format(format) });
  }

  if (cancellation.year() !== 0) {
    return t('models.unpayment.cancellation_date', { date: cancellation.format(format) });
  }

  if (cut.year() !== 0) {
    return t('models.unpayment.cut_date', { date: cut.format(format) });
  }

  return t('models.unpayment.init_date', { date: init.format(format) });
};

const createNonPaymentCut = (nonPaymentCut: NonPaymentCut): INonPaymentCut => ({
  ...nonPaymentCut,
  init: setDate(nonPaymentCut.initDate),
  cut: setDate(nonPaymentCut.cutDate),
  cancellation: setDate(nonPaymentCut.cancellationDate),
  reconnection: setDate(nonPaymentCut.reconnectionDate),
  last: setLast(nonPaymentCut),
});

export type { NonPaymentCut, INonPaymentCut };

export default createNonPaymentCut;
