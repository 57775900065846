import _ from 'lodash/fp';
import { AppDocument } from 'models';

const MB_TO_BYTE = 1048576;

const documentName = (filename: string): string => {
  const array = _.split('.')(filename);
  return _.slice(0)(_.size(array) - 1)(array).join('.');
};

const setBase64 = (b64URI: string): string => (
  !_.isEmpty(b64URI) ? _.split(',')(b64URI)[1] : ''
);

const base64ToPDF = async (base64: string, filename: string): Promise<File> => {
  const response = await fetch(`data:application/pdf;base64,${base64}`);
  const buffer = await response.arrayBuffer();

  return new File([buffer], filename, { type: 'application/pdf' });
};

const tooBig = (size: number, max: number): boolean => _.gt(size)(max * MB_TO_BYTE);

const readFile = async (file: File, doc: AppDocument): Promise<AppDocument> => (
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onerror = reject;
    reader.onloadend = () => {
      const result = reader.result as string;
      doc.base64 = setBase64(result);
      doc.url = result;
      doc.filename = file.name;
      resolve(doc);
    };
  })
);

const readFiles = async (
  files: FileList,
  doc: AppDocument,
): Promise<AppDocument[]> => (
  Promise.all(Array.from(files).map(async (file: File) => readFile(file, { ...doc })))
);

const DOCUMENTS = {
  tooBig,
  readFile,
  readFiles,
  base64ToPDF,
  documentName,
};

export default DOCUMENTS;
