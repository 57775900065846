import { FunctionComponent } from 'react';
import _ from 'lodash/fp';
import classnames from 'classnames';
import { classNames } from 'libs';
import { Icon } from 'components/icons';

import { FieldProps, SearchProps } from './types';
import FormGroup from './components/FormGroup';
import Label from './components/Label';
import Input from './components/Input';
import InputError from './components/InputError';

interface Props extends SearchProps, FieldProps {}

const SearchField: FunctionComponent<Props> = (
  {
    id,
    name,
    readOnly = false,
    disabled = false,
    required = false,
    className = '',
    value,
    placeholder,
    onChange,
    onBlur,
    touched = false,
    error = '',
  }: Props,
) => {
  const hasValue = !_.isEmpty(value);
  const hasError = touched && !_.isEmpty(error);

  const FIELD = classnames(classNames('border', className), {
    'border-content-disabled': disabled,
    'border-danger-400': hasError,
    'border-divider-200': !hasValue && !hasError,
    'border-content': hasValue && !hasError,
  });

  const LABEL = classnames('text-s-sm bg-backdrop-primary absolute px-1.5 -top-2 left-3 z-[5] max-w-[calc(100%-24px)]', {
    hidden: !hasValue,
    'text-content-disabled': disabled,
    'text-content': _.isEmpty(error),
    'text-danger-400': hasError && !disabled,
  });

  const INPUT = classnames('w-full block px-4 py-3 text-content placeholder:text-content-inactive placeholder:disabled:text-content-disabled disabled:text-content-disabled', {
    'cursor-not-allowed': disabled || readOnly,
  });

  const ERROR = classnames({
    hidden: !touched || disabled,
    block: hasError,
  });

  return (
    <FormGroup className={FIELD}>
      <Label
        value={placeholder}
        htmlFor={id}
        className={LABEL}
        required={required}
      />
      <div className="flex items-start">
        <Input
          id={id}
          type="search"
          name={name}
          placeholder={`${placeholder}${required ? '*' : ''}`}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          readOnly={readOnly}
          disabled={disabled}
          required={required}
          className={INPUT}
        />
        <div className="pt-3 pr-4">
          <Icon name="search" className="text-content-interaction" />
        </div>
      </div>
      <InputError error={error} className={ERROR} />
    </FormGroup>
  );
};

export default SearchField;
