import { useManagerState } from 'managers-app/store';
import { FunctionComponent } from 'react';
import _ from 'lodash';
import { WithNavigation } from 'layouts/headers';

const Navigation: FunctionComponent = () => {
  const title = useManagerState((state) => state.app.title);
  const back = useManagerState((state) => state.app.back);
  const close = useManagerState((state) => state.app.close);
  const backButton = typeof back !== 'string';
  const hasBack = !_.isEmpty(back) || !_.isUndefined(back);
  const hasClose = !_.isEmpty(close) && !_.isEqual(close, back);

  return (
    <WithNavigation
      title={title}
      back={back}
      close={close}
      backButton={backButton}
      hasBack={hasBack}
      hasClose={hasClose}
    />
  );
};

export default Navigation;
