import { AppDocument, RequestDocuments, buildDocument } from 'models/document';
import _ from 'lodash/fp';
import { Client, defaultClient } from 'models/client';
import { momentum } from 'libs';

import {
  ClientData,
  RequestData,
  AddressData,
  RegularInstallationData,
  ConsumptionData,
  SelfconsumptionData,
  SpecsData,
  CommonData,
  SupplyPointRequest,
  SupplyPointCupsRequest,
  CupsRequest,
  CauRequest,
  SelfconsumptionRequest,
  GenerationRequest,
  RequestStep,
  Historical,
  Request,
  DefaultParams,
  NewRequest,
  IRequest,
  REQUEST_STATES,
  REQUEST_STEPS,
  LITE_STATES,
  LITE_STEPS,
  RequestStates,
  RequestSteps,
  IHistoricals,
  ChangeStateParams,
  RequestParams,
  Annotation,
} from './types';

const clientData: ClientData = {
  clientType: '',
  clientName: '',
  clientIdentityNumber: '',
  clientEmail: '',
  clientPhoneNumber: '',
  clientAddress: '',
  clientPostalCode: '',
  clientMunicipality: '',
  clientProvince: '',
};

const CLIENT_DATA = _.keysIn(clientData);

const requestData: RequestData = {
  municipality: '',
  province: '',
  terrain: '',
};

const REQUEST_DATA = _.keysIn(requestData);

const addressData: AddressData = {
  address: '',
  postalCode: '',
};

const ADDRESS_DATA = _.keysIn(addressData);

const regularInstallationData: RegularInstallationData = {
  maxInstallationPower: '',
  tension: '',
  use: '',
  useDescription: '',
  installationType: '',
};

const REGULAR_INSTALLATION_DATA = _.keysIn(regularInstallationData);

const consumptionData: ConsumptionData = {
  tension: '',
  installationPower: '',
  primaryPowerSource: '',
  primaryPowerSourceDescription: '',
};

const CONSUMPTION_DATA = _.keysIn(consumptionData);

const selfconsumptionData: SelfconsumptionData = {
  selfconsumptionType: '',
  connectionType: '',
  dischargedIntoNetwork: false,
  admitedCompensation: false,
};

const SELFCONSUMPTION_DATA = ['selfconsumptionType', 'connectionType'];

const specsData: SpecsData = {
  hibridization: false,
  hibridizationDescription: '',
  accumulation: false,
  accumulationDescription: '',
  environmentalImpactEvaluation: false,
  amountOfGuarantee: '',
  node: '',
};

const commonData = (companyID: number, clientID: number): CommonData => ({
  observations: '',
  clientID,
  companyID,
  distributorID: 0,
  acceptance: false,
});

const COMMON_DATA = _.keysIn(commonData(0, 0));

const defaultSupplyPointCups = (params: DefaultParams): SupplyPointCupsRequest => ({
  ...clientData,
  ...requestData,
  ...commonData(params.companyID, params.clientID),
  ...addressData,
  ...regularInstallationData,
  cadastralReference: '',
  technicalReport: buildDocument('technical-report', params.container),
  project: buildDocument('project', params.container),
  charges: buildDocument('charges', params.container),
  authorization: buildDocument('authorization', params.container),
});

const defaultSupplyPoint = (params: DefaultParams): SupplyPointRequest => ({
  ...clientData,
  ...requestData,
  ...commonData(params.companyID, params.clientID),
  ...addressData,
  ...regularInstallationData,
  cadastralReference: '',
  technicalReport: buildDocument('technical-report', params.container),
  technicalBlueprint: buildDocument('technical-blueprint', params.container),
  authorization: buildDocument('authorization', params.container),
});

const defaultCups = (params: DefaultParams): CupsRequest => ({
  ...clientData,
  ...requestData,
  ...commonData(params.companyID, params.clientID),
  ...addressData,
  ...regularInstallationData,
  cadastralReference: '',
  installationBulletin: buildDocument('installation-bulletin', params.container),
  license: buildDocument('license', params.container),
  ownership: buildDocument('ownership', params.container),
  authorization: buildDocument('authorization', params.container),
});

const defaultCau = (companyID: number, clientID: number): CauRequest => ({
  ...requestData,
  ...commonData(companyID, clientID),
  ...addressData,
  ...consumptionData,
  ...selfconsumptionData,
  cups: '',
});

const defaultSelfconsumption = (params: DefaultParams): SelfconsumptionRequest => ({
  ...clientData,
  ...requestData,
  ...commonData(params.companyID, params.clientID),
  ...addressData,
  ...consumptionData,
  ...selfconsumptionData,
  ...specsData,
  cadastralReference: '',
  cups: '',
  hiredPotency: '',
  technicalReport: buildDocument('technical-report', params.container),
  technicalBlueprint: buildDocument('technical-blueprint', params.container),
  warranty: buildDocument('warranty', params.container),
  environmentalImpactReceipt: buildDocument('environmental-impact-receipt', params.container),
  constitutionOfGuarantee: buildDocument('constitution-of-guarantee', params.container),
  authorization: buildDocument('authorization', params.container),
});

const defaultGeneration = (params: DefaultParams): GenerationRequest => ({
  ...clientData,
  ...requestData,
  ...commonData(params.companyID, params.clientID),
  ...consumptionData,
  ...specsData,
  cadastralReference: '',
  technicalBlueprint: buildDocument('technical-blueprint', params.container),
  warranty: buildDocument('warranty', params.container),
  environmentalImpactReceipt: buildDocument('environmental-impact-receipt', params.container),
  constitutionOfGuarantee: buildDocument('constitution-of-guarantee', params.container),
  T243: buildDocument('T243', params.container),
  generalSituationPlan: buildDocument('general-situation-plan', params.container),
  privateLocationPlan: buildDocument('private-location-plan', params.container),
  significantDistances: buildDocument('significant-distances', params.container),
  generalImplementationPlan: buildDocument('general-implementation-plan', params.container),
  generalImplementationPlanDWG: buildDocument('general-implementation-plan-dwg', params.container),
  authorization: buildDocument('authorization', params.container),
});

const defaultRequest: Request = {
  id: 0,
  type: 'CAU',
  authomatism: false,
  code: '',
  power: '',
  address: '',
  totalDays: 0,
  finishDate: '',
  state: {
    name: '',
    clientDescription: 'Analizando documentación',
    clientLimit: '',
    managerDescription: 'Analizando documentación',
    managerLimit: '',
    responsable: '',
  },
  metadata: {
    daysLeft: 0,
    distributorWorks: false,
    clientWorks: false,
    workRequirements: [],
    requirements: [],
    workTime: '',
    works: false,
    distributorWorksFinish: false,
    clientWorksFinish: false,
  },
  documents: [],
  steps: [],
  historicals: [],
  clientData: null,
  distributor: {
    id: 0,
    name: '',
    document: buildDocument('capacidad-nodal', ''),
  },
  client: defaultClient,
};

const getDocument = (documents: AppDocument[]): ((docType: RequestDocuments) => string) => (
  (docType: RequestDocuments): string => _.flow(
    _.find(['docType', docType]),
    _.get('url'),
  )(documents)
);

const defaultHistorical: Historical = {
  id: 0,
  type: 'CAU',
  limitDate: '',
  finishDate: '',
  daysToLimit: 0,
  totalDays: 0,
  stepName: 'Documentación',
  stepDescription: 'Analizando documentación',
  metadata: {
    responsable: 'Distribuidora',
    requirements: [],
    workRequirements: [],
    workTime: '',
    distributorWorks: false,
    clientWorks: false,
    accepted: false,
    date: '',
    times: [],
    presence: false,
    cups: [],
  },
  documents: [],
};

const byStep = (historicals: Historical[]): ((step: RequestSteps) => Historical[]) => (
  // @ts-ignore
  (step: RequestSteps): Historical[] => _.flow(
    _.filter((h: Historical) => _.isEqual(h.stepName, step)),
    _.orderBy(['id'], ['desc']),
  )(historicals)
);

const clientWorks = (historicals: Historical[]): (() => Historical[]) => (
  // @ts-ignore
  (): Historical[] => _.flow(
    _.filter((h: Historical) => _.isEqual(h.stepName, 'Obras') && h.metadata.clientWorks),
    _.orderBy(['id'], ['desc']),
  )(historicals)
);

const distributorWorks = (historicals: Historical[]): (() => Historical[]) => (
  // @ts-ignore
  (): Historical[] => _.flow(
    _.filter((h: Historical) => _.isEqual(h.stepName, 'Obras') && h.metadata.distributorWorks),
    _.orderBy(['id'], ['desc']),
  )(historicals)
);

const finishStepDate = (historicals: Historical[]): ((step: RequestSteps) => string) => (
  (step: RequestSteps): string => {
    const stepHistoricals = _.filter((h: Historical) => _.isEqual(h.stepName, step))(historicals);
    if (_.isEmpty(stepHistoricals)) {
      return '';
    }

    return _.flow(
      _.orderBy(['id'], ['desc']),
      _.first,
      _.get('finishDate'),
    )(stepHistoricals);
  }
);

const finishWorksStepDate = (historicals: Historical[]): (() => string) => (
  (): string => {
    const finishDistributorWorks = _.flow(
      _.first,
      _.get('finishDate'),
    )(distributorWorks(historicals)());
    const finishClientWorks = _.flow(
      _.first,
      _.get('finishDate'),
    )(clientWorks(historicals)());

    if (!_.isEmpty(finishDistributorWorks) && momentum(finishDistributorWorks).year() === 0) {
      return finishDistributorWorks;
    }

    if (!_.isEmpty(finishClientWorks) && momentum(finishClientWorks).year() === 0) {
      return finishClientWorks;
    }

    const difference = momentum(finishDistributorWorks).diff(finishClientWorks, 'days');

    if (difference > 0) {
      return finishDistributorWorks;
    }
    return finishClientWorks;
  }
);

const limitStepDate = (historicals: Historical[]): ((step: RequestSteps) => string) => (
  (step: RequestSteps): string => {
    const stepHistoricals = _.filter((h: Historical) => _.isEqual(h.stepName, step))(historicals);
    if (_.isEmpty(stepHistoricals)) {
      return '';
    }
    return _.flow(
      _.filter((h: Historical) => _.isEqual(h.stepName, step)),
      _.orderBy(['id'], ['desc']),
      _.first,
      _.get('limitDate'),
    )(stepHistoricals);
  }
);

const limitWorksStepDate = (historicals: Historical[]): (() => string) => (
  (): string => {
    const limitDistributorWorks = _.flow(
      _.first,
      _.get('limitDate'),
    )(distributorWorks(historicals)());

    const limitClientWorks = _.flow(
      _.first,
      _.get('limitDate'),
    )(clientWorks(historicals)());

    if (!_.isEmpty(limitClientWorks)
      && !_.isUndefined(limitClientWorks)
      && momentum(limitClientWorks).year() === 0) {
      return limitClientWorks;
    }

    if (!_.isEmpty(limitDistributorWorks)
    && !_.isUndefined(limitDistributorWorks)
    && momentum(limitDistributorWorks).year() === 0) {
      return limitDistributorWorks;
    }

    const differenceDistributor = momentum(limitDistributorWorks).diff(momentum(), 'days');
    const differenceClient = momentum(limitClientWorks).diff(momentum(), 'days');

    if (differenceDistributor > differenceClient) {
      return limitDistributorWorks;
    }
    return limitClientWorks;
  }
);

const firstHistorical = (historicals: Historical[]): (() => Historical) => (
  (): Historical => {
    if (_.isEmpty(historicals)) return defaultHistorical;
    // @ts-ignore
    return _.flow(
      _.orderBy(['id'], ['asc']),
      _.first,
    )(historicals);
  }
);

const current = (historicals: Historical[]): (() => Historical) => (
  (): Historical => {
    // @ts-ignore
    const historical: Historical = _.flow(
      _.orderBy(['id'], ['desc']),
      _.first,
    )(historicals);

    if (_.isUndefined(historical)) return defaultHistorical;
    return historical;
  }
);

const cover = (historicals: Historical[]): ((step: RequestSteps) => boolean) => (
  // @ts-ignore
  (step: RequestSteps): boolean => _.flow(
    _.filter((h: Historical) => _.isEqual(h.stepName, step)),
    _.filter((h: Historical) => _.isEqual(h.stepDescription, 'Documentación requerida')),
    _.size,
    // @ts-ignore
    _.gte(_, 2),
  )(historicals)
);

const closestLimit = (request: Request): (() => string) => (
  (): string => {
    if (momentum(request.state.clientLimit).isSame(momentum(request.state.managerLimit), 'day')) {
      return request.state.clientLimit;
    }

    const limitClient = momentum(request.state.clientLimit).diff(momentum(), 'days');
    const limitDistributor = momentum(request.state.managerLimit).diff(momentum(), 'days');

    if (request.state.managerDescription === 'Obras finalizadas') {
      return request.state.clientLimit;
    }

    if (request.state.clientDescription === 'Obras finalizadas') {
      return request.state.managerLimit;
    }

    if (momentum(request.state.clientLimit).year() === 0) {
      return request.state.managerLimit;
    }

    if (momentum(request.state.managerDescription).year() === 0) {
      return request.state.managerLimit;
    }

    if (limitDistributor === 0) {
      return request.state.clientLimit;
    }

    if (limitClient === 0) {
      return request.state.managerLimit;
    }

    if (limitClient > limitDistributor) {
      return request.state.managerLimit;
    }

    return request.state.clientLimit;
  }
);

const status = (request: Request): (() => RequestStates) => (
  (): RequestStates => {
    if (_.isEqual(request.state.clientDescription, request.state.managerDescription)) {
      return request.state.clientDescription;
    }

    const limitClient = momentum(request.state.clientLimit).diff(momentum(), 'days');
    const limitDistributor = momentum(request.state.managerLimit).diff(momentum(), 'days');

    if (request.state.clientDescription === 'Obras finalizadas') {
      return request.state.managerDescription;
    }

    if (request.state.managerDescription === 'Obras finalizadas') {
      return request.state.clientDescription;
    }

    if (momentum(request.state.clientLimit).year() === 0) {
      return request.state.managerDescription;
    }

    if (momentum(request.state.managerLimit).year() === 0) {
      return request.state.clientDescription;
    }

    if (limitDistributor === 0) {
      return request.state.clientDescription;
    }

    if (limitClient === 0) {
      return request.state.managerDescription;
    }

    if (limitClient > limitDistributor) {
      return request.state.managerDescription;
    }

    return request.state.clientDescription;
  }
);

const createHistoricals = (historicals: Historical[] = []): IHistoricals => ({
  items: historicals,
  byStep: byStep(historicals),
  finishStepDate: finishStepDate(historicals),
  limitStepDate: limitStepDate(historicals),
  finishWorksStepDate: finishWorksStepDate(historicals),
  limitWorksStepDate: limitWorksStepDate(historicals),
  clientWorks: clientWorks(historicals),
  distributorWorks: distributorWorks(historicals),
  current: current(historicals),
  first: firstHistorical(historicals),
});

const userName = (cd: ClientData | null, client: Client | null): string => {
  if (_.isNil(cd)) {
    return client?.name || '';
  }
  return cd.clientName;
};

const userID = (cd: ClientData | null, client: Client | null): string => {
  if (_.isNil(cd)) {
    return client?.identityNumber || '';
  }
  return cd.clientIdentityNumber;
};

const createRequest = (request: Request = defaultRequest): IRequest => ({
  ...request,
  userName: userName(request.clientData, request.client),
  userID: userID(request.clientData, request.client),
  clientToLimit: Math.ceil(momentum(request.state.clientLimit).diff(momentum(), 'hours') / 24),
  managerToLimit: Math.ceil(momentum(request.state.managerLimit).diff(momentum(), 'hours') / 24),
  finished: momentum(request.finishDate).year() > 1,
  closestLimit: closestLimit(request),
  status: status(request),
  document: getDocument(request.documents),
  history: createHistoricals(request.historicals),
  cover: cover(request.historicals),
  hasWorks: request.metadata.clientWorks && request.metadata.distributorWorks,
});

const buildClientRequestParams = (responsable: string): RequestParams => ({
  clientComment: '',
  documents: [],
  abbreviated: false,
  days: 0,
  responsable,
  type: '',
  upstream: undefined,
});

const buildManagerRequestParams = (responsable: string, comment = ''): RequestParams => ({
  managerComment: comment,
  documents: [],
  abbreviated: false,
  days: 0,
  responsable,
  type: '',
  upstream: undefined,
});

const clientRequest = (request: IRequest): IRequest => createRequest({
  ...request,
  state: {
    name: request.state.name,
    clientDescription: request.state.clientDescription,
    managerDescription: request.state.clientDescription,
    clientLimit: request.state.clientDescription === 'Obras finalizadas' ? '-' : request.state.clientLimit,
    managerLimit: request.state.clientDescription === 'Obras finalizadas' ? '-' : request.state.clientLimit,
    responsable: request.state.responsable,
  },
});

const managerRequest = (request: IRequest): IRequest => createRequest({
  ...request,
  state: {
    name: request.state.name,
    clientDescription: request.state.managerDescription,
    managerDescription: request.state.managerDescription,
    clientLimit: request.state.managerDescription === 'Obras finalizadas' ? '-' : request.state.managerLimit,
    managerLimit: request.state.managerDescription === 'Obras finalizadas' ? '-' : request.state.managerLimit,
    responsable: request.state.responsable,
  },
});

const duplicate = _.flow(
  _.map((request: IRequest): IRequest | IRequest[] => (
    request.metadata.clientWorks && request.metadata.distributorWorks
    && request.state.name === 'Obras'
    && request.state.managerDescription !== 'Empezar obras en la red'
    && request.state.managerDescription !== 'Obras finalizadas'
    && (request.state.clientDescription === 'Analizando documentación'
    || request.state.clientDescription === 'Analizando cesión'
    || request.state.clientDescription === 'Analizando autorización'
    || (
      request.state.clientDescription === 'Autorización de la administración'
        && request.state.responsable !== 'Tú'))
      ? [clientRequest(request), managerRequest(request)]
      : request
  )),
  _.flatten,
);

const duplicateAll = _.flow(
  _.map((request: IRequest): IRequest | IRequest[] => (
    request.state.name === 'Obras'
    && request.metadata.clientWorks
    && request.metadata.distributorWorks
      ? [clientRequest(request), managerRequest(request)]
      : request
  )),
  _.flatten,
);

export {
  defaultCau,
  defaultCups,
  defaultGeneration,
  defaultSelfconsumption,
  defaultSupplyPoint,
  defaultSupplyPointCups,
  CLIENT_DATA,
  REQUEST_DATA,
  ADDRESS_DATA,
  REGULAR_INSTALLATION_DATA,
  CONSUMPTION_DATA,
  SELFCONSUMPTION_DATA,
  COMMON_DATA,
  createRequest,
  defaultRequest,
  REQUEST_STATES,
  REQUEST_STEPS,
  LITE_STATES,
  LITE_STEPS,
  createHistoricals,
  buildClientRequestParams,
  buildManagerRequestParams,
  duplicate,
  duplicateAll,
};

export type {
  RequestStep,
  Historical,
  Request,
  SupplyPointRequest,
  SupplyPointCupsRequest,
  CupsRequest,
  CauRequest,
  SelfconsumptionRequest,
  GenerationRequest,
  NewRequest,
  IRequest,
  RequestStates,
  RequestSteps,
  IHistoricals,
  ChangeStateParams,
  Annotation,
  RequestParams,
};
