import { Icon, IconName } from 'components/icons';
import { useIsMatch } from 'hooks';
import { FunctionComponent } from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { Badge } from 'managers-app/components/badges';
import _ from 'lodash/fp';
import { ROUTES } from 'managers-app/libs';

interface Props {
  icon: IconName;
  label: string;
  to: string;
  number?: number;
  match?: string;
  end?: boolean;
  requests?: boolean;
}

const MenuItem: FunctionComponent<Props> = (
  {
    icon,
    label,
    to,
    number,
    match,
    end = false,
    requests = false,
  }: Props,
) => {
  const isMatch = useIsMatch(match ?? to, end);
  const isRequestsMatch = useIsMatch(ROUTES.requests.index, false) && requests;
  const LINK = classnames('flex flex-col items-center mb-8 hover:text-primary-negative', {
    'text-primary-negative': isMatch || isRequestsMatch,
    'text-content-inactive': !isMatch && !isRequestsMatch,
  });

  return (
    <Link to={to} className={LINK}>
      <Icon name={icon} className="mb-1.5" />
      <p className="text-[10px] leading-[14px] tracking-[0.04em] text-center relative">
        {label}
        {!_.isUndefined(number) && !_.isEqual(number, 0) && (
        <Badge value={number} className="absolute top-[5px] left-[calc(100%+1px)]" small />
        )}
      </p>
    </Link>
  );
};

export default MenuItem;
