import {
  FunctionComponent,
  ReactNode,
  useMemo,
  useState,
} from 'react';
import _ from 'lodash/fp';
import { Icon } from 'components/icons';
import { EmptyRow } from 'components/empties';
import { H3 } from 'components/typographies';

type ByStep = {
  step: string;
  quantity: number;
}

interface Props {
  rows: ByStep[];
  per?: number;
  children: (page: ByStep[]) => ReactNode;
  message: string;
  title: string;
}

const SimplePagination: FunctionComponent<Props> = (
  {
    rows,
    children,
    per = 3,
    message,
    title,
  }: Props,
) => {
  const [pageNumber, setPageNumber] = useState(0);
  const maxPages = Math.floor(rows.length / per);

  const page = useMemo(() => (
    rows.slice(pageNumber * per, Math.min((pageNumber + 1) * per, rows.length))
  ), [rows, per, pageNumber]);

  const onNext = (): void => {
    if (_.gte(pageNumber)(maxPages)) return;
    setPageNumber((prev) => prev + 1);
  };

  const onPrev = (): void => {
    if (_.lte(pageNumber)(0)) return;
    setPageNumber((prev) => prev - 1);
  };

  return (
    <EmptyRow empty={rows.length === 0} message={message}>
      <div className="w-full">
        <div className="flex justify-between mb-2">
          <H3>{title}</H3>
          {(rows.length > per) && (
          <div className="flex items-center justify-center">
            <button type="button" onClick={onPrev} disabled={pageNumber === 0} className="mr-8 disabled:opacity-25">
              <Icon name="angle-left" />
            </button>
            <button type="button" onClick={onNext} disabled={pageNumber === maxPages} className="ml-8 disabled:opacity-25">
              <Icon name="angle-right" />
            </button>
          </div>
          )}
        </div>
        {children(page)}
      </div>
    </EmptyRow>
  );
};

export type { ByStep };

export default SimplePagination;
