const FILTER_TAG_STYLES = {
  CUPS: 'bg-cups-400',
  CAU: 'bg-cau-400',
  SAD: 'bg-sad-400',
  SARA: 'bg-sara-400',
  SARG: 'bg-sarg-400',
  SAyC: 'bg-sadcups-400',
};

type FilterTagStyles = keyof typeof FILTER_TAG_STYLES;

export { FILTER_TAG_STYLES };

export type { FilterTagStyles };
