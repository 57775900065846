import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const useHandleRedirect = (route: string): [string, (r: string | boolean) => void] => {
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  const handleResponse = (r: string | boolean): void => {
    if (typeof r === 'string') {
      setMessage(r);
      return;
    }

    navigate(route);
  };

  return [message, handleResponse];
};

export default useHandleRedirect;
