import { useManagerActions } from 'managers-app/store';
import { useEffect } from 'react';

const useAppBack = (route?: string | (() => void)): void => {
  const setBack = useManagerActions((state) => state.app.setBack);

  useEffect(() => {
    setBack(route);
  }, [route]);
};

export default useAppBack;
