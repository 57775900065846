import { Main } from 'components/containers';
import { AppError } from 'components/errors';
import { AppLoader } from 'components/loaders';
import { StoreProvider } from 'easy-peasy';
import { useGTM, useLoadingRequest } from 'hooks';
import {
  indexed,
  layout,
  page,
  route,
  t,
} from 'libs';
import { FunctionComponent, lazy, useEffect } from 'react';
import { Outlet, RouteObject, ScrollRestoration } from 'react-router-dom';
import { usePrivateAsyncRequest } from 'managers-app/hooks';
import { Helmet } from 'react-helmet';

import { PATHS } from './libs/router';
import store, { useManagerActions, useManagerState } from './store';
import { Dashboard, Private, Public } from './layouts';
import ClientsRoutes from './views/clients/ClientsRoutes';
import ManagersRoutes from './views/managers/ManagersRoutes';
import SettingsRoutes from './views/settings/SettingsRoutes';
import AuthorizationsRoutes from './views/authorizations/AuthorizationsRoutes';
import ScheduledCutsRoutes from './views/scheduled-cuts/ScheduledCutsRoutes';

const Session = lazy(() => import('./views/Session'));
const ResetPassword = lazy(() => import('./views/passwords/ResetPassword'));
const RestorePassword = lazy(() => import('./views/passwords/RestorePassword'));
const Requests = lazy(() => import('./views/requests/Requests'));
const Calendar = lazy(() => import('./views/requests/Calendar'));
const AllRequests = lazy(() => import('./views/requests/AllRequests'));
const Request = lazy(() => import('./views/requests/Request'));
const Profile = lazy(() => import('./views/Profile'));
const Unpayments = lazy(() => import('./views/Unpayments'));

const ManagerSession: FunctionComponent = () => {
  const update = useManagerActions((actions) => actions.session.update);
  const distributors = useManagerActions((actions) => actions.distributor.index);
  const clients = useManagerActions((actions) => actions.clients.index);
  const authorizations = useManagerActions((actions) => actions.authorizations.index);
  const initAdvice = useManagerActions((actions) => actions.app.initAdvice);
  const [loading, message] = useLoadingRequest(update);
  usePrivateAsyncRequest(distributors);
  usePrivateAsyncRequest(clients);
  usePrivateAsyncRequest(authorizations);
  useEffect(() => {
    initAdvice();
  }, []);

  return (
    <AppLoader loading={loading} message={t('managers.loading')}>
      <AppError message={message}>
        <Outlet />
      </AppError>
    </AppLoader>
  );
};

const ManagersLoader: FunctionComponent = () => {
  const index = useManagerActions((actions) => actions.company.index);
  const company = useManagerState((state) => state.company.item);
  const [loading, message] = useLoadingRequest(index);
  useGTM(company.gtm);

  return (
    <AppLoader loading={loading} message={t('managers.loading')}>
      <AppError message={message}>
        <Main>
          <style>{`:root { ${company.system} }`}</style>
          <Helmet>
            <title>{company.name}</title>
            <meta name="description" content={company.name} />
            <meta property="og:title" content={company.name} />
            <meta name="og:description" content={company.name} />
            <meta property="og:image" content={company.document('brand')} />
            <link rel="icon" href={company.document('favicon')} />
          </Helmet>
          <ManagerSession />
        </Main>
      </AppError>
    </AppLoader>
  );
};

const ManagersApp: FunctionComponent = () => (
  <StoreProvider store={store}>
    <ScrollRestoration />
    <ManagersLoader />
  </StoreProvider>
);

const ManagersRouter: RouteObject[] = [
  layout(
    <ManagersApp />,
    route(
      PATHS.index,
      layout(
        <Public />,
        page(PATHS.session, <Session />),
        page(PATHS.password.reset, <ResetPassword />),
        page(PATHS.password.restore, <RestorePassword />),
      ),
      layout(
        <Private />,
        layout(
          <Dashboard />,
          layout(
            <Requests />,
            indexed(<Calendar />),
            page(PATHS.requests.index, <AllRequests />),
          ),
          route(
            PATHS.requests.index,
            page(PATHS.requests.show, <Request />),
          ),
          page(PATHS.unpayment, <Unpayments />),
          page(PATHS.profile, <Profile />),
        ),
        ...ScheduledCutsRoutes,
        ...AuthorizationsRoutes,
        ...ClientsRoutes,
        ...ManagersRoutes,
        ...SettingsRoutes,
      ),
    ),
  ),
];

export default ManagersRouter;
