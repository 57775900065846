import { FormikErrors } from 'formik';
import { AppDocument } from 'models';
import { ChangeEvent, ClipboardEvent, FocusEvent } from 'react';

interface BaseProps {
  id: string;
  name: string;
  readOnly?: boolean;
  disabled?: boolean;
  required?: boolean;
  className?: string;
}

interface InputProps extends BaseProps {
  type?: React.InputHTMLAttributes<HTMLInputElement>['type'];
  value: string
  placeholder: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
  onPaste?: (e: ClipboardEvent<HTMLInputElement>) => void;
}

interface SearchProps extends BaseProps {
  value: string
  placeholder: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
}

interface DataProps extends BaseProps {
  value: string
  placeholder: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
  list: string[];
}

interface RadioProps extends Omit<BaseProps, 'required'> {
  value: string;
  placeholder: string;
  checked: boolean;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
  description?: string;
  bold?: boolean;
}

interface CheckProps extends BaseProps {
  value: boolean;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
}

interface CheckboxProps extends BaseProps {
  value: string;
  placeholder: string;
  checked: boolean;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
}

interface TextareaProps extends BaseProps {
  value: string
  placeholder: string;
  onChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  onBlur?: (e: FocusEvent<HTMLTextAreaElement>) => void;
  maxLength?: number;
}

const acceptanceTypes = {
  pdf: ['application/pdf'],
  image: ['image/jpg', 'image/jpeg', 'image/png'],
  dwg: ['application/acad', 'image/vnd.dwg', 'image/x-dwg', ''],
  xlsx: ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'],
  docx: ['application/vnd.openxmlformats-officedocument.wordprocessingml.document'],
};

type AcceptanceType = keyof typeof acceptanceTypes;

interface FileProps extends BaseProps {
  value: AppDocument;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (field: string, value: any, shouldValidate?: boolean | undefined
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ) => Promise<void> | Promise<FormikErrors<any>>;
  onBlur?: () => void;
  accept?: AcceptanceType;
}

interface FilesProps extends BaseProps {
  values: AppDocument[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (field: string, value: any, shouldValidate?: boolean | undefined
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ) => Promise<void> | Promise<FormikErrors<any>>;
  onBlur?: () => void;
  accept?: string;
}

interface SelectProps extends BaseProps {
  value: string
  placeholder: string;
  onChange: (e: ChangeEvent<HTMLSelectElement>) => void;
  onBlur?: (e: FocusEvent<HTMLSelectElement>) => void;
  arrow?: string
}

interface LabelProps {
  value: string;
  htmlFor: string;
  className?: string;
  required?: boolean;
}

interface FieldProps {
  touched?: boolean;
  error?: string;
}

interface Option {
  id: string;
  label: string;
  description?: string;
}

export type {
  InputProps,
  CheckProps,
  SelectProps,
  LabelProps,
  FieldProps,
  TextareaProps,
  FileProps,
  DataProps,
  SearchProps,
  RadioProps,
  CheckboxProps,
  Option,
  FilesProps,
};

export { acceptanceTypes };
