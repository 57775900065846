import { Notification } from 'components/notifications';
import { ROUTES } from 'clients-app/libs';
import { useClientState } from 'clients-app/store';
import { InternalLink } from 'components/buttons';
import { useIsMatch } from 'hooks';
import { t, classNames } from 'libs';
import { FunctionComponent } from 'react';

interface Props {
  className?: string;
}

const Unverified: FunctionComponent<Props> = ({ className = '' }: Props) => {
  const user = useClientState((state) => state.session.user);
  const isMatch = useIsMatch(ROUTES.profile.index);

  return (
    <Notification content={t('clients.contracts.unverified')} styles="warning" className={classNames('mb-6 lg:mb-14', className)}>
      {user.particular && !isMatch && (
        <InternalLink
          to={`${ROUTES.profile.validation}?desde=${ROUTES.consumption.index}&titulo=${t('clients.pages.consumption')}`}
          label={t('clients.pages.validation')}
        />
      )}
      {!user.particular && !isMatch && (
        <InternalLink
          to={ROUTES.profile.index}
          label={t('clients.pages.profile')}
        />
      )}
    </Notification>
  );
};

export default Unverified;
