import { useClientState } from 'clients-app/store';
import { FunctionComponent } from 'react';
import _ from 'lodash/fp';
import { Link } from 'react-router-dom';
import { Icon } from 'components/icons';
import { ButtonText } from 'components/typographies';
import { ROUTES } from 'clients-app/libs';
import { t } from 'libs';
import { ButtonLink } from 'components/buttons';
import { SectionLoader } from 'components/loaders';
import { ErrorSection } from 'components/errors';
import { IContract } from 'models';

import { useContractsState } from './useContracts';
import ContractCard from './ContractCard';

const ContractList: FunctionComponent = () => {
  const contracts = useClientState((state) => state.contracts.items);
  const [loading, message] = useContractsState();

  return (
    <SectionLoader loading={loading}>
      <ErrorSection message={message}>
        <div className="w-full max-w-full mb-10 border-l border-divider-200">
          <div className="w-full lg:flex">
            {_.isEmpty(contracts) && (
              <Link to={ROUTES.contracts.new} className="flex flex-col items-center justify-center text-content-interaction border-r border-divider-200 h-[175px] w-full max-w-[310px] lg:w-1/2 lg:max-w-none lg:h-[200px]">
                <Icon name="plus" className="mb-6" size="large" />
                <ButtonText className="text-content-interaction">{t('clients.contracts.dont_appear')}</ButtonText>
              </Link>
            )}
            <div className="flex flex-1 pb-2 overflow-x-scroll scrollable">
              {_.map((contract: IContract) => (
                <ContractCard key={contract.id} contract={contract} />
              ))(contracts)}
            </div>
            <Link to={ROUTES.authorized.new} className="flex-col items-center justify-center hidden border-x lg:flex text-content-interaction border-divider-200 w-60">
              <Icon name="plus" className="mb-6" size="large" />
              <ButtonText className="text-content-interaction">{t('clients.pages.new_authorized')}</ButtonText>
            </Link>
          </div>
        </div>
        <div className="flex flex-col w-full lg:hidden">
          <ButtonLink
            label={t('clients.pages.new_authorized')}
            to={ROUTES.authorized.new}
            icon="plus"
            size="small"
          />
        </div>
      </ErrorSection>
    </SectionLoader>
  );
};

export default ContractList;
