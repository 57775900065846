import { Thunk, thunk } from 'easy-peasy';
import { API, handleResponse } from 'libs';
import { ChangeStateParams } from 'models';

import managers from '../../managers';

interface RequestViabilityModel {
  desestimate: Thunk<RequestViabilityModel, ChangeStateParams>;
  upstream: Thunk<RequestViabilityModel, ChangeStateParams>;
  validate: Thunk<RequestViabilityModel, ChangeStateParams>;
}

const requestViabilityModel: RequestViabilityModel = {
  desestimate: thunk(async (_actions, payload, { getStoreActions }) => {
    const url = `/requests/${payload.requestID}/viability/${payload.id}/desestimate`;
    const response = await API.post(managers)(url, payload.params);
    // @ts-ignore
    const { initCurrent: init } = getStoreActions().requests;
    return handleResponse(response, init);
  }),

  upstream: thunk(async (_actions, payload, { getStoreActions }) => {
    const url = `/requests/${payload.requestID}/viability/${payload.id}/upstream`;
    const response = await API.post(managers)(url, payload.params);
    // @ts-ignore
    const { initCurrent: init } = getStoreActions().requests;
    return handleResponse(response, init);
  }),

  validate: thunk(async (_actions, payload, { getStoreActions }) => {
    const url = `/requests/${payload.requestID}/viability/${payload.id}/validate`;
    const response = await API.post(managers)(url, payload.params);
    // @ts-ignore
    const { initCurrent: init } = getStoreActions().requests;
    return handleResponse(response, init);
  }),
};

export type { RequestViabilityModel };

export default requestViabilityModel;
