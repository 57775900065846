const NOTIFICATION_STYLES = {
  regular: 'border-notice-400 bg-notice-200',
  warning: 'border-warning-400 bg-warning-200',
  'warning-light': 'border-warning-400',
};

type NotificationStyle = keyof typeof NOTIFICATION_STYLES;

const CONTAINER = 'flex items-center px-3 pb-3 pt-7 border relative flex-col md:py-6 md:flex-row md:px-6 w-full';

export type { NotificationStyle };

export { NOTIFICATION_STYLES, CONTAINER };
