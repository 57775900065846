import { createStore, EasyPeasyConfig, Store } from 'easy-peasy';
import _ from 'lodash/fp';

import model from './models';
import { LEGAL_DOCUMENTS, LegalDocument } from './models/document';
import {
  buildScheduledParams,
  Location,
  TransformationCenter,
  ScheduledParams,
  buildScheduledCut,
} from './models/scheduled-cuts';
import { Filter, DEFAULT_FILTER } from './models/requests';
import { NameParams, PasswordParams } from './models/session';
import { useManagerState, useManagerActions } from './hooks';

const buildClientStore = (overrideConfig: EasyPeasyConfig = {}): Store<typeof model> => {
  const defaultConfig = {
    injections: {},
  };

  const config = _.defaultsDeep(defaultConfig, overrideConfig);

  if (process.env.NODE_ENV === 'development') {
    return createStore(model, {
      devTools: true,
      ...config,
    });
  }

  return createStore(model, config);
};

export {
  useManagerState,
  useManagerActions,
  LEGAL_DOCUMENTS,
  buildScheduledParams,
  buildScheduledCut,
  DEFAULT_FILTER,
};

export type {
  LegalDocument,
  Location,
  TransformationCenter,
  ScheduledParams,
  NameParams,
  PasswordParams,
  Filter,
};

export default buildClientStore();
