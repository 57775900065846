import { FunctionComponent } from 'react';
import { Outlet } from 'react-router-dom';

import Sidebar from './components/Sidebar';
import Back from './components/Back';

const Dashboard: FunctionComponent = () => (
  <main className="flex flex-1 max-w-full">
    <div className="min-h-screen bg-content max-w-[100px] min-w-[100px] w-full">
      <Sidebar />
    </div>
    <div className="flex flex-col flex-1 pt-9 max-w-[calc(100%-100px)] dashb:max-w-screen-full mx-auto">
      <Back />
      <Outlet />
    </div>
  </main>
);

export default Dashboard;
