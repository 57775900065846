import axios, { AxiosInstance, AxiosResponse } from 'axios';
import _ from 'lodash/fp';

import {
  AxiosRequest,
  InstanceRequest,
  HandleAuthorized,
  AxiosInterface,
} from './types';

const BASE_URL = process.env.REACT_APP_BASE_URL || '';

const isUnauthorized = _.flow(
  _.get('response.status'),
  _.isEqual(401),
);

const get: InstanceRequest = (instance: AxiosInstance): AxiosRequest => (
  async <T>(endpoint: string, params?: T): Promise<AxiosResponse> => {
    try {
      return await instance.get(endpoint, { ...params });
    } catch (error) {
    // @ts-ignore
      return error.response;
    }
  }
);

const post: InstanceRequest = (instance: AxiosInstance): AxiosRequest => (
  async <T>(endpoint: string, params?: T): Promise<AxiosResponse> => {
    try {
      return await instance.post(endpoint, params);
    } catch (error) {
    // @ts-ignore
      return error.response;
    }
  }
);

const put: InstanceRequest = (instance: AxiosInstance): AxiosRequest => (
  async <T>(endpoint: string, params?: T): Promise<AxiosResponse> => {
    try {
      return await instance.put(endpoint, params);
    } catch (error) {
    // @ts-ignore
      return error.response;
    }
  }
);

const destroy: InstanceRequest = (instance: AxiosInstance): AxiosRequest => (
  async <T>(endpoint: string, params?: T): Promise<AxiosResponse> => {
    try {
      return await instance.delete(endpoint, { ...params });
    } catch (error) {
    // @ts-ignore
      return error.response;
    }
  }
);

const createInstance = (url: string, handleAuthorized: HandleAuthorized): AxiosInstance => {
  const instance = axios.create({
    baseURL: `${BASE_URL}/v1/${url}`,
    headers: {
      'Accept-Language': 'es-ES',
    },
  });

  instance.interceptors.response.use(
    _.identity,
    handleAuthorized,
  );

  return instance;
};

const API: AxiosInterface = {
  get,
  post,
  put,
  delete: destroy,
};

export { createInstance, isUnauthorized };

export default API;
