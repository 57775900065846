import { useEffect, useState } from 'react';

interface MessageParams {
  success: boolean;
  message: string;
}

const defaultMessage: MessageParams = {
  success: false,
  message: '',
};

const useHandleMessage = (success: string): [
  MessageParams, (r: string | boolean) => void, () => void
] => {
  const [message, setMessage] = useState(defaultMessage);

  const onClearMessage = (): void => setMessage(defaultMessage);

  const handleResponse = (r: string | boolean): void => (
    typeof r === 'string'
      ? setMessage({ success: false, message: r })
      : setMessage({ success: true, message: success })
  );

  useEffect(() => {
    setTimeout(() => {
      onClearMessage();
    }, 4000);
  }, [message]);

  return [message, handleResponse, onClearMessage];
};

export type { MessageParams };

export default useHandleMessage;
