import { Thunk, thunk } from 'easy-peasy';
import { API, handleResponse } from 'libs';
import { Distributor } from 'models';

import managers from '../../managers';

interface NodalModel {
  create: Thunk<NodalModel, Distributor>;
  update: Thunk<NodalModel, Distributor>;
}

const nodalModel: NodalModel = {
  create: thunk(async (_actions, payload, { getStoreActions }) => {
    const response = await API.post(managers)(`distributors/${payload.id}/nodal-capacities`, payload.document);
    // @ts-ignore
    return handleResponse(response, getStoreActions().distributor.init);
  }),

  update: thunk(async (_actions, payload, { getStoreActions }) => {
    const response = await API.put(managers)(`distributors/${payload.id}/nodal-capacities/${payload.document.id}`, payload.document);
    // @ts-ignore
    return handleResponse(response, getStoreActions().distributor.init);
  }),
};

export type { NodalModel };

export default nodalModel;
