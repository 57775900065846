import {
  Action,
  action,
  Thunk,
  thunk,
} from 'easy-peasy';
import { API, handleResponse } from 'libs';
import { createContract, IContract } from 'models';

import clients from '../../clients';

import {
  Measures,
  PowerByMonth,
  MeasureParams,
  EnergyByHour,
} from './types';

interface ConsumptionModel {
  contract: IContract;
  from: Date | null;
  to: Date | null;
  measures: Measures;
  powerByMonth: PowerByMonth[];

  setDevice: Action<ConsumptionModel, IContract>;
  setFrom: Action<ConsumptionModel, Date | null>;
  setTo: Action<ConsumptionModel, Date | null>;
  initMeasures: Action<ConsumptionModel, Measures>;
  initPower: Action<ConsumptionModel, PowerByMonth[]>;

  energy: Thunk<ConsumptionModel, MeasureParams>;
  power: Thunk<ConsumptionModel, string>;
}

const consumptionModel: ConsumptionModel = {
  contract: createContract(),
  from: null,
  to: null,
  measures: {
    imported: [],
    exported: [],
    lastYear: [],
    lastPeriod: [],
  },
  powerByMonth: [],

  setDevice: action((state, payload) => {
    state.contract = payload;
  }),

  setFrom: action((state, payload) => {
    state.from = payload;
  }),

  setTo: action((state, payload) => {
    state.to = payload;
  }),

  initMeasures: action((state, payload) => {
    state.measures = payload;
  }),

  initPower: action((state, payload) => {
    state.powerByMonth = payload;
  }),

  energy: thunk(async (actions, payload) => {
    const response = await API.get(clients)('/measures', { params: payload });
    return handleResponse(response, actions.initMeasures);
  }),

  power: thunk(async (actions, payload) => {
    const response = await API.get(clients)(`/power/${payload}`);
    return handleResponse(response, actions.initPower);
  }),

};

export type { ConsumptionModel, EnergyByHour, PowerByMonth };

export default consumptionModel;
