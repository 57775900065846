import { FunctionComponent, ReactNode } from 'react';
import { Icon } from 'components/icons';

interface Props {
  open: boolean;
  onClose: () => void;
  step: number;
  totalSteps: number;
  children: ReactNode;
}

const StepModal: FunctionComponent<Props> = (
  {
    open,
    onClose,
    children,
    step,
    totalSteps,
  }: Props,
) => {
  if (!open) return null;

  return (
    <div className="fixed top-0 left-0 z-50 flex items-end justify-center w-screen h-screen bg-overlay md:items-center">
      <div className="p-6 pb-9 rounded-t-xl bg-backdrop-primary w-full md:pb-6 md:rounded-xl md:w-full md:max-w-md max-h-[90vh] overflow-y-auto">
        <div className="flex items-center justify-between mb-3">
          <p className="text-s-base-b">{`${step}/${totalSteps}`}</p>
          <div onClick={onClose} className="cursor-pointer">
            <Icon name="close" className="text-content-interaction" />
          </div>
        </div>
        {children}
      </div>
    </div>
  );
};

export default StepModal;
