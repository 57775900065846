import settings from '../../features/settings/settings.json';
import authorizations from '../../features/authorizations/authorizations.json';
import manager from '../../features/managers/manager.json';
import clients from '../../features/clients/clients.json';
import unpayments from '../../features/non-payment-cuts/unpayments.json';
import scheduleds from '../../features/scheduled-cuts/scheduleds.json';
import profile from '../../features/profile/profile.json';

import app from './app.json';
import pages from './pages.json';

const managers = {
  ...app,
  pages,
  manager,
  authorizations,
  clients,
  settings,
  unpayments,
  scheduleds,
  profile,
};

export default managers;
