import { FunctionComponent, ReactNode } from 'react';

interface Props {
  children: ReactNode;
}

const Main: FunctionComponent<Props> = ({ children }: Props) => (
  <div className="relative flex flex-col w-full min-h-screen max-w-screen bg-backdrop-primary">
    {children}
  </div>
);

export default Main;
