const TAG_TEXT_STYLES = {
  regular: 'text-content-inactive',
  notice: 'text-notice-400',
  warning: 'text-warning-400',
  success: 'text-success-400',
  danger: 'text-danger-400',
};

const TAG_STYLES = {
  regular: 'bg-content-inactive',
  notice: 'bg-notice-400',
  warning: 'bg-warning-400',
  success: 'bg-success-400',
  danger: 'bg-danger-400',
};

type TagStyles = keyof typeof TAG_STYLES;

export { TAG_STYLES, TAG_TEXT_STYLES };

export type { TagStyles };
