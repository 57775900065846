import {
  Action,
  Computed,
  computed,
  Thunk,
  action,
  thunk,
} from 'easy-peasy';
import { API, handleData, handleResponse } from 'libs';
import {
  Authorization, ID, OWNERS, RejectionParams,
} from 'models';
import _ from 'lodash/fp';

import managers from '../../managers';

interface AuthorizationsModel {
  items: Authorization[]
  current: Authorization;

  init: Action<AuthorizationsModel, Authorization[]>;
  initCurrent: Action<AuthorizationsModel, Authorization>;

  index: Thunk<AuthorizationsModel>;
  show: Thunk<AuthorizationsModel, ID>;
  contract: Thunk<AuthorizationsModel, ID>;
  update: Thunk<AuthorizationsModel, ID>;
  destroy: Thunk<AuthorizationsModel, RejectionParams>;

  validateds: Computed<AuthorizationsModel, Authorization[]>;
  pending: Computed<AuthorizationsModel, Authorization[]>
  count: Computed<AuthorizationsModel, number>;
}

const defaultAuthorization: Authorization = {
  owner: OWNERS[0],
  name: '',
  cups: '',
  identityNumber: '',
  validated: false,
  rejected: false,
  documents: [],
};

const authorizationsModel: AuthorizationsModel = {
  items: [],
  current: defaultAuthorization,

  init: action((state, payload) => {
    state.items = payload;
  }),

  initCurrent: action((state, payload) => {
    state.current = payload;
  }),

  index: thunk(async (actions, _payload) => {
    const response = await API.get(managers)('/authorizations');
    return handleResponse(response, actions.init);
  }),

  show: thunk(async (actions, payload) => {
    const response = await API.get(managers)(`/authorizations/${payload}`);
    return handleResponse(response, actions.initCurrent);
  }),

  contract: thunk(async (_actions, payload) => {
    const response = await API.get(managers)(`/authorizations/${payload}/contract`);
    return handleData(response);
  }),

  update: thunk(async (actions, payload) => {
    const response = await API.put(managers)(`/authorizations/${payload}`);
    return handleResponse(response, actions.init);
  }),

  destroy: thunk(async (actions, payload) => {
    const response = await API.delete(managers)(`/authorizations/${payload.id}`, {
      data: payload,
    });
    return handleResponse(response, actions.init);
  }),

  validateds: computed((state) => _.flow(
    _.filter((a: Authorization) => a.validated || a.rejected),
  )(state.items)),

  pending: computed((state) => _.flow(
    _.filter((a: Authorization) => !a.validated && !a.rejected),
  )(state.items)),

  count: computed((state) => _.flow(
    _.filter((a: Authorization) => !a.validated && !a.rejected),
    _.size,
  )(state.items)),
};

export type { AuthorizationsModel };

export default authorizationsModel;
