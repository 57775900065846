import { FunctionComponent, ReactNode } from 'react';
import { Link } from 'react-router-dom';

interface Props {
  to: string;
  children: ReactNode;
  className?: string;
}

const LinkWrapper: FunctionComponent<Props> = ({ to, children, className = '' }: Props) => (
  <Link to={to} className={className}>
    {children}
  </Link>
);

export default LinkWrapper;
