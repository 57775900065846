import _ from 'lodash/fp';

const NIF_REGEX = /^[0-9]{8}[TRWAGMYFPDXBNJZSQVHLCKE]$/i;
const NIE_REGEX = /^[XYZ][0-9]{7}[TRWAGMYFPDXBNJZSQVHLCKE]$/i;
const CIF_REGEX = /^([ABCDEFGHJKLMNPQRSUVW])(\d{7})([0-9A-J])$/;
const CADASTRAL_LENGHT = 20;

const VALIDATIONS = {
  dni: (value = ''): boolean => NIE_REGEX.test(value) || NIF_REGEX.test(value),
  nif: (value = ''): boolean => CIF_REGEX.test(value),
  identity: (value = ''): boolean => (
    NIE_REGEX.test(value) || NIF_REGEX.test(value) || CIF_REGEX.test(value)
  ),
  cadastral: (value = ''): boolean => {
    const positionWeight = [13, 15, 12, 5, 4, 17, 9, 21, 3, 7, 1];
    const letterDC = 'MQWERTYUIOPASDFGHJKLBZX';

    if (_.lt(_.size(value), CADASTRAL_LENGHT)) return false;

    const cadastralreference = value.toUpperCase();
    const firstDC = (
      cadastralreference.substring(0, 7) + cadastralreference.substring(14, 18)
    ).toUpperCase();
    const secondDC = (
      cadastralreference.substring(7, 14) + cadastralreference.substring(14, 18)
    ).toUpperCase();
    const chainsDC = [firstDC, secondDC];
    let calculatedDC = '';

    chainsDC.forEach((chain) => {
      let digitaddition = 0;

      chain.split('').forEach((char, position) => {
        let charValue = char;

        if (char >= 'A' && char <= 'N') {
          // @ts-ignore
          charValue = char.charCodeAt(0) - 64;
        } else if (char === 'Ñ') {
          // @ts-ignore
          charValue = 15;
        } else if (char > 'N') {
          // @ts-ignore
          charValue = char.charCodeAt(0) - 63;
        }
        // @ts-ignore
        digitaddition = (digitaddition + charValue * positionWeight[position]) % 23;
      });

      calculatedDC += letterDC.charAt(digitaddition);
    });

    if (calculatedDC !== cadastralreference.substring(18, 20)) return false;

    return true;
  },
};

export default VALIDATIONS;
