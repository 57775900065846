import { ComponentProps, FunctionComponent, ReactNode } from 'react';
import _ from 'lodash/fp';
import { Container } from 'components/containers';
import { ButtonLink } from 'components/buttons';

import { Sidebar } from './components';

interface Props {
  sidebar: ReactNode;
  children: ReactNode;
  button?: Omit<ComponentProps<typeof ButtonLink>, 'className' | 'iconRight' | 'size' | 'styles'>;
}

const SideScreen: FunctionComponent<Props> = (
  {
    sidebar,
    children,
    button,
  }: Props,
) => (
  <Container className="flex-1 lg:flex">
    <Sidebar className="lg:w-1/4">
      {sidebar}
    </Sidebar>
    <div className="flex flex-col lg:w-3/4 lg:items-end mt-20 lg:mt-0 lg:pt-16 lg:pl-16 lg:ml-auto [&_*:target]:scroll-mt-32 lg:[&_*:target]:scroll-mt-20">
      {children}
    </div>
    <div className="block lg:hidden">
      {_.all(_.negate(_.isUndefined), [button?.to, button?.label]) && (
      <ButtonLink
        to={button?.to ?? ''}
        label={button?.label}
        icon={button?.icon}
        size="fixed"
      />
      )}
    </div>
  </Container>
);

export default SideScreen;
